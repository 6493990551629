div.tvAppIndex{
  padding: 100px 0;
  div.tvActivationReverse{
    div.topHeader{
      padding: 20px 10px;
      // background-color: var(--grey-color);
      border-bottom: 1px solid var(--border-color);
      h1{
        font-size: 22px;
        color: var(--text-color);
      }
    }
    div.bottomContainer{
      padding: 50px 0 20px;
      h2{
        color: var(--text-color);
        font-size: 18px;
        margin-bottom: 20px;
      }
      p{
        font-size: 16px;
        color: var(--text-color);
        margin-bottom: 15px;
      }
      div.inputContainer{
        form{
          label{
            color: var(--text-color);
            font-size: 15px;
            display: block;
            margin-bottom: 10px;
          }
          input{
            display: block;
            outline: none;
            border: none;
            padding: 15px 15px;
            border-radius: 5px;
            margin-bottom: 10px;
            width: 30%;
            @media all and (max-width:640px){
              width: 100%;
            }
          }
          button{
            width: 8rem;
            padding: 6px 1px 9px;
            border-radius: 2px;
            background-color: var(--secondary-color);
            color: var(--white-color);
            margin-right: 10px;
            cursor: pointer;
            transition: 0.3s ease-in-out;
            font-size: 13px;
            margin-bottom: 10px;
            
            &:hover{
              background-color: var(--secondary-hover-color);
            }
          }
          
        }
        p.terms{
          font-size: 15px;
          span{
            color: rgb(63, 131, 242);
            cursor: pointer;
          }
        }
      }
    }
  }
  div.linkTvApp {
    padding: 130px 0;
    div.authCodeContainer {
      width: 35%;
      margin: 0 auto;
      background-color: #22293b;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      text-align: center;
      border-radius: 10px;
      padding: 50px 60px;
      @media all and (max-width:1200px){
          width: 40%;
      }
      @media all and (max-width:980px){
          width: 50%;
      }
      @media all and (max-width:768px){
          width: 60%;
      }
      @media all and (max-width:640px){
          width: 75%;
      }
      @media all and (max-width:480px){
          width: 90%;
          padding: 40px 40px;
      }
      h1.heading {
        color: var(--text-color);
        font-size: 19px;
        font-weight: 600;
        margin-bottom: 8px;
      }
      p {
        color: var(--white-color);
        font-size: 14px;
        margin-bottom: 30px;
      }
      span.loading{
          display: block;
          color: var(--success-color);
          margin-bottom: 20px;
      }
      div.authCodeField {
        border: 1px solid var(--success-color);
        padding: 10px;
        font-size: 24px;
        color: var(--success-color);
        letter-spacing: 10px;
        font-weight: 700;
        margin-bottom: 15px;
        &.expired{
          border: 1px solid var(--error-color);
          color: var(--error-color);
        }
      }
      div.codeExpired {
        span {
          color: #fff;
        }
        div.regenerateButton {
          margin-top: 10px;
          button {
            background-image: linear-gradient(0deg, #2175d9, #2789ff);
            width: 12rem;
            border-radius: 4px;
            color: #fff;
            padding: 10px;
            font-size: 13px;
            line-height: 1.2;
            letter-spacing: 0.29px;
            font-weight: 500;
            cursor: pointer;
          }
        }
      }
      span.timer{
          color: #fff;
          font-size: 16px;
      }
    }
  }

}
