div.livePlayer{
    padding: 130px 0;
    @media all and (max-width:2500px){
        padding: 200px 0 0 0;
    }
    @media all and (max-width:1980px){
        padding: 165px 0 0 0;
    }
    @media all and (max-width:980px){
        padding: 150px 0 0 0;
    }
    div.videoContainer{
        height: 100vh;
        .video-js{
            padding-top: 0 !important;
            height: 100vh !important;
            width: 100% !important;
        }
        video{
            display: block;
            height: 100%;
        }
    }
}