div.header{
  position: fixed !important;
  top: 0;
  z-index: 99;
  left: 0;
  right: 0;
  width: 100%;
  div.headerContainer 
  {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  background: var(--bg-color);
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  @media all and (max-width:2500px){
    padding: 0;
  }
  @media all and (max-width:980px){
    padding: 0;
  }
  @media all and (max-width:480px){
    padding: 0;
  }
  @media all and (max-width:360px){
    padding: 0;
  }
  div.wrapper 
 {
    display: flex;
    justify-content: space-between;
    align-items: center;
     @media all and (max-width:2500px){
      width: 96%;
     }
     @media all and (max-width:1768px){
      width: 97%;
     }
     @media all and (max-width:1600px){
      width: 97%;
     }
     @media all and (max-width:1400px){
      width: 96%;
     }
     @media all and (max-width:1200px){
      width: 96%;
     }
     @media all and (max-width:980px){
      width: 95%;
     }
    @media all and (max-width:768px){
      width: 93%;
     }
    div.leftContainer 
    {
        display: flex; 
        align-items: center;
        position: relative;
        @media all and (max-width:480px){
          width: 25%;
         }
      div.menuIconContainer
      {
          display: flex;
          margin-right: 10px;
          cursor: pointer;
          @media all and (max-width:2200px){
            margin-right: 15px;
           }
           @media all and (max-width:768px){
            margin-right: 0;
           }
           @media all and (max-width:640px){
              margin-right: 20%;
           }
          @media all and (max-width:480px){
            margin-right: 20px;
           }
           @media all and (max-width:360px){
            margin-right: 10px;
           }
           &:hover{
            background-color: var(--grey-color);
            border-radius: 50%;
            svg{
              color: var(--black-color);
            }
          }
          svg{
            width: 30px !important;
            height: 30px !important;
            color:var(--text-color);
            @media all and (max-width:768px){
              width: 25px !important;
            }
            @media all and (max-width:640px){
              width: 22px !important;
            }
            @media all and (max-width:480px){
              width: 20px !important;
              height: 20px !important;
            }
            @media all and (max-width:360px){
              width: 17px !important;
            }
            
          }
        
      }
      div.logoContainer 
      {
        h1 {
          width: 100px;
         
          max-height: 40px;
          @media all and (max-width:640px){
            width: 85px;
          }
          @media all and (max-width:480px){
            width: 72px;
          }
          @media all and (max-width:360px){
            width: 72px;
          }
          img
          {
            height: 50px;
            object-fit: contain;
            width: 100%;
            @media all and (max-width:1200px){
                    height: 30px;
            }
            @media all and (max-width:980px){
              height: 30px;
             }      
            @media all and (max-width:768px){
              height: 28px;
            }
            @media all and (max-width:640px){
              height: 27px;
              margin-left: -23px;
            }
            @media all and (max-width:360px){
              height: 22px;
              margin-left: -10px;
            }
      }
    }
  }
  }
  div.centerContainer
  {
        display: flex;
        flex-direction: row; 
        position: relative;
        align-items: center;
        width:100%;
        height: 40px;
        border: 1px solid lightgray;
        border-radius: 20px;
        background: var(--bg-color);
        margin-left: 10%;
        @media all and (max-width:2500px){
          width: 30%;
        }
        @media all and (max-width:1980px){
          width: 35%;
        }
        @media all and (max-width:1600px){
          width: 42%;
        }
        @media all and (max-width:1400px){
          width: 45%;
        }
        @media all and (max-width:980px){
          margin-left: -6%;
          width: 50%;
        }
        @media all and (max-width:768px){
            height: 25px;
            width: 50%;
        }
        @media all and (max-width:640px){
          width: 50%;
      }
        @media all and (max-width:480px){
          height: 20px;
          width: 50%;
      }
        @media all and (max-width:360px){
            height: 20px;
            width: 50%;
        }

        div.inputSearchContainer{
          display: flex;
          width: 100%;
          align-items: center;
          margin: 0;
          position: relative;
          margin-left: 20px;
          @media all and (max-width:480px){
            margin-left: 10px; 
          }
          @media all and (max-width:360px){
            margin-left: 10px; 
          }
          input{
            width: 80%;
            font-size: 15px;
            flex: 1;
            border: none;
            height: 35px;
            position: relative;
            outline: none;
            background-color: transparent;
            color: var(--text-color);
            @media all and (max-width:768px){
              font-size: 13px; 
            }
            @media all and (max-width:640px){
                font-size: 12px; 
            }
            @media all and (max-width:480px){
              font-size: 12px; 
            }
            @media all and (max-width:360px){
                font-size: 10px; 
            }
            }
            &.showInput {
              
              padding: 8px 6px;
              background-color: var(--gray-color);
              align-items: center;
              width: 80%;
              justify-content: space-between;
              transition: 0.3s ease-in-out;
              cursor: pointer;
              input {
                display: block;
              }
              @media all and (max-width:768px){
                display: none;
              }
            }
            input:focus{
              outline: none;
            
          }
          button{
            background-color:var(--grey-color);
            width: 80px;
            height: 40px;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            border: none;
            border-left: 1px solid lightgray ;
            cursor: pointer;
            @media all and (max-width:980px){
              width: 50px;
            }
              @media all and (max-width:768px){
                  height: 25px;
                  width: 50px;
              }
              @media all and (max-width:480px){
                height: 20px;
                width: 40px;
            }
              @media all and (max-width:360px){
                  height: 20px;
                  width: 30px;
              }
              svg{
                width: 10px;
                margin-top: 3px;
                @media all and (max-width:2500px){
                  width: 15px;
              }
               }
            }
            button:hover{
                background-color:lightgrey;

            }
         }
         
  }

    

 div.rightContainer {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: right;
    margin-left: 15%;
    transition: 0.2s ease-in-out;  
    @media all and (max-width:980px){
      margin-left: 0;
     } 
    @media all and (max-width:360px){
      margin-left: 10px;
     }
      div.userIconContainer {
                display: flex;
                width: 50px;
                align-items: center;
                cursor: pointer;
                @media all and (max-width:2500px){
                  width: 24px; 
              }
                @media all and (max-width:640px){
                  width: 20px;
                 }
                @media all and (max-width:320px){
                  width: 12px;
                 }
                svg{
                  align-items: center;
                  width: 30px;
                  color: var(--text-color);
                 
                  @media all and (max-width:640px){
                      width: 25px; 
                  }
                  @media all and (max-width:480px){
                    width: 15px; 
                  }
                   @media all and (max-width:360px){
                      width: 14px;
                      
                  }
                }   
      }
      
    }
  }
}

// div.headerCategories{
//   position: fixed !important;
//   z-index: 10;
//   background:var(--bg-color);
//   width: 95%;
//   margin-left: 5%;
//   top: 15%;
//   @media all and (max-width:1400px){
//     margin-left: 6%;
//    }
//   @media all and (max-width:1200px){
//     margin-left: 6%;
//    }
//    @media all and (max-width:980px){
//     margin-left: 8%;
//    }
//   @media all and (max-width:768px){
//     margin-left: 10%;
//    }
//   @media all and (max-width:640px){
//     margin-left: 0;
//     width: 100%;
//    }
//     div.my_Swiper{
//     width: 100%;
//     display: flex;
//     padding: 0;
//     z-index:1;
//     align-items: center;
//     overflow: hidden;
//     margin-left: auto;
//     margin-right: auto;
//     list-style: none;
//     position: sticky !important;
//       div.swiper-wrapper{
//           width: 100%;
//           z-index: 1;
//           display: flex;
//           transform:translateX(0px);
          
//           .swiper-slide{
//               transform: translateZ(0);
//               flex-shrink: 0;
//               @media all and (max-width:640px){
//                   width:15px; 
//               }
//               @media all and (max-width:360px){
//                   width:10px; 
//               }
//               .swiper_button{
//                   background-color:var(--button-color);
//                   height: 30px;
//                   width:100%;
//                   text-align: center;
//                   margin-left: 25px;
//                   border: none;
//                   border-radius: 10px;
//                   position: sticky;
//                   margin-bottom: 10px;
//                   &:hover
//                   {
//                       background-color:var( --button-hover);
//                       cursor: pointer; 
//                       text-align: center;
//                   }
//               } 
              
              
//       }
//       .swiper-button-prev{
//           top: 17px;
//           left: 0;
//           position: absolute;
//           z-index: 15;
//           background-color: var(--grey-color);
//           opacity: 1;
//           &:hover
//           {
//               color:var(--grey-color);
//               cursor: pointer;   
//           }  
//       }
//       .swiper-button-prev::after{
//           font-size: 15px;
//       }
//       .swiper-button-next
//        {
//           right: 1px;
//           position: absolute;
//           color:var(--inner-text-color);
//           z-index: 15;
//         }
//        .swiper-button-next::after{
//           font-size: 15px;
//           color: var(--grey-color);
//        }
//        .swiper-slide-active{
//           margin-left: 35px;
//        }

//        .swiper-button-next{
//           &::after{
//               background-color: var(--grey-color);
//               padding: 6px;
//               border-radius: 50%;
//               font-size: 18px;
//               width: 20px;
//               height: 20px;
//               display: flex;
//               justify-content: center;
//               align-items: center;
              
//               opacity: 0;
//               transition: 0.3s ease-in-out;
//           }
//               .swiper-button-prev:hover {
//                  color: var(--grey-color);
//                   cursor: pointer;

//           }
//       }
//       .swiper-button-prev{
//           &::after{
//               background-color: var(--grey-color);
//               padding: 6px;
//               border-radius: 50%;
//               font-size: 18px;
//               width: 20px;
//               height: 20px;
//               display: flex;
//               justify-content: center;
//               align-items: center;
//               color: var(--bg-color);
//               opacity: 0;
//               transition: 0.3s ease-in-out;
              
//           }
//       }
//        .swiper-button-next {
//           right: 2px;
//           position: absolute;
//           z-index: 15;
//           top: 17px;
//           @media all and (max-width:1400px){
//               right: 6px; 
//           }
//           &:hover
//           {
//               color: var(--grey-color);
//               cursor: pointer;   
//           } 
           
//       }
//       .swiper-button-next{
//           &::after{
//               background:var(--grey-color);
//               padding: 6px;
//               border-radius: 50%;
//               font-size: 18px;
//               width: 20px;
//               height: 20px;
//               display: flex;
//               justify-content: center;
//               align-items: center;
//               color: var(--bg-color);
//               opacity: 0;
//               transition: 0.3s ease-in-out;
//           }
//       }
//     } 
//   }

// div.selectionbar_container
// {     width: 100%;
//       top:0;
//       display: flex; 
//       padding: 20px;
//       z-index: 100;
//       background-color:var(--bg-color);
//       align-items: center;
//       justify-content: space-between;
//       position: sticky;
//   div.selection__button
//   {  
//       background-color:var(--inner-text-color);
//       width:100%;
//       height: 40px;
//       text-align: center;
//       margin-left: 5px;
//       // padding: 0.9%;
//       border: none;
//       border-radius: 10px;
//       &:hover
//       {
//           background-color:var( --grey-color);
//           cursor: pointer; 
//           text-align: center;
//       }
//   }
// }
// }
div.sidebarContainer{
    top: 19%;
    background-color: var(--bg-color);
    align-items: center;
    padding: 5px 5px; 
    color: var(--text-color);
    font-family: sans-serif;
    font-size: 12px;
    position:fixed;
    width: 10%;
    z-index: 99;
    bottom: 0;
    box-shadow: none;
    @media all and (max-width: 2500px) {
      top: 11% !important;
    }
    @media all and (max-width: 2200px) {
      top: 13% !important;
    }
    @media all and (max-width: 1600px) {
      top: 13% !important;
    }
    @media all and (max-width: 1400px) {
      top: 18% !important;
    }
    @media all and (max-width: 1200px) {
      h2{
        display: none;
      }
    }
    @media all and (max-width: 640px) {
      display: none;
    }
    
}
div.menuBarOverlay {
  &.active {
    
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: var(--gray-color);
      z-index: 999;
      display: flex;
      justify-content: center;
      align-items: center;
      // animation: fadeIn 0.3s ease-in-out;
    
  }
}
div.menuBarOverlay.active {
  display: flex;
}

div.searchDropContainer{
  position: absolute;
  top:45px;
  display: flex;
  flex-direction: column;
 
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-size: 13px;
  background-color: var(--bg-color);
  width: 100%;
  @media all and (max-width:640px){
      width: 120%;
      
     }
  @media all and (max-width:480px){
      width: 130%;
      right: 10px;
     }
     @media all and (max-width:480px){
      width: 135%;
      right: 10px;
     }
    &.hide{
      // width: 100%;
      display: flex;
    }
    
}
div.rightUserContainer{
  &.Active{
    background-color:var(--gray-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
div.rightUserContainer{
  display: flex;
}

div.browseMore {
  @media all and (max-width: 1200px) {
    display: none;
  }
  //  animation: fadeIn 0.3s ease-in-out ;
  display: none;

  div.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: var(--overlay);
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.browseMoreContainer {
    animation: fadeIn 0.7s ease-in-out ;

    position: fixed;
    // width: 70%;
    top: 14%;
    left: 10%;
    // transform: translateX(-50%);
    background-color: #fff;
    display: flex;
    border-radius: 6px;
    z-index: 999;
    &::after{
      content: "";
      top: 81px;
      /* width: 7px; */
      /* height: 4px; */
      border-color: transparent transparent #fff;
      border-style: solid;
      border-width: 0px 9px 15px;
      position: fixed;
      /* right: 2%; */
      /* transform: translateX(-580%); */
      left: 16.5%;
     @media all and (max-width:1980px){
      
        top: 11%;
        left:13.5%
      }
  
      @media all and (max-width:1900px){
        top: 13%;
        left:13.5%
      }
    
    }


    h1.categoryName {
      font-size: 14px;
      margin-bottom: 20px;
      color: var(--grey-color);
    }
    ul {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      li {
        margin-bottom: 8px;
        cursor: pointer;
        &:hover {
          span {
            color: var(--text-hover);
          }
        }
        span {
          font-size: 14px;
          color: #10141f;
          transition: 0.3s ease-in-out;
        }
      }
    }
    div.left {
      // width: 22%;
      padding: 30px 25px;
      overflow-y: scroll;
     height: 500px;


     &::-webkit-scrollbar {
width:8px;

    }
    
    &::-webkit-scrollbar-thumb{
     background-color:darkgrey;
     border-bottom-right-radius: 3px;
     border-top-right-radius: 3px;
     border-top-left-radius: 3px;
     border-bottom-left-radius: 3px;

   
    }
    &::-webkit-scrollbar-thumb:hover{
     background-color: rgb(125, 128, 128);
    }

    }
    div.center {
      width: 40%;
      background-color: #f0f3f5;
      padding: 30px 25px;
      div.sections {
        display: flex;
        justify-content: space-between;
        div.leftSection {
          width: 50%;
        }
        div.rightSection {
          width: 50%;
        }
      }
    }
    div.right {
      width: 40%;
      padding: 30px 25px;
    }
  }
}
div.browseMore.active {
  display: block;
  @media all and (max-width: 1200px) {
    display: none;
  }
  animation: fadeIn 0.4s ease-in-out ;
}

div.browseMoreResponsive {
  div.imageListContainer{
    padding: 5px 5px;
    color: var(--text-color);
    font-family: sans-serif;
    font-size: 12px;
    position: fixed;
    z-index: 9999;
    height: 70%;
    width: 35%;
    left: 32%;
    top: 13%;
    background-color:var(--grey-color);
    border-radius: 10px;
    overflow-y: scroll;
    
   ul.listContainer{
    div.categorySection{
      margin: 10px;
      h6.heading{
        font-size: 13px;
        color: gray;
        margin-left: 10px;
      }
      li.lists{
       
        div.list{
            display: inline-flex;
            margin-bottom: 3px;
            cursor: pointer;
            width: 100%;
            &:hover {
              background-color: gray;
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
              transform: scale(1.02); 
            }
            div.left {
              // margin-right: 10px;
              div.imageContainer {
                align-items: center;
                padding: 10px ;
                color: var(--text-color);
                background-color: var(--gray-color);
                width: 100px !important;       
                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                  object-fit: cover;
                }
                @media all and (max-width: 980px) {
                  width:80px !important;
                  }
                @media all and (max-width: 480px) {
                  width: 60px !important;
                }
              }
            }
            div.right {
              h1.title {
                font-size: 14px;
                margin-left: 20px;
                margin-top: 12px;
                color: var(--black-color);
                @media all and (max-width: 768px) {
                 margin-left: 0px;
                  }
                @media all and (max-width: 640px) {
                  font-size: 11px;
                }
               
              }
              }
              &:hover{
            background-color: var(--grey-color);
            border-radius: 10px;
            width: 100%;
            margin-left: 0;
          }
          @media all and (max-width: 640px) {
           margin-bottom: 0px;
          }
            }   
      }
    }
  
   }
   div.seeAll{
    margin-left: 10%;
    button{
      font-weight: 600;
      cursor: pointer;
      &:hover{
        color: gray;
      }
    }
   
   }
  @media all and (max-width: 2500px) {
    width: 30%;
    left: 35%;
    }  
  @media all and (max-width: 1600px) {
    width: 36%;
    left: 31%;
    }
  @media all and (max-width: 980px) {
    width: 47%;
    left: 30%;
    }
  @media all and (max-width:640px) {
      width: 51%;
    }
    }

div.menuListContainer{
  align-items: center;
  padding: 5px 5px; 
  color: var(--text-color);
  font-family: sans-serif;
  font-size: 12px;
  position:fixed;
  width: 14%;
  z-index: 9999;
  background-color: var(--bg-color);
  right: 5px;
  margin-top: 5%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  @media all and (max-width: 2500px) {
   margin-top: 5%;
   }
  @media all and (max-width: 1400px) {
    margin-left: 85%;
    }
  @media all and (max-width: 1200px) {
      margin-top: 8%;
      margin-left: 83%;
      width: 16%;
    }
  @media all and (max-width: 980px) {
    width: 18%;
    margin-left: 80%;
    margin-top: 9%;
    }
  @media all and (max-width: 768px) {
    width: 25%;
    margin-left: 73%;
    margin-top:11%;
    }
  @media all and (max-width: 640px) {
    width: 30%;
    margin-left: 68%;
    margin-top: 15%;
      }
  @media all and (max-width: 480px) {
    width: 40%;
    margin-left: 59%;
    margin-top: 20%;
      }
  @media all and (max-width: 360px) {
    margin-top: 20%;
    width: 50%;
    margin-left: 50%;
      }
    &.Active{
     width:100px;
    }
    
    div.userContainerList{
      display: inline-block;
      align-items: center;
      padding: 10px ;
      color: var(--text-color);
      background-color: var(--gray-color);
      z-index: 999;
      @media all and (max-width: 2500px) {
        width: 75%;
       }
       @media all and (max-width: 2200px) {
        width: 100%;
       }
          div.userRow{
            font-size: 13px;
            height: 45px;
            display: inline-flex;
            align-items: center;
            width: 100%;
            cursor: pointer;
            @media all and (max-width: 2500px) {
              margin-top: 15px;
              }
            @media all and (max-width: 1200px) {
                margin-top: 5px;
             }
            svg{
              width: 25px;
              height: 25px;
              fill: white !important;
            }
            li{
             
              margin-left: 20px;
              font-size: 13px;
              font-weight: 100;
              @media all and (max-width: 2500px) {
                margin-left: 30px;
                font-size: 17px;
                }
              @media all and (max-width: 1768px) {
                  font-size: 15px;
               }
              @media all and (max-width: 1600px) {
                font-size: 14px;
                margin-left: 20px;
                }
              @media all and (max-width: 1400px) {
                font-size: 10px;
                margin-left: 10px;
                  }
            } 
            &:hover{
              background-color: var(--grey-color);
              border-radius: 10px;
              color:var(--black-color);
              svg{
                fill: var(--black-color)!important;
                margin-left: 5px;
              }
            }
          } 
          
             
    }   
 } 

}
}
