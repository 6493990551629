div.ChangePassword { 
  padding: 130px 0 120px;
  @media all and (max-width:2500px){
    padding: 200px 0;
    }
    @media all and (max-width:1768px){
        padding: 160px 0;
    }
    @media all and (max-width:1600px){
        padding: 145px 0;
    }
span.error{
    font-size: 14px;
    color: #E72A31;
    display: block;
    margin-top: 10px;
    &.center{
        text-align: center;
    }
}
span.success{
    font-size: 14px;
    display: block;
    text-align: center;
    margin-top: 10px;
}
h1.heading { 
      font-size: 34px;
      padding-bottom: 10px;
      color: var(--text-color);
      text-align: center;
      @media all and (max-width:640px){
          font-size: 22px;
          margin: 0 20px;
      }
}

div.contents { 
      margin-top: 40px;
      @media all and (max-width:480px){
          padding: 0 10px;
      }
  



  div.formSection { 
          width: 30%;
          margin: 0 auto;
          @media all and (max-width:1200px){
              width: 55%;
          }
          @media all and (max-width:980px){
              width: 75%;
          }
          @media all and (max-width:640px){
              width: 85%;
          }
          form{
            div.inputContainer{
                display: flex;
                flex-direction: column;
                margin-bottom: 20px;
                &:nth-child(3){
                    margin-bottom: 60px;
                }
                label{
                    color: var(--inner-text-color);
                    font-size: 16px;
                    display: inline-block;
                    margin-bottom: 10px;
                }
                input{
                    outline: none;
                    border-radius: 5px;
                    font-size: 14px;
                    background: transparent;
                    text-align: left;
                  padding: 20px 15px;
                  border: 1px solid var(--inner-text-color);
                  caret-color: var(--text-color);
                  display: block;
                  width: 100%;
                  color: var(--text-color);
                  &:focus{
                      border: 1px solid var(--text-color);
                  }
                  @media all and (max-width:640px){
                      padding: 16px 15px;
                  }
                }
            }
            div.buttonContainer { 
                display: flex;
            padding: 18px 20px;
            border-radius: 4px;
            align-items: center;
            margin-bottom: 15px;
            cursor: pointer;
            transition: 0.2s ease-in-out;
            justify-content: center;
            position: relative;
            width: 20rem;
            margin: 30px auto 0;
            @media all and (max-width:640px){
                width: 15rem;
            }
            @media all and (max-width:480px){
                width: 10rem;
            }
            &:hover{
                div.background{

                    transform: scale(1.0476190476,1.0357142857);
                }
            }
                div.background{
                    background: var(--text-hover);
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: 4px;
                    transition: 0.1s ease-in-out;
                    z-index: 1;

                }
                button { 
                    background: transparent;
                    font-size: 15px;
                    color: var( --text-color);
                    font-weight: 600;
                    position: relative;
                    z-index: 2;
                    cursor: pointer;


                }
            }
          }
  }
}
}