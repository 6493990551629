div.playerdetailsScreen {
    scroll-behavior: smooth;
    padding: 20px 0 0;
    position: relative;
     @media all and (max-width: 2600px) {
      padding: 246px 0 0;
    }
    @media all and (max-width: 2500px) {
      padding: 180px 0 0;
    }
    @media all and (max-width: 1768px) {
      padding: 140px 0 0;
    }
    @media all and (max-width: 1600px) {
      padding: 145px 0 0;
    }
    .swiper-button-next {
      right: -9px !important;
      top: 45%;
      @media all and (min-width: 1200px) {
        right: 0 !important;
      }
    }
    .swiper-button-prev {
      left: -9px !important;
      top: 45%;
      @media all and (min-width: 1200px) {
        left: 0 !important;
      }
    }
    div.wrapper {
      background-color: var(--bg-color);
      position: relative;
      z-index: 8;
      display: flex;
      justify-content: space-between;
      @media all and (max-width:980px){
        flex-direction: column;
      }
      div.leftContainer {
        width: 73%;
        @media all and (max-width:980px){
        width: 100%;
        margin-bottom: 20px;
      }
      div.videoplayerContainer{
      
        width: 90%;
        margin: 0 auto;
        background-color: var(--black-color);
        position: relative;
        div.videoContainer {
          background-color: #000;
          width: 100%;
          // animation: fadeIn 0.5s ease-in-out;
          &.heightIncrease {
            // height: 38em;
            height: 1000px;
            @media all and (max-width: 1200px) {
              height: 25em;
            }
          }
          div.gradientTop {
            position: absolute;
            top: 0;
            z-index: 8;
            width: 100%;
            height: 20%;
            background-position: top;
            background-size: cover;
            background-repeat: no-repeat;
            //   background: linear-gradient(
            //       179.83deg,
            //       rgb(16, 20, 31) 0%,
            //       rgba(16, 20, 31, 0) 64.46%
            //     ),
            //     linear-gradient(
            //       540deg,
            //       rgb(16, 20, 31) 0%,
            //       rgba(16, 20, 31, 0) 100.44%,
            //       rgba(16, 20, 31, 0.4) -26%
            //     );
            background: linear-gradient(
              0deg,
              rgba(16, 20, 31, 0),
              rgba(16, 20, 31, 0.2) 19%,
              rgba(16, 20, 31, 0.4) 39%,
              rgba(16, 20, 31, 0.8)
            );
          }
          div.player {
            position: relative;
            z-index: 9;
            width: 100%;
            display: block;
            div.closeIcon {
              display: none;
            }
            div.nowPlaying {
              position: absolute;
              top: 20px;
              right: 20px;
              z-index: 7;
              h1 {
                color: var(--text-color);
                @media all and (max-width: 1200px) {
                  font-size: 23px;
                }
                @media all and (max-width: 980px) {
                  font-size: 20px;
                }
                @media all and (max-width: 768px) {
                  font-size: 18px;
                }
                @media all and (max-width: 640px) {
                  font-size: 16px;
                }
                @media all and (max-width: 480px) {
                  font-size: 14px;
                }
              }
            }
            &.pipMode {
              position: fixed;
              top: 110px;
              width: 22%;
              right: 0;
              z-index: 999999999;
              height: 200px;
  
              @media all and (max-width: 768px) {
                width: 40%;
              }
              @media all and (max-width: 640px) {
                width: 50%;
              }
              @media all and (max-width: 480px) {
                width: 68%;
              }
              video {
                object-fit: cover;
                height: 100%;
              }
              .video-js {
                padding: 0;
                height: 100%;
              }
              div.gradientTop {
                display: none;
              }
              div.nowPlaying {
                display: none;
              }
              &:hover {
                div.closeIcon {
                  position: absolute;
                  top: 10px;
                  left: 10px;
                  z-index: 9;
                  background-color: var(--bg-color);
                  height: 25px;
                  width: 25px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 50%;
                  svg {
                    color: var(--text-color);
                  }
                }
                div.gradientTop {
                  display: block;
                }
              }
            }
          }
  
          video {
            display: block;
            width: 100%;
          }
        }
      }
      
      div.multiAudio{
        width: 22%;
        margin-top: 2%;
        
      }
  
        div.infoContainer{
          margin-top: 15px;
          div.showMetadata {
            border-bottom: 1px solid var(--border-color);
            padding-bottom: 40px;
            width: 100%;
            h1.name {
              font-size: 30px;
              font-weight: 700;
              color: var(--text-color);
              
            }
            div.actions{
              display: flex;
              margin: 10px 0;
              @media all and (max-width: 640px) {
                flex-direction: column;
              }
              button {
                width: 10rem;
                display: block;
                background: var(--secondary-btn-color);
                padding: 8px 10px;
                font-size: 14px;
                color: var(--text-color);
                font-weight: 600;
                border-radius: 30px;
                cursor: pointer;
                transition: 0.3s ease-in-out;
                &:hover {
                  background: var(--button-hover);
                }
              }
              div.left{
                width: 30%;
                @media all and (max-width: 640px) {
                  width: 100%;
                  margin-bottom: 20px;
                  div.playButtonContainer{
                    button.playButton{
                      width: 90% !important;
                      margin: 0 auto;
                    }
                  }
                }
                div.playButtonContainer{
                  button.playButton{
                    width: unset;
                    padding: 8px 12px;
                    background: var(--button-color);
                    transition: 0.3s ease-in-out;
                    cursor: pointer;
                   
                    &:hover {
                      background-color: rgba(203, 203, 208, 0.16);
                      
                    }
                  
                    @media all and (max-width: 360px) {
                      width: 100% !important;
                    }
  
                  }
                }
                
              }
              div.right{
                width: 70%;
                display: flex;
                justify-content: flex-end;
                @media all and (max-width: 640px) {
                  justify-content: center;
                  width: 100%;
                  flex-wrap: wrap;
                }
                div.addRemoveList{
                  button{
                    width: 12rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @media all and (max-width: 640px) {
                      width: 14rem;
                    }
                    @media all and (max-width: 480px) {
                      width: 17rem;
                      margin-bottom: 10px;
                    }
                    div.icon{
                      margin-left: 6px;
                      line-height: 0;
                    }
                  }
                }
                div.shareButton{
                  position: relative;
                  button.share{
                    width: 6rem;
                    margin-left: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @media all and (max-width: 480px) {
                      width: 14rem;
                      margin-left: 0;
                    }
                    div.icon{
                      margin-left: 6px;
                      line-height: 0;
                    }
                  }
                  div.shareContainer {
                    position: absolute;
                    bottom: -126px;
                    left: -50px;
                    z-index: 9;
                    background-color: #1c1f29;
                    border: 1px solid var(--border-color);
                    width: 232px;
                    border-radius: 8px;
                    display: none;
                    button{
                      border-radius:0%;
                    }
                    @media all and (max-width: 1200px) {
                      width: 200px;
                    }
                    @media all and (max-width: 980px) {
                      width: 232px;
                      left: 50%;
                      transform: translateX(-50%);
                    }
                    @media all and (max-width: 480px) {
                      width: 100%;
                    }
                    &.active {
                      display: block;
                    }
                    &::after {
                      content: "";
                      top: -9px;
                      width: 0;
                      height: 0;
                      border-color: transparent transparent rgba(240, 241, 245, 0.16);
                      border-style: solid;
                      border-width: 0 8px 8px;
                      position: absolute;
                      left: 50%;
                      transform: translateX(-50%);
                      @media all and (max-width: 1200px) {
                        left: 40%;
                      }
                      @media all and (max-width: 980px) {
                        left: 50%;
                      }
                    }
                    div.facebookContainer {
                      // display: flex;
                      // justify-content: space-between;
                      // padding: 15px;
                      // color: var(--text-color);
                      // cursor: pointer;
                      // border-bottom: 1px solid var(--border-color);
                      button.facebook {
                        display: flex !important;
                        justify-content: space-between !important;
                        padding: 15px !important;
                        color: var(--text-color) !important;
                        cursor: pointer !important;
                        border-bottom: 1px solid var(--border-color) !important;
                        width: 100% !important;
                        div.icon {
                          width: 10%;
                          svg {
                            width: 20px !important;
                            height: 20px !important;
                            color: var(--grey-color);
                            transition: 0.3s ease-in-out;
                            &:hover {
                              color: var(--text-color);
                            }
                          }
                        }
                      }
                      div.left {
                        width: 10%;
                      }
                      div.right {
                        svg {
                          width: 20px !important;
                          height: 20px !important;
                          color: var(--grey-color);
                          transition: 0.3s ease-in-out;
                          &:hover {
                            color: var(--text-color);
                          }
                        }
                      }
                    }
                    div.twitterContainer {
                      // display: flex;
                      // justify-content: space-between;
                      // padding: 15px;
                      // color: var(--text-color);
          
                      // cursor: pointer;
                      button.twitter {
                        display: flex !important;
                        justify-content: space-between !important;
                        padding: 15px !important;
                        color: var(--text-color) !important;
                        cursor: pointer !important;
                        // border-bottom: 1px solid var(--border-color) !important;
                        width: 100% !important;
                        div.icon {
                          width: 10%;
                          svg {
                            width: 20px !important;
                            height: 20px !important;
                            color: var(--grey-color);
                            transition: 0.3s ease-in-out;
                            &:hover {
                              color: var(--text-color);
                            }
                          }
                        }
                      }
                      div.left {
                        width: 10%;
                      }
                      div.right {
                        svg {
                          width: 20px !important;
                          height: 20px !important;
                          color: var(--grey-color);
                          transition: 0.3s ease-in-out;
                          &:hover {
                            color: var(--text-color);
                          }
                        }
                      }
                    }
                  }
                }
                div.more{
                  margin-left: 10px;
                  position: relative;
                  button.more {
                    width: 2rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: var(--text-color);
                    border-radius: 8px;
                    cursor: pointer;
                    transition: 0.3s ease-in-out;
                    svg {
                      width: 20px !important;
                      height: 20px !important;
                    }
                    &:hover {
                      background-color: rgba(203, 203, 208, 0.16);
                    }
                  }
                  div.reportProblemContainer {
                    position: absolute;
                    bottom: -66px;
                    right: -17px;
                    z-index: 9;
                    background-color: #1c1f29;
                    border: 1px solid var(--border-color);
                    width: 200px;
                    border-radius: 8px;
                    padding: 15px;
                    display: none;
                    cursor: pointer;
                    &.active {
                      display: block;
                    }
                    @media all and (max-width: 1200px) {
                      width: 164px;
                    }
                    @media all and (max-width: 980px) {
                      width: 200px;
                    }
          
                    span {
                      color: var(--text-color);
                    }
                    &::after {
                      content: "";
                      top: -9px;
                      width: 0;
                      height: 0;
                      border-color: transparent transparent rgba(240, 241, 245, 0.16);
                      border-style: solid;
                      border-width: 0 8px 8px;
                      position: absolute;
                      right: 8%;
                      transform: translateX(-50%);
                      @media all and (max-width: 1200px) {
                        right: 5%;
                      }
                      @media all and (max-width: 980px) {
                        right: 8%;
                      }
                    }
                  }
                }
              }
            
              
            }
            div.data {
              display: flex;
              margin-bottom: 6px;
              span {
                font-size: 14px;
                color: var(--inner-text-color);
                @media all and (max-width: 640px) {
                  font-size: 14px;
                }
                &.year {
                  margin-right: 6px;
                }
                &.duration {
                  margin-right: 8px;
                  &.showDot{
    
                    &::before {
                      content: "·";
                      margin-right: 6px;
                    }
                  }
                }
                &.certificate {
                  display: block;
                  padding: 4px 6px;
                  background-color: rgba(150, 153, 163, 0.32);
                  font-size: 11px;
                  font-weight: 700;
                  color: var(--text-color);
                  border-radius: 4px;
                  @media all and (max-width: 640px) {
                    font-size: 10px;
                  }
                }
              }
            }
    
            div.genres {
              margin-bottom: 10px;
              span {
                font-size: 14px;
                color: var(--inner-text-color);
                margin-right: 6px;
                @media all and (max-width: 640px) {
                  font-size: 14px;
                }
                &::before {
                  content: "·";
                  margin-right: 6px;
                }
                &:first-child {
                  &::before {
                    display: none;
                  }
                }
                &:last-child {
                  margin-right: 0;
                }
              }
            }
            div.watchNow {
              display: none;
              @media all and (max-width: 980px) {
                display: block;
              }
            }
            div.addtoList {
              display: none;
              @media all and (max-width: 980px) {
                display: block;
              }
            }
            div.buttons {
              display: none;
              margin-bottom: 20px;
              @media all and (max-width: 980px) {
                display: flex;
              }
            }
    
            p.description {
              font-size: 14px;
              color: var(--text-color);
              line-height: 1.4em;
              letter-spacing: 0.3px;
              max-width: 100%;
              @media all and (max-width: 1200px) {
                max-width: 100%;
              }
            }
            div.extraInfoContainer{
              margin-top: 10px;
              div.metaItem {
                margin-bottom: 20px;
                h6.subHeading {
                  font-size: 14px;
                  font-weight: 400;
                  color: var(--inner-text-color);
                  margin-bottom: 10px;
                }
                div.items {
                  width: 30%;
                  &.oneRow {
                    display: flex;
                    width: 60%;
                    flex-wrap: wrap;
                    span.item {
                      margin-right: 10px;
                      margin-bottom: 0;
                      display: block;
                      color: var(--text-color);
                      cursor: pointer;
                      &:last-child{
                        span.comma{
                          display: none;
                        }
                      }
                    }
        
                  }
                  span.itemInfo{
                    cursor: pointer;
                  }
                  div.item {
                    display: flex;
                    justify-content: space-between;
                    color: var(--inner-text-color);
                    margin-bottom: 6px;
                    &:last-child {
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }
            div.info {
              margin-top: 20px;
              div.starring {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
                div.left {
                  width: 15%;
                  @media all and (max-width: 768px) {
                    width: 20%;
                  }
                  @media all and (max-width: 640px) {
                    width: 25%;
                  }
                  @media all and (max-width: 480px) {
                    width: 30%;
                  }
                  @media all and (max-width: 360px) {
                    width: 33%;
                  }
    
                  h6 {
                    font-size: 14px;
                    font-weight: 400;
                    color: var(--inner-text-color);
                  }
                }
    
                div.right {
                  width: 85%;
                  @media all and (max-width: 980px) {
                    width: 75%;
                  }
                  @media all and (max-width: 640px) {
                    width: 70%;
                  }
                  @media all and (max-width: 480px) {
                    width: 65%;
                  }
                  @media all and (max-width: 360px) {
                    width: 55%;
                  }
                  ul.names {
                    display: flex;
                    flex-wrap: wrap;
                    li {
                      font-size: 14px;
                      font-weight: 600;
                      margin-right: 8px;
                      color: var(--text-color);
                      &:last-child {
                        margin-right: 0;
                      }
                      @media all and (max-width: 480px) {
                        font-size: 14px;
                      }
                    }
                  }
                }
              }
    
              div.director {
                display: flex;
                justify-content: space-between;
                div.left {
                  width: 15%;
                  @media all and (max-width: 768px) {
                    width: 20%;
                  }
                  @media all and (max-width: 640px) {
                    width: 25%;
                  }
                  @media all and (max-width: 480px) {
                    width: 30%;
                  }
                  @media all and (max-width: 360px) {
                    width: 33%;
                  }
    
                  h6 {
                    font-size: 14px;
                    font-weight: 400;
                    color: var(--inner-text-color);
                  }
                }
    
                div.right {
                  width: 85%;
                  @media all and (max-width: 980px) {
                    width: 75%;
                  }
                  @media all and (max-width: 640px) {
                    width: 70%;
                  }
                  @media all and (max-width: 480px) {
                    width: 65%;
                  }
                  @media all and (max-width: 360px) {
                    width: 55%;
                  }
                  h6 {
                    font-size: 14px;
                    font-weight: 600;
                    margin-right: 8px;
                    color: var(--text-color);
                    @media all and (max-width: 480px) {
                      font-size: 14px;
                    }
                  }
                }
              }
             
            }
            div.subscriptions {
              margin-top: 20px;
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              div.items {
                cursor: pointer;
                // width: 8rem;
                margin-right: 10px;
                background-color: var(--yellow-color);
                padding: 6px 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 13px;
                font-weight: 600;
                color: var(--text-color);
                margin-bottom: 20px;
                border-radius: 30px;
                &.free {
                  background-color: var(--success-color);
                }
              }
            }
          }
        }
        div.showTrailerContainer{
          margin-top: 20px;
          display: flex;
          h6.heading{
            font-size: 14px;
            font-weight: 400;
            color: var(--inner-text-color);
            margin-bottom: 15px;
          }
          div.imageContainer{
            width: 200px;
            position: relative;
            margin-left: 100px;
            cursor: pointer;
            &:hover{
                      div.playIconContainer{
                          visibility: visible;
                animation: fadeIn 0.2s ease-in-out forwards;
                  }
          }
          @media all and (max-width:2500px){
            margin-left: 12%;
          }
          @media all and (max-width:1200px){
            margin-left: 10%;
          }
          @media all and (max-width:980px){
            margin-left: 20%;
          }
            img{
              display: block;
              width: 100%;
              border-radius: 3px;
            }
            div.playIconContainer{
              align-items: center;
              border: 1px solid var(--white-color);
              border-radius: 50%;
              background: rgba(30,30,20,.5);;
              cursor: pointer;
              display: flex;
              height: 40px;
              width: 40px;
              justify-content: center;
              left: 50%;
              position: absolute;
              top: 50%;
              -webkit-transform: translate(-50%,-50%);
              transform: translate(-50%,-50%);
              transition: .3s ease-in-out;
              z-index: 9;
              visibility: hidden;
              animation: fadeOut 0.2s ease-in-out forwards;
              svg{
                  width: 25px !important;
                  height: 25px !important;
                  color: white;
                  fill: white;
              }
          }
          }
        }
        div.commentsSection{
          div.commentsContainer {
            width: 100%;
            margin: 0 auto;
            padding: 60px 0;
            @media all and (max-width:1200px){
              width: 80%;
            }
            @media all and (max-width:768px){
              width: 90%;
            }
            @media all and (max-width:640px){
              width: 100%;
            }
            div.top {
              div.head {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid var(--border-color);
                padding-bottom: 10px;
                span.commentsCount {
                  color: var(--text-color);
                  font-size: 22px;
                  @media all and (max-width:640px){
                    font-size: 16px;
                  }
                }
        
                div.sortContainer {
                  select {
                    background-color: transparent;
                    border: 1px solid var(--border-color);
                    padding: 10px 15px;
                    color: var(--text-color);
                    font-size: 16px;
                    border-radius: 6px;
                    option {
                      color: var(--black-color);
                    }
                  }
                }
              }
              
              div.textContainer {
                display: flex;
                padding: 40px 0 20px;
                border-bottom: 1px solid var(--border-color);
        
                div.imageContainer {
                  margin-right: 20px;
                  width: 45px;
                  height: 45px;
                    img{
                      height: 100%;
                      width: 100%;
                      border-radius: 50%;
                    }
                  span.nameAvatar {
                    width: 45px;
                    height: 45px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: var(--white-color);
                    background-color: green;
                    border-radius: 50%;
                  }
                }
                div.inputContainer {
                  width: 90%;
                  textarea {
                    display: block;
                    width: 100%;
                    height: 45px;
                    padding: 10px 15px;
                    border-radius: 5px;
                    margin-bottom: 20px;
                    &:focus-visible {
                      border: 3px solid var(--button-color) !important;
                      outline: none;
                    }
                  }
                  div.actions {
                    display: flex;
                    justify-content: space-between;
        
                    p {
                      font-size: 14px;
                      color: var(--text-color);
                      span {
                        color: var(--grey-color);
                        cursor: pointer;
                        &:hover {
                          text-decoration: underline;
                        }
                      }
                    }
        
                    div.buttonContainer {
                      button.submit {
                        background-color: var(--button-color);
                        color: var(--text-color);
                        font-weight: 600;
                        padding: 10px 20px;
                        border-radius: 6px;
                        font-size: 14px;
                        cursor: pointer;
                        border: 1px solid var(--button-color);
                        transition: 0.4s ease-in-out;
                        &:hover {
                          background-color: transparent;
                        }
                      }
                    }
                  }
                }
              }
            }
        
            div.bottom {
              div.comments {
                div.eachComment{
                  border-bottom: 1px solid var(--border-color);
                  &:last-child{
                    border: none;
                  }
                  div.containers {
                    padding: 40px 0 25px;
                    
                    div.replyMessagesContainer {
                      padding: 40px 0 0;
                     
                      div.containers{
                        padding: 0 0 0;
                        display: flex;
                        border: none;
                      }
                     
                    }
                    div.commentItem {
                      display: flex;
                      &:last-child{
                        border: none;
                      }
            
                      div.left {
                        width: 45px;
                        margin-right: 20px;
                        div.profileImageContainer{
                          width: 100%;
                          height: 45px;
                          @media all and (max-width:640px){
                            height: 41px;
                          }
                          img{
                            width: 100%;
                            display: block;
                            height: 100%;
                          border-radius: 50%;
        
                          }
                        }
                        span.nameAvatar {
                          width: 100%;
                          height: 45px;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          color: var(--white-color);
                          background-color: green;
                          border-radius: 50%;
                        }
                      }
            
                      div.right {
                        width: 90%;
                        @media all and (max-width:640px){
                          width: 96%;
                        }
                        h1.name {
                          font-size: 16px;
                          color: var(--white-color);
                        }
            
                        span.time {
                          font-size: 13px;
                          color: var(--inner-text-color);
                          font-weight: 600;
                          display: inline-block;
                          margin-bottom: 20px;
                        }
            
                        p.comment {
                          font-size: 16px;
                          color: var(--text-color);
                          margin-bottom: 20px;
                        }
            
                        div.actions {
                          display: flex;
                          span.item {
                            display: flex;
                            align-items: center;
                            margin-right: 20px;
                            font-size: 11px;
                            color: var(--inner-text-color);
                            transition: 0.4s ease-in-out;
                            cursor: pointer;
                            &:hover {
                              opacity: 0.6;
                            }
                            &:last-child {
                              margin-right: 0;
                            }
                            span {
                              svg {
                                width: 15px !important;
                                height: 15px !important;
                                color: rgb(190, 190, 187);
                                fill: rgb(190, 190, 187);
                                margin-right: 5px;
                              }
                            }
                          }
                        }
                      }
                    }
                   
                  }
        
                }
              }
            }
          }
        }
      }
      div.rightContainer {
        width: 25%;
        height: 600px;
        overflow-y: scroll;
         @media all and (max-width:980px){
        width: 100%;
      }
      div.relatedVideos{
        div.releated_Video__Container{
          h1.relatedHeading{
            font-size: 18px;
            color: var(--text-color);
          }
          div.recommendedVideos{
            @media all and (max-width:980px){
              display: flex;
              flex-wrap: wrap;
            }
            div.items{
              @media all and (max-width:980px){
                width: 48%;
                margin-right: 10px;
                &:nth-child(2n){
                  margin-right: 0;
                }
              }
              @media all and (max-width:768px){
                width: 100%;
                margin-right: 0;
                &:nth-child(2n){
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
      }
    }
  
    div.details {
      position: relative;
      z-index: 8;
      display: flex;
      padding: 30px 0 130px;
      @media all and (max-width: 980px) {
        flex-direction: column;
      }
      div.watchNow {
        width: 100%;
        margin-top: 15px;
        button {
          width: 100%;
          display: block;
          background: var(--button-color);
          padding: 15px 10px;
          font-size: 15px;
          color: var(--text-color);
          font-weight: 600;
          border-radius: 8px;
          cursor: pointer;
          transition: 0.3s ease-in-out;
          &:hover {
            background: var(--button-hover);
          }
        }
      }
      div.addtoList {
        width: 100%;
        margin-top: 15px;
        button {
          width: 100%;
          display: block;
          background-color: rgba(150, 153, 163, 0.16);
          padding: 15px 10px;
          font-size: 15px;
          color: var(--text-color);
          font-weight: 600;
          border-radius: 8px;
          cursor: pointer;
          transition: 0.3s ease-in-out;
          &:hover {
            background-color: rgba(203, 203, 208, 0.16);
          }
        }
      }
  
      div.buttons {
        margin-top: 15px;
        display: flex;
        width: 100%;
        div.buttonContainerOne {
          width: 70%;
          position: relative;
          margin-right: 10px;
          @media all and (max-width: 980px) {
            width: 90%;
          }
          @media all and (max-width: 640px) {
            width: 80%;
          }
          @media all and (max-width: 480px) {
            width: 75%;
          }
          div.shareContainer {
            position: absolute;
            bottom: -126px;
            left: -20px;
            z-index: 9;
            background-color: #1c1f29;
            border: 1px solid var(--border-color);
            width: 232px;
            border-radius: 8px;
            display: none;
            @media all and (max-width: 1200px) {
              width: 200px;
            }
            @media all and (max-width: 980px) {
              width: 232px;
              left: 50%;
              transform: translateX(-50%);
            }
            @media all and (max-width: 480px) {
              width: 100%;
            }
            &.active {
              display: block;
            }
            &::after {
              content: "";
              top: -9px;
              width: 0;
              height: 0;
              border-color: transparent transparent rgba(240, 241, 245, 0.16);
              border-style: solid;
              border-width: 0 8px 8px;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              @media all and (max-width: 1200px) {
                left: 40%;
              }
              @media all and (max-width: 980px) {
                left: 50%;
              }
            }
            div.facebookContainer {
              // display: flex;
              // justify-content: space-between;
              // padding: 15px;
              // color: var(--text-color);
              // cursor: pointer;
              // border-bottom: 1px solid var(--border-color);
              button.facebook {
                display: flex !important;
                justify-content: space-between !important;
                padding: 15px !important;
                color: var(--text-color) !important;
                cursor: pointer !important;
                border-bottom: 1px solid var(--border-color) !important;
                width: 100% !important;
                div.icon {
                  width: 10%;
                  svg {
                    width: 20px !important;
                    height: 20px !important;
                    color: var(--grey-color);
                    transition: 0.3s ease-in-out;
                    &:hover {
                      color: var(--text-color);
                    }
                  }
                }
              }
              div.left {
                width: 10%;
              }
              div.right {
                svg {
                  width: 20px !important;
                  height: 20px !important;
                  color: var(--grey-color);
                  transition: 0.3s ease-in-out;
                  &:hover {
                    color: var(--text-color);
                  }
                }
              }
            }
            div.twitterContainer {
              // display: flex;
              // justify-content: space-between;
              // padding: 15px;
              // color: var(--text-color);
  
              // cursor: pointer;
              button.twitter {
                display: flex !important;
                justify-content: space-between !important;
                padding: 15px !important;
                color: var(--text-color) !important;
                cursor: pointer !important;
                // border-bottom: 1px solid var(--border-color) !important;
                width: 100% !important;
                div.icon {
                  width: 10%;
                  svg {
                    width: 20px !important;
                    height: 20px !important;
                    color: var(--grey-color);
                    transition: 0.3s ease-in-out;
                    &:hover {
                      color: var(--text-color);
                    }
                  }
                }
              }
              div.left {
                width: 10%;
              }
              div.right {
                svg {
                  width: 20px !important;
                  height: 20px !important;
                  color: var(--grey-color);
                  transition: 0.3s ease-in-out;
                  &:hover {
                    color: var(--text-color);
                  }
                }
              }
            }
          }
          button.share {
            display: block;
            background-color: rgba(150, 153, 163, 0.16);
            padding: 15px 10px;
            font-size: 15px;
            color: var(--text-color);
            font-weight: 600;
            border-radius: 8px;
            width: 100%;
            cursor: pointer;
            transition: 0.3s ease-in-out;
            &:hover {
              background-color: rgba(203, 203, 208, 0.16);
            }
          }
        }
        div.buttonContainerTwo {
          width: 30%;
          position: relative;
          @media all and (max-width: 980px) {
            width: 10%;
          }
          @media all and (max-width: 640px) {
            width: 18%;
          }
          @media all and (max-width: 480px) {
            width: 25%;
          }
         
        }
      }
      
      div.leftContainer {
        width: 27%;
        margin-right: 40px;
        @media all and (max-width: 980px) {
          display: none;
        }
        div.showCardDetails {
          width: 100%;
          position: sticky;
          top: 0;
          div.imageContainer {
            width: 100%;
            img {
              width: 100%;
              display: block;
              border-radius: 5px;
            }
          }
        }
      }
  
      div.rightContainer {
        width: 100%;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          display: none;
        }
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }
    }
  
    div.parent {
      label {
      }
  
      div {
        a.fff {
        }
      }
    }
  }
  