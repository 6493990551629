div.menubarMinContainer{ 
    padding: 10px 10px; 
    color: var(--text-color);
    font-family: sans-serif;
    font-size: 12px;
    width: 5%;
    z-index: 1;
    bottom: 0;
    top: 12%;
    position: fixed !important;
    background-color: var(--bg-color);
    justify-content: center;
    border-right: 2px solid var(--border-color);
    @media all and (max-width: 2500px) {
      top: 15%;
    }
    @media all and (max-width: 1400px) {
      width: 6%;
    }
    @media all and (max-width: 1200px) {
      width: 7%;
    }
    @media all and (max-width: 980px) {
      width:9%;
    } 
    @media all and (max-width: 768px) {
      width:10%;
    } 
    @media all and (max-width: 640px) {
      display: none;
      border: none;
    }  
div.menuIconMin
{
    align-items: center;
    font-size: 13px;
    display: flex;
    background-color: var(--bg-color);
    height: 50px;
    margin-top: 10px;
    justify-content:center;
    @media all and (max-width: 2500px) {
    height: 62px;
    justify-content: center;
      }
   &:hover{
    background-color: var(--grey-color);
    border-radius: 10px;
    width: 100%;
    svg{
      fill: var(--black-color);
     
    }
  }
    svg{
      width: 25px;
      height: 25px;
      fill: white;
    }          
}
@keyframes fadeIn {
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
}
@keyframes fadeOut {
0% {
  opacity: 1;
}
100% {
  opacity: 0;
}
}
}