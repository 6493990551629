div.menuBodyContainer{
    width: 100%;
    div.menubarContainer{ 
            padding: 13px 10px; 
            color: var(--text-color);
            font-family: sans-serif;
            font-size: 12px;
            position:fixed;
            width: 15%;
            z-index: 99;
            bottom: 0;
            top: 12%;
            background-color: var(--bg-color);
            animation: sidebar-slide-in 0.5s ease-in-out;
            animation-fill-mode:both;
            box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  
            @media all and (max-width: 2500px) {
              width: 13%;
            }
            @media all and (max-width: 1600px) {
                width: 15%;
              }
            @media all and (max-width: 768px) {
              width: 17%;
            }
            @media all and (max-width: 640px) {
              width: 25%;
              // display: inline;
            }  
          @keyframes sidebar-slide-in {
            from {
              transform: translateX(-100%);
            }
            to {
              transform: translateX(0);
            }
          }
          @keyframes sidebar-slide-out {
            from {
              transform: translateX(0);
            }
            to {
              transform: translateX(100%);
            }
          } 
        div.menuIcon
        {
          align-items: center;
          font-size: 13px;
          display: flex;
          background-color: var(--bg-color);
          height: 50px;
          margin-top: 10px;
          justify-content:center;
            
          @media all and (max-width: 2500px) {
          height: 62px;
          justify-content: center;
            }
          
          &:hover{
            background-color: var(--grey-color);
            border-radius: 10px;
            // width: 100%;
            svg{
              fill:var(--black-color);
            }
            li{
              color: var(--black-color);
            }
          }
            svg{
              width: 25px;
              height: 25px;
              fill: white;
            @media all and (max-width: 360px) {
                  width: 20px;
                  }
            }
            li{
                font-size: 13px;
                font-weight: 100;
                margin-left: 10%;  
            }        
        }
      @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      @keyframes fadeOut {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
      div.menuMaxContainer{

      }
    }
    div.menubarContainer {
      &.active { 
        align-items: center;
        width: 100%;
      }
    }
    div.overlay {
      position: fixed;
      top: -100%;
      bottom: 0;
      right: 0;
      left: 100%;
      width: 1000%;
      background-color:transparent;
      z-index: 99;
      display: flex;
      justify-content: center;
      align-items: center;
      animation: fadeIn 0.3s ease-in-out;
    }
  }