div.playerContainer{
    
    width: 90%;
    margin: 0 auto;
    background-color: var(--black-color);
    position: relative;
    div.videoContainer {
      background-color: #000;
      width: 100%;
      // animation: fadeIn 0.5s ease-in-out;
      &.heightIncrease {
        // height: 38em;
        height: 1000px;
        @media all and (max-width: 1200px) {
          height: 25em;
        }
      }
      div.gradientTop {
        position: absolute;
        top: 0;
        z-index: 8;
        width: 100%;
        height: 20%;
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
        //   background: linear-gradient(
        //       179.83deg,
        //       rgb(16, 20, 31) 0%,
        //       rgba(16, 20, 31, 0) 64.46%
        //     ),
        //     linear-gradient(
        //       540deg,
        //       rgb(16, 20, 31) 0%,
        //       rgba(16, 20, 31, 0) 100.44%,
        //       rgba(16, 20, 31, 0.4) -26%
        //     );
        background: linear-gradient(
          0deg,
          rgba(16, 20, 31, 0),
          rgba(16, 20, 31, 0.2) 19%,
          rgba(16, 20, 31, 0.4) 39%,
          rgba(16, 20, 31, 0.8)
        );
      }
      div.player {
        position: relative;
        z-index: 9;
        width: 100%;
        display: block;
        div.closeIcon {
          display: none;
        }
        div.nowPlaying {
          position: absolute;
          top: 20px;
          right: 20px;
          z-index: 7;
          h1 {
            color: var(--text-color);
            @media all and (max-width: 1200px) {
              font-size: 23px;
            }
            @media all and (max-width: 980px) {
              font-size: 20px;
            }
            @media all and (max-width: 768px) {
              font-size: 18px;
            }
            @media all and (max-width: 640px) {
              font-size: 16px;
            }
            @media all and (max-width: 480px) {
              font-size: 14px;
            }
          }
        }
        &.pipMode {
          position: fixed;
          top: 110px;
          width: 22%;
          right: 0;
          z-index: 999999999;
          height: 200px;

          @media all and (max-width: 768px) {
            width: 40%;
          }
          @media all and (max-width: 640px) {
            width: 50%;
          }
          @media all and (max-width: 480px) {
            width: 68%;
          }
          video {
            object-fit: cover;
            height: 100%;
          }
          .video-js {
            padding: 0;
            height: 100%;
          }
          div.gradientTop {
            display: none;
          }
          div.nowPlaying {
            display: none;
          }
          &:hover {
            div.closeIcon {
              position: absolute;
              top: 10px;
              left: 10px;
              z-index: 9;
              background-color: var(--bg-color);
              height: 25px;
              width: 25px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              svg {
                color: var(--text-color);
              }
            }
            div.gradientTop {
              display: block;
            }
          }
        }
      }

      video {
        display: block;
        width: 100%;
      }
    }
  }