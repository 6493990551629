div.contactSupport {
  padding: 130px 0;
  @media all and (max-width: 2500px) {
    padding: 200px 0;
  }
  @media all and (max-width: 1768px) {
    padding: 150px 0;
  }
  h1.heading {
    font-size: 28px;
    margin-bottom: 30px;
    color: var(--text-color);
    text-align: center;
    @media all and (max-width: 640px) {
      font-size: 28px;
    }
  }
  div.formContainer {
    width: 50%;
    margin: 0 auto;
    @media all and (max-width:980px){
      width: 100%;
    }
    form {
      div.full {
        margin-bottom: 20px;
        input,
        select {
          color: var(--text-color) !important;
          
          outline: none;
          border-radius: 5px;
          font-size: 14px;
          background: transparent;
          text-align: left;
          padding: 20px 15px;
          border: 1px solid var(--inner-text-color);
          caret-color: var(--text-color);
          display: block;
          width: 100%;
          color: var(--text-color);
          option {
            color: #000 !important;
          }
          &:focus {
            border: 1px solid var(--text-color);
          }
          @media all and (max-width: 640px) {
            padding: 16px 15px;
          }
        }

        label {
          color: var(--inner-text-color);
          font-size: 16px;
          display: inline-block;
          margin-bottom: 10px;
        }
        &.message{
            display: flex;
            flex-direction: column;
            textarea {
                height: 150px;
                outline: none;
          border-radius: 5px;
          font-size: 14px;
          background: transparent;
          caret-color: var(--text-color);
          color: var(--text-color);
          padding: 20px 15px;
          &:focus {
            border: 1px solid var(--text-color);
          }


            }
        }
        div.buttonContainer { 
          display: flex;
        padding: 18px 20px;
        border-radius: 4px;
        align-items: center;
        margin-bottom: 15px;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        justify-content: center;
        position: relative;
        width: 20rem;
        margin: 30px auto 0;
        @media all and (max-width:640px){
          width: 15rem;
        }
        @media all and (max-width:480px){
          width: 10rem;
        }
        &:hover{
          div.background{
  
            transform: scale(1.0476190476,1.0357142857);
          }
        }
          div.background{
            background: var(--button-color);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 4px;
            transition: 0.1s ease-in-out;
            z-index: 1;
  
          }
          button { 
            background: transparent;
            font-size: 15px;
            color: var( --text-color);
            font-weight: 600;
            position: relative;
            z-index: 2;
            cursor: pointer;
  
  
          }
        }
      }
    }
  }
}
