div.forgotPassword{
    span.error{
        color: #E72A31;
        font-size: 12px;
       text-align: left;
       display: block;
       margin-bottom: 10px;

    }
    div.forgotPasswordContainer{
        padding: 220px 0; 
    @media all and (max-width:640px){
        padding: 260px 0;
    }
        h1.heading{
            font-size: 34px;
            color: var(--text-color);
            text-align: center;
            margin-bottom: 20px;
            @media all and (max-width:480px){
                font-size: 26px;
            }
           
        }
        div.formContainer{
            text-align: center;
            width: 35rem;
            margin: 0 auto;
            @media all and (max-width:640px){
                width: 25rem;
            }
            @media all and (max-width:480px){
                width: 100%;
            }
            form{
                input{
                    background: transparent;
                    outline: none;
                    border: 1px solid var(--inner-text-color);
                    text-align: left;
                    width: 100%;
                padding: 20px 15px;
                border-radius: 8px;
                caret-color:var(--text-color);
                margin-bottom: 30px;
                color: var(--text-color);
                &:focus{
                    border: 1px solid var(--text-color);
                }
                &::placeholder{
                    font-size: 15px;
                    color: rgb(186, 184, 184);
                }
            }
            div.buttonContainer { 
                display: flex;
            padding: 18px 20px;
            border-radius: 4px;
            align-items: center;
            margin-bottom: 15px;
            cursor: pointer;
            transition: 0.2s ease-in-out;
            justify-content: center;
            position: relative;
            width: 20rem;
            margin: 0 auto;
            @media all and (max-width:640px){
                width: 15rem;
            }
            @media all and (max-width:480px){
                width: 10rem;
            }
            &:hover{
                div.background{

                    transform: scale(1.0476190476,1.0357142857);
                }
            }
                div.background{
                    background: var(--text-hover);
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: 4px;
                    transition: 0.1s ease-in-out;
                    z-index: 1;

                }
                button { 
                    background: transparent;
                    font-size: 15px;
                    color: var( --text-color);
                    font-weight: 600;
                    position: relative;
                    z-index: 2;
                    cursor: pointer;

                }
            }
            }
        }
    }
}