@charset "UTF-8";
div.header {
  position: fixed !important;
  top: 0;
  z-index: 99;
  left: 0;
  right: 0;
  width: 100%;
}
div.header div.headerContainer {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  background: var(--bg-color);
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}
@media all and (max-width: 2500px) {
  div.header div.headerContainer {
    padding: 0;
  }
}
@media all and (max-width: 980px) {
  div.header div.headerContainer {
    padding: 0;
  }
}
@media all and (max-width: 480px) {
  div.header div.headerContainer {
    padding: 0;
  }
}
@media all and (max-width: 360px) {
  div.header div.headerContainer {
    padding: 0;
  }
}
div.header div.headerContainer div.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media all and (max-width: 2500px) {
  div.header div.headerContainer div.wrapper {
    width: 96%;
  }
}
@media all and (max-width: 1768px) {
  div.header div.headerContainer div.wrapper {
    width: 97%;
  }
}
@media all and (max-width: 1600px) {
  div.header div.headerContainer div.wrapper {
    width: 97%;
  }
}
@media all and (max-width: 1400px) {
  div.header div.headerContainer div.wrapper {
    width: 96%;
  }
}
@media all and (max-width: 1200px) {
  div.header div.headerContainer div.wrapper {
    width: 96%;
  }
}
@media all and (max-width: 980px) {
  div.header div.headerContainer div.wrapper {
    width: 95%;
  }
}
@media all and (max-width: 768px) {
  div.header div.headerContainer div.wrapper {
    width: 93%;
  }
}
div.header div.headerContainer div.wrapper div.leftContainer {
  display: flex;
  align-items: center;
  position: relative;
}
@media all and (max-width: 480px) {
  div.header div.headerContainer div.wrapper div.leftContainer {
    width: 25%;
  }
}
div.header div.headerContainer div.wrapper div.leftContainer div.menuIconContainer {
  display: flex;
  margin-right: 10px;
  cursor: pointer;
}
@media all and (max-width: 2200px) {
  div.header div.headerContainer div.wrapper div.leftContainer div.menuIconContainer {
    margin-right: 15px;
  }
}
@media all and (max-width: 768px) {
  div.header div.headerContainer div.wrapper div.leftContainer div.menuIconContainer {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.header div.headerContainer div.wrapper div.leftContainer div.menuIconContainer {
    margin-right: 20%;
  }
}
@media all and (max-width: 480px) {
  div.header div.headerContainer div.wrapper div.leftContainer div.menuIconContainer {
    margin-right: 20px;
  }
}
@media all and (max-width: 360px) {
  div.header div.headerContainer div.wrapper div.leftContainer div.menuIconContainer {
    margin-right: 10px;
  }
}
div.header div.headerContainer div.wrapper div.leftContainer div.menuIconContainer:hover {
  background-color: var(--grey-color);
  border-radius: 50%;
}
div.header div.headerContainer div.wrapper div.leftContainer div.menuIconContainer:hover svg {
  color: var(--black-color);
}
div.header div.headerContainer div.wrapper div.leftContainer div.menuIconContainer svg {
  width: 30px !important;
  height: 30px !important;
  color: var(--text-color);
}
@media all and (max-width: 768px) {
  div.header div.headerContainer div.wrapper div.leftContainer div.menuIconContainer svg {
    width: 25px !important;
  }
}
@media all and (max-width: 640px) {
  div.header div.headerContainer div.wrapper div.leftContainer div.menuIconContainer svg {
    width: 22px !important;
  }
}
@media all and (max-width: 480px) {
  div.header div.headerContainer div.wrapper div.leftContainer div.menuIconContainer svg {
    width: 20px !important;
    height: 20px !important;
  }
}
@media all and (max-width: 360px) {
  div.header div.headerContainer div.wrapper div.leftContainer div.menuIconContainer svg {
    width: 17px !important;
  }
}
div.header div.headerContainer div.wrapper div.leftContainer div.logoContainer h1 {
  width: 100px;
  max-height: 40px;
}
@media all and (max-width: 640px) {
  div.header div.headerContainer div.wrapper div.leftContainer div.logoContainer h1 {
    width: 85px;
  }
}
@media all and (max-width: 480px) {
  div.header div.headerContainer div.wrapper div.leftContainer div.logoContainer h1 {
    width: 72px;
  }
}
@media all and (max-width: 360px) {
  div.header div.headerContainer div.wrapper div.leftContainer div.logoContainer h1 {
    width: 72px;
  }
}
div.header div.headerContainer div.wrapper div.leftContainer div.logoContainer h1 img {
  height: 50px;
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
}
@media all and (max-width: 1200px) {
  div.header div.headerContainer div.wrapper div.leftContainer div.logoContainer h1 img {
    height: 30px;
  }
}
@media all and (max-width: 980px) {
  div.header div.headerContainer div.wrapper div.leftContainer div.logoContainer h1 img {
    height: 30px;
  }
}
@media all and (max-width: 768px) {
  div.header div.headerContainer div.wrapper div.leftContainer div.logoContainer h1 img {
    height: 28px;
  }
}
@media all and (max-width: 640px) {
  div.header div.headerContainer div.wrapper div.leftContainer div.logoContainer h1 img {
    height: 27px;
    margin-left: -23px;
  }
}
@media all and (max-width: 360px) {
  div.header div.headerContainer div.wrapper div.leftContainer div.logoContainer h1 img {
    height: 22px;
    margin-left: -10px;
  }
}
div.header div.headerContainer div.wrapper div.centerContainer {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  width: 100%;
  height: 40px;
  border: 1px solid lightgray;
  border-radius: 20px;
  background: var(--bg-color);
  margin-left: 10%;
}
@media all and (max-width: 2500px) {
  div.header div.headerContainer div.wrapper div.centerContainer {
    width: 30%;
  }
}
@media all and (max-width: 1980px) {
  div.header div.headerContainer div.wrapper div.centerContainer {
    width: 35%;
  }
}
@media all and (max-width: 1600px) {
  div.header div.headerContainer div.wrapper div.centerContainer {
    width: 42%;
  }
}
@media all and (max-width: 1400px) {
  div.header div.headerContainer div.wrapper div.centerContainer {
    width: 45%;
  }
}
@media all and (max-width: 980px) {
  div.header div.headerContainer div.wrapper div.centerContainer {
    margin-left: -6%;
    width: 50%;
  }
}
@media all and (max-width: 768px) {
  div.header div.headerContainer div.wrapper div.centerContainer {
    height: 25px;
    width: 50%;
  }
}
@media all and (max-width: 640px) {
  div.header div.headerContainer div.wrapper div.centerContainer {
    width: 50%;
  }
}
@media all and (max-width: 480px) {
  div.header div.headerContainer div.wrapper div.centerContainer {
    height: 20px;
    width: 50%;
  }
}
@media all and (max-width: 360px) {
  div.header div.headerContainer div.wrapper div.centerContainer {
    height: 20px;
    width: 50%;
  }
}
div.header div.headerContainer div.wrapper div.centerContainer div.inputSearchContainer {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 0;
  position: relative;
  margin-left: 20px;
}
@media all and (max-width: 480px) {
  div.header div.headerContainer div.wrapper div.centerContainer div.inputSearchContainer {
    margin-left: 10px;
  }
}
@media all and (max-width: 360px) {
  div.header div.headerContainer div.wrapper div.centerContainer div.inputSearchContainer {
    margin-left: 10px;
  }
}
div.header div.headerContainer div.wrapper div.centerContainer div.inputSearchContainer input {
  width: 80%;
  font-size: 15px;
  flex: 1;
  border: none;
  height: 35px;
  position: relative;
  outline: none;
  background-color: transparent;
  color: var(--text-color);
}
@media all and (max-width: 768px) {
  div.header div.headerContainer div.wrapper div.centerContainer div.inputSearchContainer input {
    font-size: 13px;
  }
}
@media all and (max-width: 640px) {
  div.header div.headerContainer div.wrapper div.centerContainer div.inputSearchContainer input {
    font-size: 12px;
  }
}
@media all and (max-width: 480px) {
  div.header div.headerContainer div.wrapper div.centerContainer div.inputSearchContainer input {
    font-size: 12px;
  }
}
@media all and (max-width: 360px) {
  div.header div.headerContainer div.wrapper div.centerContainer div.inputSearchContainer input {
    font-size: 10px;
  }
}
div.header div.headerContainer div.wrapper div.centerContainer div.inputSearchContainer.showInput {
  padding: 8px 6px;
  background-color: var(--gray-color);
  align-items: center;
  width: 80%;
  justify-content: space-between;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.header div.headerContainer div.wrapper div.centerContainer div.inputSearchContainer.showInput input {
  display: block;
}
@media all and (max-width: 768px) {
  div.header div.headerContainer div.wrapper div.centerContainer div.inputSearchContainer.showInput {
    display: none;
  }
}
div.header div.headerContainer div.wrapper div.centerContainer div.inputSearchContainer input:focus {
  outline: none;
}
div.header div.headerContainer div.wrapper div.centerContainer div.inputSearchContainer button {
  background-color: var(--grey-color);
  width: 80px;
  height: 40px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border: none;
  border-left: 1px solid lightgray;
  cursor: pointer;
}
@media all and (max-width: 980px) {
  div.header div.headerContainer div.wrapper div.centerContainer div.inputSearchContainer button {
    width: 50px;
  }
}
@media all and (max-width: 768px) {
  div.header div.headerContainer div.wrapper div.centerContainer div.inputSearchContainer button {
    height: 25px;
    width: 50px;
  }
}
@media all and (max-width: 480px) {
  div.header div.headerContainer div.wrapper div.centerContainer div.inputSearchContainer button {
    height: 20px;
    width: 40px;
  }
}
@media all and (max-width: 360px) {
  div.header div.headerContainer div.wrapper div.centerContainer div.inputSearchContainer button {
    height: 20px;
    width: 30px;
  }
}
div.header div.headerContainer div.wrapper div.centerContainer div.inputSearchContainer button svg {
  width: 10px;
  margin-top: 3px;
}
@media all and (max-width: 2500px) {
  div.header div.headerContainer div.wrapper div.centerContainer div.inputSearchContainer button svg {
    width: 15px;
  }
}
div.header div.headerContainer div.wrapper div.centerContainer div.inputSearchContainer button:hover {
  background-color: lightgrey;
}
div.header div.headerContainer div.wrapper div.rightContainer {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: right;
  margin-left: 15%;
  transition: 0.2s ease-in-out;
}
@media all and (max-width: 980px) {
  div.header div.headerContainer div.wrapper div.rightContainer {
    margin-left: 0;
  }
}
@media all and (max-width: 360px) {
  div.header div.headerContainer div.wrapper div.rightContainer {
    margin-left: 10px;
  }
}
div.header div.headerContainer div.wrapper div.rightContainer div.userIconContainer {
  display: flex;
  width: 50px;
  align-items: center;
  cursor: pointer;
}
@media all and (max-width: 2500px) {
  div.header div.headerContainer div.wrapper div.rightContainer div.userIconContainer {
    width: 24px;
  }
}
@media all and (max-width: 640px) {
  div.header div.headerContainer div.wrapper div.rightContainer div.userIconContainer {
    width: 20px;
  }
}
@media all and (max-width: 320px) {
  div.header div.headerContainer div.wrapper div.rightContainer div.userIconContainer {
    width: 12px;
  }
}
div.header div.headerContainer div.wrapper div.rightContainer div.userIconContainer svg {
  align-items: center;
  width: 30px;
  color: var(--text-color);
}
@media all and (max-width: 640px) {
  div.header div.headerContainer div.wrapper div.rightContainer div.userIconContainer svg {
    width: 25px;
  }
}
@media all and (max-width: 480px) {
  div.header div.headerContainer div.wrapper div.rightContainer div.userIconContainer svg {
    width: 15px;
  }
}
@media all and (max-width: 360px) {
  div.header div.headerContainer div.wrapper div.rightContainer div.userIconContainer svg {
    width: 14px;
  }
}
div.header div.sidebarContainer {
  top: 19%;
  background-color: var(--bg-color);
  align-items: center;
  padding: 5px 5px;
  color: var(--text-color);
  font-family: sans-serif;
  font-size: 12px;
  position: fixed;
  width: 10%;
  z-index: 99;
  bottom: 0;
  box-shadow: none;
}
@media all and (max-width: 2500px) {
  div.header div.sidebarContainer {
    top: 11% !important;
  }
}
@media all and (max-width: 2200px) {
  div.header div.sidebarContainer {
    top: 13% !important;
  }
}
@media all and (max-width: 1600px) {
  div.header div.sidebarContainer {
    top: 13% !important;
  }
}
@media all and (max-width: 1400px) {
  div.header div.sidebarContainer {
    top: 18% !important;
  }
}
@media all and (max-width: 1200px) {
  div.header div.sidebarContainer h2 {
    display: none;
  }
}
@media all and (max-width: 640px) {
  div.header div.sidebarContainer {
    display: none;
  }
}
div.header div.menuBarOverlay.active {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--gray-color);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.header div.menuBarOverlay.active {
  display: flex;
}
div.header div.searchDropContainer {
  position: absolute;
  top: 45px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-size: 13px;
  background-color: var(--bg-color);
  width: 100%;
}
@media all and (max-width: 640px) {
  div.header div.searchDropContainer {
    width: 120%;
  }
}
@media all and (max-width: 480px) {
  div.header div.searchDropContainer {
    width: 130%;
    right: 10px;
  }
}
@media all and (max-width: 480px) {
  div.header div.searchDropContainer {
    width: 135%;
    right: 10px;
  }
}
div.header div.searchDropContainer.hide {
  display: flex;
}
div.header div.rightUserContainer.Active {
  background-color: var(--gray-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
div.header div.rightUserContainer {
  display: flex;
}
div.header div.browseMore {
  display: none;
}
@media all and (max-width: 1200px) {
  div.header div.browseMore {
    display: none;
  }
}
div.header div.browseMore div.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--overlay);
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease-in-out;
}
div.header div.browseMore div.browseMoreContainer {
  animation: fadeIn 0.7s ease-in-out;
  position: fixed;
  top: 14%;
  left: 10%;
  background-color: #fff;
  display: flex;
  border-radius: 6px;
  z-index: 999;
}
div.header div.browseMore div.browseMoreContainer::after {
  content: "";
  top: 81px;
  /* width: 7px; */
  /* height: 4px; */
  border-color: transparent transparent #fff;
  border-style: solid;
  border-width: 0px 9px 15px;
  position: fixed;
  /* right: 2%; */
  /* transform: translateX(-580%); */
  left: 16.5%;
}
@media all and (max-width: 1980px) {
  div.header div.browseMore div.browseMoreContainer::after {
    top: 11%;
    left: 13.5%;
  }
}
@media all and (max-width: 1900px) {
  div.header div.browseMore div.browseMoreContainer::after {
    top: 13%;
    left: 13.5%;
  }
}
div.header div.browseMore div.browseMoreContainer h1.categoryName {
  font-size: 14px;
  margin-bottom: 20px;
  color: var(--grey-color);
}
div.header div.browseMore div.browseMoreContainer ul {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
div.header div.browseMore div.browseMoreContainer ul li {
  margin-bottom: 8px;
  cursor: pointer;
}
div.header div.browseMore div.browseMoreContainer ul li:hover span {
  color: var(--text-hover);
}
div.header div.browseMore div.browseMoreContainer ul li span {
  font-size: 14px;
  color: #10141f;
  transition: 0.3s ease-in-out;
}
div.header div.browseMore div.browseMoreContainer div.left {
  padding: 30px 25px;
  overflow-y: scroll;
  height: 500px;
}
div.header div.browseMore div.browseMoreContainer div.left::-webkit-scrollbar {
  width: 8px;
}
div.header div.browseMore div.browseMoreContainer div.left::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
div.header div.browseMore div.browseMoreContainer div.left::-webkit-scrollbar-thumb:hover {
  background-color: rgb(125, 128, 128);
}
div.header div.browseMore div.browseMoreContainer div.center {
  width: 40%;
  background-color: #f0f3f5;
  padding: 30px 25px;
}
div.header div.browseMore div.browseMoreContainer div.center div.sections {
  display: flex;
  justify-content: space-between;
}
div.header div.browseMore div.browseMoreContainer div.center div.sections div.leftSection {
  width: 50%;
}
div.header div.browseMore div.browseMoreContainer div.center div.sections div.rightSection {
  width: 50%;
}
div.header div.browseMore div.browseMoreContainer div.right {
  width: 40%;
  padding: 30px 25px;
}
div.header div.browseMore.active {
  display: block;
  animation: fadeIn 0.4s ease-in-out;
}
@media all and (max-width: 1200px) {
  div.header div.browseMore.active {
    display: none;
  }
}
div.header div.browseMoreResponsive div.imageListContainer {
  padding: 5px 5px;
  color: var(--text-color);
  font-family: sans-serif;
  font-size: 12px;
  position: fixed;
  z-index: 9999;
  height: 70%;
  width: 35%;
  left: 32%;
  top: 13%;
  background-color: var(--grey-color);
  border-radius: 10px;
  overflow-y: scroll;
}
div.header div.browseMoreResponsive div.imageListContainer ul.listContainer div.categorySection {
  margin: 10px;
}
div.header div.browseMoreResponsive div.imageListContainer ul.listContainer div.categorySection h6.heading {
  font-size: 13px;
  color: gray;
  margin-left: 10px;
}
div.header div.browseMoreResponsive div.imageListContainer ul.listContainer div.categorySection li.lists div.list {
  display: inline-flex;
  margin-bottom: 3px;
  cursor: pointer;
  width: 100%;
}
div.header div.browseMoreResponsive div.imageListContainer ul.listContainer div.categorySection li.lists div.list:hover {
  background-color: gray;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
}
div.header div.browseMoreResponsive div.imageListContainer ul.listContainer div.categorySection li.lists div.list div.left div.imageContainer {
  align-items: center;
  padding: 10px;
  color: var(--text-color);
  background-color: var(--gray-color);
  width: 100px !important;
}
div.header div.browseMoreResponsive div.imageListContainer ul.listContainer div.categorySection li.lists div.list div.left div.imageContainer img {
  width: 100%;
  height: 100%;
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
}
@media all and (max-width: 980px) {
  div.header div.browseMoreResponsive div.imageListContainer ul.listContainer div.categorySection li.lists div.list div.left div.imageContainer {
    width: 80px !important;
  }
}
@media all and (max-width: 480px) {
  div.header div.browseMoreResponsive div.imageListContainer ul.listContainer div.categorySection li.lists div.list div.left div.imageContainer {
    width: 60px !important;
  }
}
div.header div.browseMoreResponsive div.imageListContainer ul.listContainer div.categorySection li.lists div.list div.right h1.title {
  font-size: 14px;
  margin-left: 20px;
  margin-top: 12px;
  color: var(--black-color);
}
@media all and (max-width: 768px) {
  div.header div.browseMoreResponsive div.imageListContainer ul.listContainer div.categorySection li.lists div.list div.right h1.title {
    margin-left: 0px;
  }
}
@media all and (max-width: 640px) {
  div.header div.browseMoreResponsive div.imageListContainer ul.listContainer div.categorySection li.lists div.list div.right h1.title {
    font-size: 11px;
  }
}
div.header div.browseMoreResponsive div.imageListContainer ul.listContainer div.categorySection li.lists div.list:hover {
  background-color: var(--grey-color);
  border-radius: 10px;
  width: 100%;
  margin-left: 0;
}
@media all and (max-width: 640px) {
  div.header div.browseMoreResponsive div.imageListContainer ul.listContainer div.categorySection li.lists div.list {
    margin-bottom: 0px;
  }
}
div.header div.browseMoreResponsive div.imageListContainer div.seeAll {
  margin-left: 10%;
}
div.header div.browseMoreResponsive div.imageListContainer div.seeAll button {
  font-weight: 600;
  cursor: pointer;
}
div.header div.browseMoreResponsive div.imageListContainer div.seeAll button:hover {
  color: gray;
}
@media all and (max-width: 2500px) {
  div.header div.browseMoreResponsive div.imageListContainer {
    width: 30%;
    left: 35%;
  }
}
@media all and (max-width: 1600px) {
  div.header div.browseMoreResponsive div.imageListContainer {
    width: 36%;
    left: 31%;
  }
}
@media all and (max-width: 980px) {
  div.header div.browseMoreResponsive div.imageListContainer {
    width: 47%;
    left: 30%;
  }
}
@media all and (max-width: 640px) {
  div.header div.browseMoreResponsive div.imageListContainer {
    width: 51%;
  }
}
div.header div.browseMoreResponsive div.menuListContainer {
  align-items: center;
  padding: 5px 5px;
  color: var(--text-color);
  font-family: sans-serif;
  font-size: 12px;
  position: fixed;
  width: 14%;
  z-index: 9999;
  background-color: var(--bg-color);
  right: 5px;
  margin-top: 5%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
@media all and (max-width: 2500px) {
  div.header div.browseMoreResponsive div.menuListContainer {
    margin-top: 5%;
  }
}
@media all and (max-width: 1400px) {
  div.header div.browseMoreResponsive div.menuListContainer {
    margin-left: 85%;
  }
}
@media all and (max-width: 1200px) {
  div.header div.browseMoreResponsive div.menuListContainer {
    margin-top: 8%;
    margin-left: 83%;
    width: 16%;
  }
}
@media all and (max-width: 980px) {
  div.header div.browseMoreResponsive div.menuListContainer {
    width: 18%;
    margin-left: 80%;
    margin-top: 9%;
  }
}
@media all and (max-width: 768px) {
  div.header div.browseMoreResponsive div.menuListContainer {
    width: 25%;
    margin-left: 73%;
    margin-top: 11%;
  }
}
@media all and (max-width: 640px) {
  div.header div.browseMoreResponsive div.menuListContainer {
    width: 30%;
    margin-left: 68%;
    margin-top: 15%;
  }
}
@media all and (max-width: 480px) {
  div.header div.browseMoreResponsive div.menuListContainer {
    width: 40%;
    margin-left: 59%;
    margin-top: 20%;
  }
}
@media all and (max-width: 360px) {
  div.header div.browseMoreResponsive div.menuListContainer {
    margin-top: 20%;
    width: 50%;
    margin-left: 50%;
  }
}
div.header div.browseMoreResponsive div.menuListContainer.Active {
  width: 100px;
}
div.header div.browseMoreResponsive div.menuListContainer div.userContainerList {
  display: inline-block;
  align-items: center;
  padding: 10px;
  color: var(--text-color);
  background-color: var(--gray-color);
  z-index: 999;
}
@media all and (max-width: 2500px) {
  div.header div.browseMoreResponsive div.menuListContainer div.userContainerList {
    width: 75%;
  }
}
@media all and (max-width: 2200px) {
  div.header div.browseMoreResponsive div.menuListContainer div.userContainerList {
    width: 100%;
  }
}
div.header div.browseMoreResponsive div.menuListContainer div.userContainerList div.userRow {
  font-size: 13px;
  height: 45px;
  display: inline-flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
@media all and (max-width: 2500px) {
  div.header div.browseMoreResponsive div.menuListContainer div.userContainerList div.userRow {
    margin-top: 15px;
  }
}
@media all and (max-width: 1200px) {
  div.header div.browseMoreResponsive div.menuListContainer div.userContainerList div.userRow {
    margin-top: 5px;
  }
}
div.header div.browseMoreResponsive div.menuListContainer div.userContainerList div.userRow svg {
  width: 25px;
  height: 25px;
  fill: white !important;
}
div.header div.browseMoreResponsive div.menuListContainer div.userContainerList div.userRow li {
  margin-left: 20px;
  font-size: 13px;
  font-weight: 100;
}
@media all and (max-width: 2500px) {
  div.header div.browseMoreResponsive div.menuListContainer div.userContainerList div.userRow li {
    margin-left: 30px;
    font-size: 17px;
  }
}
@media all and (max-width: 1768px) {
  div.header div.browseMoreResponsive div.menuListContainer div.userContainerList div.userRow li {
    font-size: 15px;
  }
}
@media all and (max-width: 1600px) {
  div.header div.browseMoreResponsive div.menuListContainer div.userContainerList div.userRow li {
    font-size: 14px;
    margin-left: 20px;
  }
}
@media all and (max-width: 1400px) {
  div.header div.browseMoreResponsive div.menuListContainer div.userContainerList div.userRow li {
    font-size: 10px;
    margin-left: 10px;
  }
}
div.header div.browseMoreResponsive div.menuListContainer div.userContainerList div.userRow:hover {
  background-color: var(--grey-color);
  border-radius: 10px;
  color: var(--black-color);
}
div.header div.browseMoreResponsive div.menuListContainer div.userContainerList div.userRow:hover svg {
  fill: var(--black-color) !important;
  margin-left: 5px;
}

div.featured {
  height: 599px;
  overflow: hidden;
  width: 100%;
  position: relative;
  border-bottom: 1px solid var(--border-color);
  padding: 100px 0 px 0 px;
}
@media all and (max-width: 2500px) {
  div.featured {
    height: 980px;
  }
}
@media all and (max-width: 2200px) {
  div.featured {
    height: 690px;
  }
}
@media all and (max-width: 1980px) {
  div.featured {
    height: 630px;
  }
}
@media all and (max-width: 1768px) {
  div.featured {
    height: 770px;
  }
}
@media all and (max-width: 1600px) {
  div.featured {
    height: 720px;
  }
}
@media all and (max-width: 768px) {
  div.featured {
    height: 650px;
  }
}
@media all and (max-width: 640px) {
  div.featured {
    height: 685px;
  }
}
@media all and (max-width: 480px) {
  div.featured {
    height: 655px;
  }
}
div.featured .swiper {
  height: 100%;
}
div.featured div.bgImageContainer {
  width: 100%;
  display: block;
  height: 100%;
}
div.featured div.bgImageContainer img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media all and (max-width: 768px) {
  div.featured div.bgImageContainer img {
    -o-object-fit: cover;
       object-fit: cover;
  }
}
div.featured div.bottomGradient {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(78.83deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 61.46%), linear-gradient(360deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 48.44%, rgba(16, 20, 31, 0.4) 100%);
}
div.featured div.gallerySection {
  position: absolute;
  z-index: 9;
  right: 0;
  bottom: 42px;
  width: 22rem;
}
@media all and (max-width: 1400px) {
  div.featured div.gallerySection {
    width: 18.5rem;
  }
}
@media all and (max-width: 1200px) {
  div.featured div.gallerySection {
    width: 18rem;
  }
}
@media all and (max-width: 980px) {
  div.featured div.gallerySection {
    display: none;
  }
}
div.featured div.gallerySection .swiper {
  overflow: hidden;
}
div.featured div.gallerySection .swiper-slide {
  opacity: 0.4;
}
div.featured div.gallerySection .swiper-slide-active {
  opacity: 1;
}
div.featured div.gallerySection div.imageContainer {
  width: 100%;
}
div.featured div.gallerySection div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 6px;
}
div.featured div.contents {
  position: absolute;
  bottom: 30px;
  width: 92%;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
div.featured div.contents span.line {
  height: 1px;
  background-color: var(--border-color);
  width: 30%;
  display: block;
  margin-bottom: 20px;
}
@media all and (max-width: 768px) {
  div.featured div.contents span.line {
    display: none;
  }
}
div.featured div.contents h1.title {
  font-size: 55px;
  color: var(--text-color);
  margin-bottom: 30px;
  animation: slideUp 0.5s ease-in-out;
}
@media all and (max-width: 768px) {
  div.featured div.contents h1.title {
    font-size: 36px;
    margin-bottom: 0;
  }
}
div.featured div.contents div.containers {
  display: flex;
  align-items: center;
}
div.featured div.contents div.containers div.left {
  width: 8%;
  margin-right: 30px;
}
@media all and (max-width: 1200px) {
  div.featured div.contents div.containers div.left {
    width: 12%;
  }
}
@media all and (max-width: 980px) {
  div.featured div.contents div.containers div.left {
    width: 14%;
  }
}
@media all and (max-width: 768px) {
  div.featured div.contents div.containers div.left {
    display: none;
  }
}
div.featured div.contents div.containers div.left div.activeThumb {
  width: 100%;
}
div.featured div.contents div.containers div.left div.activeThumb div.imageContainer {
  width: 100%;
}
div.featured div.contents div.containers div.left div.activeThumb div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 8px;
}
div.featured div.contents div.containers div.right {
  width: 78%;
}
div.featured div.contents div.containers div.right div.metaData {
  padding: 35px 0;
  margin: 0 auto;
}
@media all and (max-width: 768px) {
  div.featured div.contents div.containers div.right div.metaData {
    padding: 20px 0;
  }
}
div.featured div.contents div.containers div.right div.metaData div.datas {
  animation: slideUp 0.5s ease-in-out;
}
div.featured div.contents div.containers div.right div.metaData div.datas span {
  color: var(--grey-color);
  font-size: 18px;
}
@media all and (max-width: 768px) {
  div.featured div.contents div.containers div.right div.metaData div.datas span {
    font-size: 15px;
  }
}
div.featured div.contents div.containers div.right div.metaData div.datas span.year {
  margin-right: 6px;
}
div.featured div.contents div.containers div.right div.metaData div.datas span.duration {
  margin-right: 6px;
}
div.featured div.contents div.containers div.right div.metaData div.datas span.duration.showDot::before {
  content: "·";
  margin-right: 6px;
}
div.featured div.contents div.containers div.right div.metaData div.datas span.certificate {
  display: inline-block;
  padding: 1px 6px;
  background-color: rgba(150, 153, 163, 0.32);
  font-size: 12px;
  font-weight: 700;
  color: var(--text-color);
  border-radius: 4px;
}
div.featured div.contents div.containers div.right div.metaData div.datas div.genres {
  margin-top: 6px;
}
div.featured div.contents div.containers div.right div.metaData div.datas div.genres span {
  margin-right: 6px;
}
div.featured div.contents div.containers div.right div.metaData div.datas div.genres span::before {
  content: "·";
  margin-right: 6px;
}
div.featured div.contents div.containers div.right div.metaData div.datas div.genres span:first-child::before {
  display: none;
}
div.featured div.contents div.containers div.right div.metaData div.datas div.genres span:last-child {
  margin-right: 0;
}
div.featured div.contents div.containers div.right div.metaData div.buttonContainer {
  margin-top: 30px;
  background: var(--button-color);
  padding: 12px 24px;
  width: 13rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  color: var(--text-color);
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.featured div.contents div.containers div.right div.metaData div.buttonContainer.no-flex {
  justify-content: left;
}
div.featured div.contents div.containers div.right div.metaData div.buttonContainer svg {
  margin-right: 10px;
}
div.featured div.contents div.containers div.right div.metaData div.buttonContainer:hover {
  background: var(--button-hover);
  transform: scale(1.0476190476, 1.0357142857);
}
div.featured div.contents div.containers div.right div.metaData div.buttonContainer span {
  color: var(--text-color);
  font-weight: 700;
  font-size: 15px;
}
div.featured div.contents div.containers div.right div.metaData div.buttonContainer span.free {
  display: inline-block;
  padding: 1px 6px;
  color: #000;
  background-color: var(--text-color);
  border-radius: 30px;
  font-size: 10px;
}
@keyframes slideUp {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  50% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

div.homePage {
  overflow-x: hidden;
}
div.homePage .swiper {
  overflow: visible;
}
div.homePage .swiper-button-disabled {
  display: none !important;
}
div.homePage .swiper-button-next {
  right: -82px;
  height: 100%;
  width: 6%;
  top: 5%;
  background: linear-gradient(-97deg, rgb(28, 31, 41) 0.98%, rgba(28, 31, 41, 0.4) 100.23%, rgba(28, 31, 41, 0.2) 100.16%, rgba(28, 31, 41, 0.0001) 52.12%);
}
@media all and (min-width: 320px) {
  div.homePage .swiper-button-next {
    display: flex;
    right: -26px;
    width: 9%;
    top: 11%;
  }
}
@media all and (min-width: 360px) {
  div.homePage .swiper-button-next {
    top: 9%;
    right: -42px;
  }
}
@media all and (min-width: 480px) {
  div.homePage .swiper-button-next {
    top: 7%;
    width: 5%;
    right: -51px;
  }
}
@media all and (min-width: 640px) {
  div.homePage .swiper-button-next {
    top: 5%;
    right: -60px;
  }
}
@media all and (min-width: 768px) {
  div.homePage .swiper-button-next {
    width: 4%;
    right: -80px;
  }
}
@media all and (min-width: 980px) {
  div.homePage .swiper-button-next {
    width: 4%;
    right: -47px;
  }
}
@media all and (min-width: 1200px) {
  div.homePage .swiper-button-next {
    right: -57px;
  }
}
@media all and (min-width: 1400px) {
  div.homePage .swiper-button-next {
    right: -67px;
  }
}
@media all and (min-width: 1600px) {
  div.homePage .swiper-button-next {
    right: -70px;
  }
}
@media all and (min-width: 1768px) {
  div.homePage .swiper-button-next {
    right: -79px;
  }
}
div.homePage .swiper-button-prev {
  left: -77px;
  height: 100%;
  width: 5%;
  top: 5%;
  background: linear-gradient(-97deg, rgb(28, 31, 41) 0.98%, rgba(28, 31, 41, 0.4) 100.23%, rgba(28, 31, 41, 0.2) 100.16%, rgba(28, 31, 41, 0.0001) 52.12%);
}
@media all and (min-width: 320px) {
  div.homePage .swiper-button-prev {
    display: flex;
    left: -26px;
    width: 9%;
    top: 11%;
  }
}
@media all and (min-width: 360px) {
  div.homePage .swiper-button-prev {
    top: 9%;
    left: -42px;
  }
}
@media all and (min-width: 480px) {
  div.homePage .swiper-button-prev {
    top: 7%;
    width: 5%;
    left: -51px;
  }
}
@media all and (min-width: 640px) {
  div.homePage .swiper-button-prev {
    top: 5%;
    left: -60px;
  }
}
@media all and (min-width: 768px) {
  div.homePage .swiper-button-prev {
    width: 4%;
    left: -80px;
  }
}
@media all and (min-width: 980px) {
  div.homePage .swiper-button-prev {
    width: 4%;
    left: -47px;
  }
}
@media all and (min-width: 1200px) {
  div.homePage .swiper-button-prev {
    left: -57px;
  }
}
@media all and (min-width: 1400px) {
  div.homePage .swiper-button-prev {
    left: -67px;
  }
}
@media all and (min-width: 1600px) {
  div.homePage .swiper-button-prev {
    left: -70px;
  }
}
@media all and (min-width: 1768px) {
  div.homePage .swiper-button-prev {
    left: -79px;
  }
}

div.categoriesContainer {
  padding: 20px 0 8px;
}
div.categoriesContainer:hover .swiper-button-next::after {
  opacity: 1;
}
div.categoriesContainer:hover .swiper-button-prev::after {
  opacity: 1;
}
div.categoriesContainer .swiper.landscape .swiper-button-next {
  right: -82px;
  height: 100%;
  width: 6%;
  top: 5%;
  background: linear-gradient(-97deg, rgb(28, 31, 41) 0.98%, rgba(28, 31, 41, 0.4) 100.23%, rgba(28, 31, 41, 0.2) 100.16%, rgba(28, 31, 41, 0.0001) 52.12%);
}
@media all and (min-width: 320px) {
  div.categoriesContainer .swiper.landscape .swiper-button-next {
    display: none;
  }
}
@media all and (min-width: 980px) {
  div.categoriesContainer .swiper.landscape .swiper-button-next {
    display: flex;
    width: 4%;
    right: -47px;
    top: 8%;
  }
}
@media all and (min-width: 1200px) {
  div.categoriesContainer .swiper.landscape .swiper-button-next {
    right: -57px;
    width: 4% !important;
    top: 7%;
  }
}
@media all and (min-width: 1400px) {
  div.categoriesContainer .swiper.landscape .swiper-button-next {
    top: 6%;
    right: -67px;
  }
}
@media all and (min-width: 1600px) {
  div.categoriesContainer .swiper.landscape .swiper-button-next {
    right: -70px;
  }
}
@media all and (min-width: 1768px) {
  div.categoriesContainer .swiper.landscape .swiper-button-next {
    right: -79px;
    top: 5%;
  }
}
@media all and (min-width: 1980px) {
  div.categoriesContainer .swiper.landscape .swiper-button-next {
    right: -91px;
  }
}
@media all and (min-width: 2200px) {
  div.categoriesContainer .swiper.landscape .swiper-button-next {
    right: -99px;
  }
}
div.categoriesContainer .swiper.landscape .swiper-button-prev {
  left: -77px;
  height: 100%;
  width: 5%;
  top: 5%;
  background: linear-gradient(-97deg, rgb(28, 31, 41) 0.98%, rgba(28, 31, 41, 0.4) 100.23%, rgba(28, 31, 41, 0.2) 100.16%, rgba(28, 31, 41, 0.0001) 52.12%);
}
@media all and (min-width: 320px) {
  div.categoriesContainer .swiper.landscape .swiper-button-prev {
    display: none;
  }
}
@media all and (min-width: 980px) {
  div.categoriesContainer .swiper.landscape .swiper-button-prev {
    display: flex;
    width: 4%;
    left: -47px;
    top: 8%;
  }
}
@media all and (min-width: 1200px) {
  div.categoriesContainer .swiper.landscape .swiper-button-prev {
    left: -57px;
    width: 4% !important;
    top: 7%;
  }
}
@media all and (min-width: 1400px) {
  div.categoriesContainer .swiper.landscape .swiper-button-prev {
    left: -67px;
    top: 6%;
  }
}
@media all and (min-width: 1600px) {
  div.categoriesContainer .swiper.landscape .swiper-button-prev {
    left: -70px;
  }
}
@media all and (min-width: 1768px) {
  div.categoriesContainer .swiper.landscape .swiper-button-prev {
    left: -79px;
    top: 5%;
  }
}
@media all and (min-width: 1980px) {
  div.categoriesContainer .swiper.landscape .swiper-button-prev {
    left: -91px;
  }
}
@media all and (min-width: 2200px) {
  div.categoriesContainer .swiper.landscape .swiper-button-prev {
    left: -99px;
  }
}
div.categoriesContainer .swiper.portrait .swiper-button-next {
  right: -82px;
  height: 100%;
  width: 6%;
  top: 5%;
  background: linear-gradient(-97deg, rgb(28, 31, 41) 0.98%, rgba(28, 31, 41, 0.4) 100.23%, rgba(28, 31, 41, 0.2) 100.16%, rgba(28, 31, 41, 0.0001) 52.12%);
}
@media all and (min-width: 320px) {
  div.categoriesContainer .swiper.portrait .swiper-button-next {
    display: none;
  }
}
@media all and (min-width: 980px) {
  div.categoriesContainer .swiper.portrait .swiper-button-next {
    display: flex;
    width: 4%;
    right: -47px;
    top: 6%;
  }
}
@media all and (min-width: 1200px) {
  div.categoriesContainer .swiper.portrait .swiper-button-next {
    right: -57px;
    width: 4% !important;
    top: 5%;
  }
}
@media all and (min-width: 1400px) {
  div.categoriesContainer .swiper.portrait .swiper-button-next {
    right: -67px;
  }
}
@media all and (min-width: 1600px) {
  div.categoriesContainer .swiper.portrait .swiper-button-next {
    right: -70px;
  }
}
@media all and (min-width: 1768px) {
  div.categoriesContainer .swiper.portrait .swiper-button-next {
    right: -79px;
    top: 4%;
  }
}
@media all and (min-width: 1980px) {
  div.categoriesContainer .swiper.portrait .swiper-button-next {
    right: -91px;
  }
}
@media all and (min-width: 2200px) {
  div.categoriesContainer .swiper.portrait .swiper-button-next {
    right: -99px;
  }
}
div.categoriesContainer .swiper.portrait .swiper-button-prev {
  left: -77px;
  height: 100%;
  width: 5%;
  top: 5%;
  background: linear-gradient(-97deg, rgb(28, 31, 41) 0.98%, rgba(28, 31, 41, 0.4) 100.23%, rgba(28, 31, 41, 0.2) 100.16%, rgba(28, 31, 41, 0.0001) 52.12%);
}
@media all and (min-width: 320px) {
  div.categoriesContainer .swiper.portrait .swiper-button-prev {
    display: none;
  }
}
@media all and (min-width: 980px) {
  div.categoriesContainer .swiper.portrait .swiper-button-prev {
    display: flex;
    width: 4%;
    left: -47px;
    top: 5%;
  }
}
@media all and (min-width: 1200px) {
  div.categoriesContainer .swiper.portrait .swiper-button-prev {
    left: -57px;
    width: 4% !important;
    top: 5%;
  }
}
@media all and (min-width: 1400px) {
  div.categoriesContainer .swiper.portrait .swiper-button-prev {
    left: -67px;
  }
}
@media all and (min-width: 1600px) {
  div.categoriesContainer .swiper.portrait .swiper-button-prev {
    left: -70px;
  }
}
@media all and (min-width: 1768px) {
  div.categoriesContainer .swiper.portrait .swiper-button-prev {
    left: -79px;
    top: 4%;
  }
}
@media all and (min-width: 1980px) {
  div.categoriesContainer .swiper.portrait .swiper-button-prev {
    left: -91px;
  }
}
@media all and (min-width: 2200px) {
  div.categoriesContainer .swiper.portrait .swiper-button-prev {
    left: -99px;
  }
}
div.categoriesContainer div.categoryNameContainer {
  display: inline;
  cursor: pointer;
  width: 100%;
}
div.categoriesContainer div.categoryNameContainer:hover h1 {
  color: var(--text-hover);
}
div.categoriesContainer div.categoryNameContainer:hover svg {
  color: var(--text-hover);
}
div.categoriesContainer div.categoryNameContainer h1 {
  font-size: 16px;
  margin-right: 20px;
  color: var(--text-color);
  transition: 0.3s ease-in-out;
  display: inline-block;
}
div.categoriesContainer div.categoryNameContainer svg {
  color: var(--text-color);
  height: 20px !important;
  width: 20px !important;
  transition: 0.3s ease-in-out;
  vertical-align: sub;
}
div.categoriesContainer div.itemsContainer {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 3px solid var(--border-color);
}
div.categoriesContainer div.itemsContainer div.items.rowCount3 {
  width: 32.5%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.categoriesContainer div.itemsContainer div.items.rowCount3:nth-child(3n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.categoriesContainer div.itemsContainer div.items.rowCount3 {
    width: 32%;
  }
}
div.categoriesContainer div.itemsContainer div.items.rowCount4 {
  width: 24%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.categoriesContainer div.itemsContainer div.items.rowCount4:nth-child(4n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.categoriesContainer div.itemsContainer div.items.rowCount4 {
    width: 23.8%;
  }
}
div.categoriesContainer div.itemsContainer div.items.rowCount5 {
  width: 19%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.categoriesContainer div.itemsContainer div.items.rowCount5:nth-child(5n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.categoriesContainer div.itemsContainer div.items.rowCount5 {
    width: 18.5%;
  }
}
div.categoriesContainer div.itemsContainer div.items.rowCount6 {
  width: 15.8%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.categoriesContainer div.itemsContainer div.items.rowCount6:nth-child(6n) {
  margin-right: 0;
}
@media all and (max-width: 1400px) {
  div.categoriesContainer div.itemsContainer div.items.rowCount6 {
    width: 15.5%;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer div.itemsContainer div.items.rowCount6 {
    width: 15%;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer div.itemsContainer div.items.portrait {
    width: 23.5%;
    margin-right: 13px;
    margin-bottom: 40px;
  }
  div.categoriesContainer div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.categoriesContainer div.itemsContainer div.items.portrait:nth-child(5n) {
    margin-right: 13px;
  }
}
@media all and (max-width: 768px) {
  div.categoriesContainer div.itemsContainer div.items.portrait {
    width: 23%;
  }
  div.categoriesContainer div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.categoriesContainer div.itemsContainer div.items.portrait:nth-child(5n) {
    margin-right: 13px;
  }
}
@media all and (max-width: 640px) {
  div.categoriesContainer div.itemsContainer div.items.portrait {
    width: 31%;
  }
  div.categoriesContainer div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-right: 13px;
  }
  div.categoriesContainer div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.categoriesContainer div.itemsContainer div.items.portrait {
    width: 47%;
  }
  div.categoriesContainer div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-right: 13px;
  }
  div.categoriesContainer div.itemsContainer div.items.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer div.itemsContainer div.items.landscape {
    width: 32%;
    margin-right: 13px;
    margin-bottom: 40px;
  }
  div.categoriesContainer div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.categoriesContainer div.itemsContainer div.items.landscape {
    width: 47.6%;
  }
  div.categoriesContainer div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-right: 0;
  }
  div.categoriesContainer div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 13px;
  }
}
@media all and (max-width: 640px) {
  div.categoriesContainer div.itemsContainer div.items.landscape {
    width: 48%;
  }
  div.categoriesContainer div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 13px;
  }
  div.categoriesContainer div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.categoriesContainer div.itemsContainer div.items.landscape {
    width: 47%;
  }
  div.categoriesContainer div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 13px;
  }
  div.categoriesContainer div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
div.categoriesContainer div.itemsContainer div.magazine.rowCount3 {
  width: 32.5%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.categoriesContainer div.itemsContainer div.magazine.rowCount3:nth-child(3n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.categoriesContainer div.itemsContainer div.magazine.rowCount3 {
    width: 32%;
  }
}
div.categoriesContainer div.itemsContainer div.magazine.rowCount4 {
  width: 20%;
  margin-right: 60px;
  margin-bottom: 30px;
}
div.categoriesContainer div.itemsContainer div.magazine.rowCount4:nth-child(4n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.categoriesContainer div.itemsContainer div.magazine.rowCount4 {
    width: 20%;
  }
}
div.categoriesContainer div.itemsContainer div.magazine.rowCount5 {
  width: 19%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.categoriesContainer div.itemsContainer div.magazine.rowCount5:nth-child(5n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.categoriesContainer div.itemsContainer div.magazine.rowCount5 {
    width: 18.5%;
  }
}
div.categoriesContainer div.itemsContainer div.magazine.rowCount6 {
  width: 15.8%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.categoriesContainer div.itemsContainer div.magazine.rowCount6:nth-child(6n) {
  margin-right: 0;
}
@media all and (max-width: 1400px) {
  div.categoriesContainer div.itemsContainer div.magazine.rowCount6 {
    width: 15.5%;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer div.itemsContainer div.magazine.rowCount6 {
    width: 15%;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer div.itemsContainer div.magazine.portrait {
    width: 23.5%;
    margin-right: 13px;
    margin-bottom: 40px;
  }
  div.categoriesContainer div.itemsContainer div.magazine.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.categoriesContainer div.itemsContainer div.magazine.portrait:nth-child(5n) {
    margin-right: 13px;
  }
}
@media all and (max-width: 768px) {
  div.categoriesContainer div.itemsContainer div.magazine.portrait {
    width: 23%;
  }
  div.categoriesContainer div.itemsContainer div.magazine.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.categoriesContainer div.itemsContainer div.magazine.portrait:nth-child(5n) {
    margin-right: 13px;
  }
}
@media all and (max-width: 640px) {
  div.categoriesContainer div.itemsContainer div.magazine.portrait {
    width: 31%;
  }
  div.categoriesContainer div.itemsContainer div.magazine.portrait:nth-child(4n) {
    margin-right: 13px;
  }
  div.categoriesContainer div.itemsContainer div.magazine.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.categoriesContainer div.itemsContainer div.magazine.portrait {
    width: 47%;
  }
  div.categoriesContainer div.itemsContainer div.magazine.portrait:nth-child(3n) {
    margin-right: 13px;
  }
  div.categoriesContainer div.itemsContainer div.magazine.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer div.itemsContainer div.magazine.landscape {
    width: 32%;
    margin-right: 13px;
    margin-bottom: 40px;
  }
  div.categoriesContainer div.itemsContainer div.magazine.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.categoriesContainer div.itemsContainer div.magazine.landscape {
    width: 47.6%;
  }
  div.categoriesContainer div.itemsContainer div.magazine.landscape:nth-child(2n) {
    margin-right: 0;
  }
  div.categoriesContainer div.itemsContainer div.magazine.landscape:nth-child(3n) {
    margin-right: 13px;
  }
}
@media all and (max-width: 640px) {
  div.categoriesContainer div.itemsContainer div.magazine.landscape {
    width: 48%;
  }
  div.categoriesContainer div.itemsContainer div.magazine.landscape:nth-child(3n) {
    margin-right: 13px;
  }
  div.categoriesContainer div.itemsContainer div.magazine.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.categoriesContainer div.itemsContainer div.magazine.landscape {
    width: 47%;
  }
  div.categoriesContainer div.itemsContainer div.magazine.landscape:nth-child(3n) {
    margin-right: 13px;
  }
  div.categoriesContainer div.itemsContainer div.magazine.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
div.categoriesContainer div.itemsContainer div.showMoreContainer, div.categoriesContainer div.itemsContainer div.showLessContainer {
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  transition: 0.4s ease-in-out;
}
div.categoriesContainer div.itemsContainer div.showMoreContainer:hover, div.categoriesContainer div.itemsContainer div.showLessContainer:hover {
  background-color: var(--button-hover);
}

div.showCardContainer {
  width: 100%;
  position: relative;
}
div.showCardContainer div.showsSubscriptions {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
@media all and (max-width: 1200px) {
  div.showCardContainer div.showsSubscriptions {
    display: none;
  }
}
div.showCardContainer div.showsSubscriptions div.items {
  width: 5rem;
  margin-right: 10px;
  background-color: var(--yellow-color);
  padding: 4px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  color: var(--text-color);
  margin-bottom: 10px;
  border-radius: 30px;
}
div.showCardContainer div.showsSubscriptions div.items.free {
  background-color: var(--success-color);
}
@media all and (max-width: 1200px) {
  div.showCardContainer div.showsSubscriptions div.items {
    font-size: 8px;
    width: 4rem;
  }
}
div.showCardContainer.showContents {
  cursor: pointer;
}
div.showCardContainer div.premiumStatus div.premium {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 8;
  background-color: rgba(8, 25, 43, 0.65);
  width: 35px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
@media all and (max-width: 1200px) {
  div.showCardContainer div.premiumStatus div.premium {
    width: 26px;
  }
}
div.showCardContainer div.premiumStatus div.premium svg {
  width: 18px !important;
  height: 18px !important;
}
@media all and (max-width: 1200px) {
  div.showCardContainer div.premiumStatus div.premium svg {
    width: 12px !important;
    heigth: 12px !important;
  }
}
div.showCardContainer div.premiumStatus div.free {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 8;
  width: 50px;
}
@media all and (max-width: 1200px) {
  div.showCardContainer div.premiumStatus div.free {
    width: 30px;
  }
}
div.showCardContainer div.premiumStatus div.free img {
  display: block;
  width: 100%;
}
div.showCardContainer div.premiumStatus div.lock {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 8;
  width: 20px;
}
@media all and (max-width: 1200px) {
  div.showCardContainer div.premiumStatus div.lock {
    width: 15px;
  }
}
div.showCardContainer div.premiumStatus div.lock img {
  display: block;
  width: 100%;
}
div.showCardContainer div.videoCard {
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
div.showCardContainer div.videoCard div.imageContainer {
  width: 100%;
}
div.showCardContainer div.videoCard div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 10px;
}
@media all and (max-width: 640px) {
  div.showCardContainer div.videoCard div.imageContainer img {
    border-radius: 0px;
  }
}
@media all and (max-width: 480px) {
  div.showCardContainer div.videoCard div.imageContainer img {
    border-radius: 0px;
  }
}
@media all and (max-width: 360px) {
  div.showCardContainer div.videoCard div.imageContainer img {
    border-radius: 0px;
  }
}
div.showCardContainer div.videoCard div.videoCard__info {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-left: 5px;
}
@media all and (max-width: 480px) {
  div.showCardContainer div.videoCard div.videoCard__info {
    margin-left: 0px;
  }
}
@media all and (max-width: 360px) {
  div.showCardContainer div.videoCard div.videoCard__info {
    margin-left: 0px;
    margin-top: 5px;
  }
}
div.showCardContainer div.videoCard div.videoCard__info .videoCard__avatar {
  height: 40px;
  width: 40px;
}
@media all and (max-width: 980px) {
  div.showCardContainer div.videoCard div.videoCard__info .videoCard__avatar {
    height: 30px;
    width: 30px;
  }
}
@media all and (max-width: 480px) {
  div.showCardContainer div.videoCard div.videoCard__info .videoCard__avatar {
    height: 28px;
    width: 28px;
  }
}
@media all and (max-width: 360px) {
  div.showCardContainer div.videoCard div.videoCard__info .videoCard__avatar {
    height: 25px;
    width: 25px;
  }
}
div.showCardContainer div.videoCard div.videoCard__info div.videoCard__text {
  margin-left: 15px;
}
@media all and (max-width: 980px) {
  div.showCardContainer div.videoCard div.videoCard__info div.videoCard__text {
    margin-left: 5px;
  }
}
@media all and (max-width: 360px) {
  div.showCardContainer div.videoCard div.videoCard__info div.videoCard__text {
    margin-left: 2px;
  }
}
div.showCardContainer div.videoCard div.videoCard__info div.videoCard__text h4 {
  font-size: 15px;
  color: var(--text-color);
}
@media all and (max-width: 980px) {
  div.showCardContainer div.videoCard div.videoCard__info div.videoCard__text h4 {
    font-size: 11px;
  }
}
@media all and (max-width: 640px) {
  div.showCardContainer div.videoCard div.videoCard__info div.videoCard__text h4 {
    font-size: 10px;
  }
}
@media all and (max-width: 480px) {
  div.showCardContainer div.videoCard div.videoCard__info div.videoCard__text h4 {
    font-size: 9px;
  }
}
@media all and (max-width: 360px) {
  div.showCardContainer div.videoCard div.videoCard__info div.videoCard__text h4 {
    font-size: 8px;
  }
}
div.showCardContainer div.videoCard div.videoCard__info div.videoCard__text p {
  font-size: 13px;
  color: var(--grey-color);
}
@media all and (max-width: 980px) {
  div.showCardContainer div.videoCard div.videoCard__info div.videoCard__text p {
    font-size: 11px;
  }
}
@media all and (max-width: 480px) {
  div.showCardContainer div.videoCard div.videoCard__info div.videoCard__text p {
    font-size: 9px;
  }
}
@media all and (max-width: 360px) {
  div.showCardContainer div.videoCard div.videoCard__info div.videoCard__text p {
    font-size: 8px;
  }
}

div.footerContainer {
  padding: 30px 0;
  position: relative;
  z-index: 8;
  background-color: var(--bg-color);
  border-top: 1px solid var(--border-color);
}
div.footerContainer div.topContainer {
  display: flex;
  justify-content: space-between;
}
div.footerContainer div.topContainer div.left {
  width: 20%;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 20px;
}
div.footerContainer div.topContainer div.left div.logoContainer {
  width: 100px;
}
div.footerContainer div.topContainer div.left div.logoContainer img {
  display: block;
  width: 100%;
}
div.footerContainer div.topContainer div.right {
  width: 20%;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 20px;
}
@media all and (max-width: 640px) {
  div.footerContainer div.topContainer div.right {
    width: 35%;
  }
}
div.footerContainer div.topContainer div.right ul.social {
  display: flex;
  align-items: center;
}
div.footerContainer div.topContainer div.right ul.social li {
  margin-right: 10px;
  cursor: pointer;
}
div.footerContainer div.topContainer div.right ul.social li:last-child {
  margin-right: 0;
}
div.footerContainer div.topContainer div.right ul.social li:hover svg {
  opacity: 0.6;
  color: var(--text-hover);
}
div.footerContainer div.topContainer div.right ul.social li svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.footerContainer div.centerContainer div.informationContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 0;
}
div.footerContainer div.centerContainer div.informationContainer div.items {
  width: 20%;
  color: var(--text-color);
}
@media all and (max-width: 768px) {
  div.footerContainer div.centerContainer div.informationContainer div.items {
    width: 25%;
    margin-bottom: 20px;
  }
}
@media all and (max-width: 640px) {
  div.footerContainer div.centerContainer div.informationContainer div.items {
    width: 33%;
  }
}
div.footerContainer div.centerContainer div.informationContainer div.items h6.heading {
  font-size: 12px;
  color: var(--inner-text-color);
  margin-bottom: 10px;
}
div.footerContainer div.centerContainer div.informationContainer div.items ul li span {
  font-size: 12px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.footerContainer div.centerContainer div.informationContainer div.items ul li span:hover {
  opacity: 0.6;
  color: var(--text-hover);
}
div.footerContainer div.bottomContainer {
  border-top: 1px solid var(--border-color);
  padding: 40px 0;
}
div.footerContainer div.bottomContainer div.stores {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
div.footerContainer div.bottomContainer div.stores svg {
  margin-right: 10px;
  width: 120px !important;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.footerContainer div.bottomContainer div.stores svg:last-child {
  margin-right: 0;
}
div.footerContainer div.bottomContainer div.stores svg:hover {
  opacity: 0.8;
}
div.footerContainer div.bottomContainer p {
  text-align: center;
  font-size: 12px;
  color: var(--grey-color);
  opacity: 0.7;
}
div.footerContainer div.bottomContainer div.typeOfPayments {
  color: var(--grey-color) !important;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
div.footerContainer div.bottomContainer div.typeOfPayments i {
  margin-right: 6px;
}
div.footerContainer div.bottomContainer div.typeOfPayments i:last-child {
  margin-right: 0;
}

div.detailsScreen {
  scroll-behavior: smooth;
  padding: 20px 0 0;
  position: relative;
}
@media all and (max-width: 2600px) {
  div.detailsScreen {
    padding: 246px 0 0;
  }
}
@media all and (max-width: 2500px) {
  div.detailsScreen {
    padding: 180px 0 0;
  }
}
@media all and (max-width: 1768px) {
  div.detailsScreen {
    padding: 140px 0 0;
  }
}
@media all and (max-width: 1600px) {
  div.detailsScreen {
    padding: 145px 0 0;
  }
}
div.detailsScreen .swiper-button-next {
  right: -9px !important;
  top: 45%;
}
@media all and (min-width: 1200px) {
  div.detailsScreen .swiper-button-next {
    right: 0 !important;
  }
}
div.detailsScreen .swiper-button-prev {
  left: -9px !important;
  top: 45%;
}
@media all and (min-width: 1200px) {
  div.detailsScreen .swiper-button-prev {
    left: 0 !important;
  }
}
div.detailsScreen div.wrapper {
  background-color: var(--bg-color);
  position: relative;
  z-index: 8;
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.wrapper {
    flex-direction: column;
  }
}
div.detailsScreen div.wrapper div.leftContainer {
  width: 73%;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.wrapper div.leftContainer {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.detailsScreen div.wrapper div.leftContainer div.playerContainer {
  width: 90%;
  margin: 0 auto;
  background-color: var(--black-color);
  position: relative;
}
div.detailsScreen div.wrapper div.leftContainer div.playerContainer div.videoContainer {
  background-color: #000;
  width: 100%;
}
div.detailsScreen div.wrapper div.leftContainer div.playerContainer div.videoContainer.heightIncrease {
  height: 1000px;
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.wrapper div.leftContainer div.playerContainer div.videoContainer.heightIncrease {
    height: 25em;
  }
}
div.detailsScreen div.wrapper div.leftContainer div.playerContainer div.videoContainer div.gradientTop {
  position: absolute;
  top: 0;
  z-index: 8;
  width: 100%;
  height: 20%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(0deg, rgba(16, 20, 31, 0), rgba(16, 20, 31, 0.2) 19%, rgba(16, 20, 31, 0.4) 39%, rgba(16, 20, 31, 0.8));
}
div.detailsScreen div.wrapper div.leftContainer div.playerContainer div.videoContainer div.player {
  position: relative;
  z-index: 9;
  width: 100%;
  display: block;
}
div.detailsScreen div.wrapper div.leftContainer div.playerContainer div.videoContainer div.player div.closeIcon {
  display: none;
}
div.detailsScreen div.wrapper div.leftContainer div.playerContainer div.videoContainer div.player div.nowPlaying {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 7;
}
div.detailsScreen div.wrapper div.leftContainer div.playerContainer div.videoContainer div.player div.nowPlaying h1 {
  color: var(--text-color);
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.wrapper div.leftContainer div.playerContainer div.videoContainer div.player div.nowPlaying h1 {
    font-size: 23px;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.wrapper div.leftContainer div.playerContainer div.videoContainer div.player div.nowPlaying h1 {
    font-size: 20px;
  }
}
@media all and (max-width: 768px) {
  div.detailsScreen div.wrapper div.leftContainer div.playerContainer div.videoContainer div.player div.nowPlaying h1 {
    font-size: 18px;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreen div.wrapper div.leftContainer div.playerContainer div.videoContainer div.player div.nowPlaying h1 {
    font-size: 16px;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreen div.wrapper div.leftContainer div.playerContainer div.videoContainer div.player div.nowPlaying h1 {
    font-size: 14px;
  }
}
div.detailsScreen div.wrapper div.leftContainer div.playerContainer div.videoContainer div.player.pipMode {
  position: fixed;
  top: 110px;
  width: 22%;
  right: 0;
  z-index: 999999999;
  height: 200px;
}
@media all and (max-width: 768px) {
  div.detailsScreen div.wrapper div.leftContainer div.playerContainer div.videoContainer div.player.pipMode {
    width: 40%;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreen div.wrapper div.leftContainer div.playerContainer div.videoContainer div.player.pipMode {
    width: 50%;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreen div.wrapper div.leftContainer div.playerContainer div.videoContainer div.player.pipMode {
    width: 68%;
  }
}
div.detailsScreen div.wrapper div.leftContainer div.playerContainer div.videoContainer div.player.pipMode video {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
}
div.detailsScreen div.wrapper div.leftContainer div.playerContainer div.videoContainer div.player.pipMode .video-js {
  padding: 0;
  height: 100%;
}
div.detailsScreen div.wrapper div.leftContainer div.playerContainer div.videoContainer div.player.pipMode div.gradientTop {
  display: none;
}
div.detailsScreen div.wrapper div.leftContainer div.playerContainer div.videoContainer div.player.pipMode div.nowPlaying {
  display: none;
}
div.detailsScreen div.wrapper div.leftContainer div.playerContainer div.videoContainer div.player.pipMode:hover div.closeIcon {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 9;
  background-color: var(--bg-color);
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
div.detailsScreen div.wrapper div.leftContainer div.playerContainer div.videoContainer div.player.pipMode:hover div.closeIcon svg {
  color: var(--text-color);
}
div.detailsScreen div.wrapper div.leftContainer div.playerContainer div.videoContainer div.player.pipMode:hover div.gradientTop {
  display: block;
}
div.detailsScreen div.wrapper div.leftContainer div.playerContainer div.videoContainer video {
  display: block;
  width: 100%;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer {
  margin-top: 15px;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata {
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 40px;
  width: 100%;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata h1.name {
  font-size: 30px;
  font-weight: 700;
  color: var(--text-color);
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions {
  display: flex;
  margin: 10px 0;
}
@media all and (max-width: 640px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions {
    flex-direction: column;
  }
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions button {
  width: 10rem;
  display: block;
  background: var(--secondary-btn-color);
  padding: 8px 10px;
  font-size: 14px;
  color: var(--text-color);
  font-weight: 600;
  border-radius: 30px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions button:hover {
  background: var(--button-hover);
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.left {
  width: 30%;
}
@media all and (max-width: 640px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.left {
    width: 100%;
    margin-bottom: 20px;
  }
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.left div.playButtonContainer button.playButton {
    width: 90% !important;
    margin: 0 auto;
  }
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.left div.playButtonContainer button.playButton {
  width: unset;
  padding: 8px 12px;
  background: var(--button-color);
}
@media all and (max-width: 360px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.left div.playButtonContainer button.playButton {
    width: 100% !important;
  }
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right {
  width: 70%;
  display: flex;
  justify-content: flex-end;
}
@media all and (max-width: 640px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right {
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
  }
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.addRemoveList button {
  width: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media all and (max-width: 640px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.addRemoveList button {
    width: 14rem;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.addRemoveList button {
    width: 17rem;
    margin-bottom: 10px;
  }
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.addRemoveList button div.icon {
  margin-left: 6px;
  line-height: 0;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton {
  position: relative;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton button.share {
  width: 6rem;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media all and (max-width: 480px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton button.share {
    width: 14rem;
    margin-left: 0;
  }
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton button.share div.icon {
  margin-left: 6px;
  line-height: 0;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer {
  position: absolute;
  bottom: -126px;
  left: -50px;
  z-index: 9;
  background-color: #1c1f29;
  border: 1px solid var(--border-color);
  width: 232px;
  border-radius: 8px;
  display: none;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer button {
  border-radius: 0%;
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer {
    width: 200px;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer {
    width: 232px;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media all and (max-width: 480px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer {
    width: 100%;
  }
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer.active {
  display: block;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer::after {
  content: "";
  top: -9px;
  width: 0;
  height: 0;
  border-color: transparent transparent rgba(240, 241, 245, 0.16);
  border-style: solid;
  border-width: 0 8px 8px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer::after {
    left: 40%;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer::after {
    left: 50%;
  }
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer div.facebookContainer button.facebook {
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px !important;
  color: var(--text-color) !important;
  cursor: pointer !important;
  border-bottom: 1px solid var(--border-color) !important;
  width: 100% !important;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer div.facebookContainer button.facebook div.icon {
  width: 10%;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer div.facebookContainer button.facebook div.icon svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer div.facebookContainer button.facebook div.icon svg:hover {
  color: var(--text-color);
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer div.facebookContainer div.left {
  width: 10%;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer div.facebookContainer div.right svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer div.facebookContainer div.right svg:hover {
  color: var(--text-color);
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer div.twitterContainer button.twitter {
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px !important;
  color: var(--text-color) !important;
  cursor: pointer !important;
  width: 100% !important;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer div.twitterContainer button.twitter div.icon {
  width: 10%;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer div.twitterContainer button.twitter div.icon svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer div.twitterContainer button.twitter div.icon svg:hover {
  color: var(--text-color);
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer div.twitterContainer div.left {
  width: 10%;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer div.twitterContainer div.right svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer div.twitterContainer div.right svg:hover {
  color: var(--text-color);
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.more {
  margin-left: 10px;
  position: relative;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.more button.more {
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.more button.more svg {
  width: 20px !important;
  height: 20px !important;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.more button.more:hover {
  background-color: rgba(203, 203, 208, 0.16);
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.more div.reportProblemContainer {
  position: absolute;
  bottom: -66px;
  right: -17px;
  z-index: 9;
  background-color: #1c1f29;
  border: 1px solid var(--border-color);
  width: 200px;
  border-radius: 8px;
  padding: 15px;
  display: none;
  cursor: pointer;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.more div.reportProblemContainer.active {
  display: block;
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.more div.reportProblemContainer {
    width: 164px;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.more div.reportProblemContainer {
    width: 200px;
  }
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.more div.reportProblemContainer span {
  color: var(--text-color);
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.more div.reportProblemContainer::after {
  content: "";
  top: -9px;
  width: 0;
  height: 0;
  border-color: transparent transparent rgba(240, 241, 245, 0.16);
  border-style: solid;
  border-width: 0 8px 8px;
  position: absolute;
  right: 8%;
  transform: translateX(-50%);
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.more div.reportProblemContainer::after {
    right: 5%;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.more div.reportProblemContainer::after {
    right: 8%;
  }
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.data {
  display: flex;
  margin-bottom: 6px;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.data span {
  font-size: 14px;
  color: var(--inner-text-color);
}
@media all and (max-width: 640px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.data span {
    font-size: 14px;
  }
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.data span.year {
  margin-right: 6px;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.data span.duration {
  margin-right: 8px;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.data span.duration.showDot::before {
  content: "·";
  margin-right: 6px;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.data span.certificate {
  display: block;
  padding: 4px 6px;
  background-color: rgba(150, 153, 163, 0.32);
  font-size: 11px;
  font-weight: 700;
  color: var(--text-color);
  border-radius: 4px;
}
@media all and (max-width: 640px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.data span.certificate {
    font-size: 10px;
  }
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.genres {
  margin-bottom: 10px;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.genres span {
  font-size: 14px;
  color: var(--inner-text-color);
  margin-right: 6px;
}
@media all and (max-width: 640px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.genres span {
    font-size: 14px;
  }
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.genres span::before {
  content: "·";
  margin-right: 6px;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.genres span:first-child::before {
  display: none;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.genres span:last-child {
  margin-right: 0;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.watchNow {
  display: none;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.watchNow {
    display: block;
  }
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.addtoList {
  display: none;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.addtoList {
    display: block;
  }
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.buttons {
  display: none;
  margin-bottom: 20px;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.buttons {
    display: flex;
  }
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata p.description {
  font-size: 14px;
  color: var(--text-color);
  line-height: 1.4em;
  letter-spacing: 0.3px;
  max-width: 100%;
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata p.description {
    max-width: 100%;
  }
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info {
  margin-top: 20px;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring div.left {
  width: 15%;
}
@media all and (max-width: 768px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring div.left {
    width: 20%;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring div.left {
    width: 25%;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring div.left {
    width: 30%;
  }
}
@media all and (max-width: 360px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring div.left {
    width: 33%;
  }
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring div.left h6 {
  font-size: 14px;
  font-weight: 400;
  color: var(--inner-text-color);
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring div.right {
  width: 85%;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring div.right {
    width: 75%;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring div.right {
    width: 70%;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring div.right {
    width: 65%;
  }
}
@media all and (max-width: 360px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring div.right {
    width: 55%;
  }
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring div.right ul.names {
  display: flex;
  flex-wrap: wrap;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring div.right ul.names li {
  font-size: 14px;
  font-weight: 600;
  margin-right: 8px;
  color: var(--text-color);
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring div.right ul.names li:last-child {
  margin-right: 0;
}
@media all and (max-width: 480px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring div.right ul.names li {
    font-size: 14px;
  }
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.director {
  display: flex;
  justify-content: space-between;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.director div.left {
  width: 15%;
}
@media all and (max-width: 768px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.director div.left {
    width: 20%;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.director div.left {
    width: 25%;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.director div.left {
    width: 30%;
  }
}
@media all and (max-width: 360px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.director div.left {
    width: 33%;
  }
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.director div.left h6 {
  font-size: 14px;
  font-weight: 400;
  color: var(--inner-text-color);
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.director div.right {
  width: 85%;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.director div.right {
    width: 75%;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.director div.right {
    width: 70%;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.director div.right {
    width: 65%;
  }
}
@media all and (max-width: 360px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.director div.right {
    width: 55%;
  }
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.director div.right h6 {
  font-size: 14px;
  font-weight: 600;
  margin-right: 8px;
  color: var(--text-color);
}
@media all and (max-width: 480px) {
  div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.director div.right h6 {
    font-size: 14px;
  }
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.subscriptions {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.subscriptions div.items {
  cursor: pointer;
  margin-right: 10px;
  background-color: var(--yellow-color);
  padding: 6px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  color: var(--text-color);
  margin-bottom: 20px;
  border-radius: 30px;
}
div.detailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.subscriptions div.items.free {
  background-color: var(--success-color);
}
div.detailsScreen div.wrapper div.rightContainer {
  width: 25%;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.wrapper div.rightContainer {
    width: 100%;
  }
}
div.detailsScreen div.wrapper div.rightContainer div.relatedVideos div.releated_Video__Container h1.relatedHeading {
  font-size: 18px;
  color: var(--text-color);
}
@media all and (max-width: 980px) {
  div.detailsScreen div.wrapper div.rightContainer div.relatedVideos div.releated_Video__Container div.recommendedVideos {
    display: flex;
    flex-wrap: wrap;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.wrapper div.rightContainer div.relatedVideos div.releated_Video__Container div.recommendedVideos div.items {
    width: 48%;
    margin-right: 10px;
  }
  div.detailsScreen div.wrapper div.rightContainer div.relatedVideos div.releated_Video__Container div.recommendedVideos div.items:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.detailsScreen div.wrapper div.rightContainer div.relatedVideos div.releated_Video__Container div.recommendedVideos div.items {
    width: 100%;
    margin-right: 0;
  }
  div.detailsScreen div.wrapper div.rightContainer div.relatedVideos div.releated_Video__Container div.recommendedVideos div.items:nth-child(2n) {
    margin-right: 0;
  }
}
div.detailsScreen div.details {
  position: relative;
  z-index: 8;
  display: flex;
  padding: 30px 0 130px;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.details {
    flex-direction: column;
  }
}
div.detailsScreen div.details div.watchNow {
  width: 100%;
  margin-top: 15px;
}
div.detailsScreen div.details div.watchNow button {
  width: 100%;
  display: block;
  background: var(--button-color);
  padding: 15px 10px;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.detailsScreen div.details div.watchNow button:hover {
  background: var(--button-hover);
}
div.detailsScreen div.details div.addtoList {
  width: 100%;
  margin-top: 15px;
}
div.detailsScreen div.details div.addtoList button {
  width: 100%;
  display: block;
  background-color: rgba(150, 153, 163, 0.16);
  padding: 15px 10px;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.detailsScreen div.details div.addtoList button:hover {
  background-color: rgba(203, 203, 208, 0.16);
}
div.detailsScreen div.details div.buttons {
  margin-top: 15px;
  display: flex;
  width: 100%;
}
div.detailsScreen div.details div.buttons div.buttonContainerOne {
  width: 70%;
  position: relative;
  margin-right: 10px;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.details div.buttons div.buttonContainerOne {
    width: 90%;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreen div.details div.buttons div.buttonContainerOne {
    width: 80%;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreen div.details div.buttons div.buttonContainerOne {
    width: 75%;
  }
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer {
  position: absolute;
  bottom: -126px;
  left: -20px;
  z-index: 9;
  background-color: #1c1f29;
  border: 1px solid var(--border-color);
  width: 232px;
  border-radius: 8px;
  display: none;
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer {
    width: 200px;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer {
    width: 232px;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media all and (max-width: 480px) {
  div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer {
    width: 100%;
  }
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer.active {
  display: block;
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer::after {
  content: "";
  top: -9px;
  width: 0;
  height: 0;
  border-color: transparent transparent rgba(240, 241, 245, 0.16);
  border-style: solid;
  border-width: 0 8px 8px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer::after {
    left: 40%;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer::after {
    left: 50%;
  }
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer button.facebook {
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px !important;
  color: var(--text-color) !important;
  cursor: pointer !important;
  border-bottom: 1px solid var(--border-color) !important;
  width: 100% !important;
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer button.facebook div.icon {
  width: 10%;
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer button.facebook div.icon svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer button.facebook div.icon svg:hover {
  color: var(--text-color);
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer div.left {
  width: 10%;
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer div.right svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer div.right svg:hover {
  color: var(--text-color);
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer button.twitter {
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px !important;
  color: var(--text-color) !important;
  cursor: pointer !important;
  width: 100% !important;
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer button.twitter div.icon {
  width: 10%;
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer button.twitter div.icon svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer button.twitter div.icon svg:hover {
  color: var(--text-color);
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer div.left {
  width: 10%;
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer div.right svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer div.right svg:hover {
  color: var(--text-color);
}
div.detailsScreen div.details div.buttons div.buttonContainerOne button.share {
  display: block;
  background-color: rgba(150, 153, 163, 0.16);
  padding: 15px 10px;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.detailsScreen div.details div.buttons div.buttonContainerOne button.share:hover {
  background-color: rgba(203, 203, 208, 0.16);
}
div.detailsScreen div.details div.buttons div.buttonContainerTwo {
  width: 30%;
  position: relative;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.details div.buttons div.buttonContainerTwo {
    width: 10%;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreen div.details div.buttons div.buttonContainerTwo {
    width: 18%;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreen div.details div.buttons div.buttonContainerTwo {
    width: 25%;
  }
}
div.detailsScreen div.details div.leftContainer {
  width: 27%;
  margin-right: 40px;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.details div.leftContainer {
    display: none;
  }
}
div.detailsScreen div.details div.leftContainer div.showCardDetails {
  width: 100%;
  position: sticky;
  top: 0;
}
div.detailsScreen div.details div.leftContainer div.showCardDetails div.imageContainer {
  width: 100%;
}
div.detailsScreen div.details div.leftContainer div.showCardDetails div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 5px;
}
div.detailsScreen div.details div.rightContainer {
  width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
div.detailsScreen div.details div.rightContainer::-webkit-scrollbar {
  display: none;
}
div.loginPage {
  background: linear-gradient(354deg, rgb(20, 20, 20) 0%, rgb(20, 20, 20) 38%, rgb(129, 17, 23) 100%);
}
div.loginPage span.error {
  color: #E72A31;
  font-size: 12px;
  text-align: left;
  display: block;
  margin-bottom: 10px;
}
div.loginPage div.updatesContainer {
  text-align: center;
  padding-top: 100px;
  width: 50%;
  margin: 0 auto -90px;
}
@media all and (max-width: 1200px) {
  div.loginPage div.updatesContainer {
    width: 60%;
  }
}
@media all and (max-width: 640px) {
  div.loginPage div.updatesContainer {
    width: 90%;
  }
}
div.loginPage div.updatesContainer p {
  background-color: #fff;
  font-size: 14px;
  color: var(--error-color);
  padding: 10px;
  border-radius: 5px;
  font-weight: 600;
  border-bottom: 4px solid var(--error-color);
}
div.loginPage div.loginContainer {
  width: 20rem;
  margin: 0 auto;
  padding: 220px 0;
  text-align: center;
}
@media all and (max-width: 640px) {
  div.loginPage div.loginContainer {
    width: 95%;
  }
}
div.loginPage div.loginContainer div.top div.userIconContainer {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid var(--border-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 10px;
}
div.loginPage div.loginContainer div.top div.userIconContainer svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--text-color);
}
div.loginPage div.loginContainer div.top h1 {
  color: var(--text-color);
  margin-bottom: 20px;
}
@media all and (max-width: 640px) {
  div.loginPage div.loginContainer div.top h1 {
    font-size: 25px;
  }
}
div.loginPage div.loginContainer div.top div.facebookContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
}
div.loginPage div.loginContainer div.top div.facebookContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.loginPage div.loginContainer div.top div.facebookContainer div.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3d6ac3;
  z-index: 1;
  transition: 0.1s ease-in-out;
  border-radius: 8px;
}
div.loginPage div.loginContainer div.top div.facebookContainer div.contents {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}
div.loginPage div.loginContainer div.top div.facebookContainer div.left {
  line-height: 0;
  margin-right: 50px;
}
div.loginPage div.loginContainer div.top div.facebookContainer div.left svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--text-color);
  line-height: 0;
}
div.loginPage div.loginContainer div.top div.facebookContainer div.right span {
  color: var(--text-color);
  font-weight: 700;
  line-height: 0;
  font-size: 15px;
}
div.loginPage div.loginContainer div.top div.googleContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
}
div.loginPage div.loginContainer div.top div.googleContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.loginPage div.loginContainer div.top div.googleContainer div.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 1;
  transition: 0.1s ease-in-out;
  border-radius: 8px;
}
div.loginPage div.loginContainer div.top div.googleContainer div.contents {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}
div.loginPage div.loginContainer div.top div.googleContainer div.left {
  line-height: 0;
  margin-right: 50px;
}
div.loginPage div.loginContainer div.top div.googleContainer div.left svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--text-color);
  line-height: 0;
}
div.loginPage div.loginContainer div.top div.googleContainer div.right span {
  color: #3c4043;
  font-weight: 700;
  line-height: 0;
  font-size: 15px;
}
div.loginPage div.loginContainer div.top div.seperate {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
div.loginPage div.loginContainer div.top div.seperate span {
  font-size: 12px;
  color: var(--text-color);
}
div.loginPage div.loginContainer div.top div.seperate span.line {
  height: 0.1px;
  width: 45%;
  background-color: var(--inner-text-color);
}
div.loginPage div.loginContainer div.top div.seperate span.line:first-child {
  margin-right: 8px;
}
div.loginPage div.loginContainer div.top div.seperate span.line:last-child {
  margin-left: 8px;
}
div.loginPage div.loginContainer div.seperate {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
div.loginPage div.loginContainer div.seperate span {
  font-size: 12px;
  color: var(--text-color);
}
div.loginPage div.loginContainer div.seperate span.line {
  height: 0.1px;
  width: 45%;
  background-color: var(--inner-text-color);
}
div.loginPage div.loginContainer div.seperate span.line:first-child {
  margin-right: 8px;
}
div.loginPage div.loginContainer div.seperate span.line:last-child {
  margin-left: 8px;
}
div.loginPage div.loginContainer div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
div.loginPage div.loginContainer div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.loginPage div.loginContainer div.buttonContainer div.background {
  background: rgba(150, 153, 163, 0.16);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}
div.loginPage div.loginContainer div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
}
div.loginPage div.loginContainer div.bottom {
  margin-top: 15px;
}
div.loginPage div.loginContainer div.bottom h3 {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}
div.loginPage div.loginContainer div.bottom form {
  margin-bottom: 20px;
}
div.loginPage div.loginContainer div.bottom form div.emailContainer {
  background-color: var(--text-color);
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
}
div.loginPage div.loginContainer div.bottom form div.emailContainer input {
  background: transparent;
  outline: none;
  border: none;
  text-align: left;
  width: 100%;
  padding: 20px 15px;
  border-radius: 8px;
}
div.loginPage div.loginContainer div.bottom form div.emailContainer input::-moz-placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.loginPage div.loginContainer div.bottom form div.emailContainer input::placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.loginPage div.loginContainer div.bottom form div.passwordContainer {
  background-color: var(--text-color);
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
}
div.loginPage div.loginContainer div.bottom form div.passwordContainer input {
  background: transparent;
  outline: none;
  border: none;
  text-align: left;
  width: 100%;
  padding: 20px 15px;
  border-radius: 8px;
}
div.loginPage div.loginContainer div.bottom form div.passwordContainer input::-moz-placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.loginPage div.loginContainer div.bottom form div.passwordContainer input::placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.loginPage div.loginContainer div.bottom form div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
div.loginPage div.loginContainer div.bottom form div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.loginPage div.loginContainer div.bottom form div.buttonContainer div.background {
  background: rgba(150, 153, 163, 0.16);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}
div.loginPage div.loginContainer div.bottom form div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
}
div.loginPage div.loginContainer div.bottom form h4.forgotPassword {
  color: var(--text-color);
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 6px;
  cursor: pointer;
}
div.loginPage div.loginContainer div.bottom form h4 {
  font-size: 15px;
  color: #d5d5d6;
  font-weight: 400;
}
div.loginPage div.loginContainer div.bottom form h4 span {
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}
div.loginPage div.loginContainer div.bottom p {
  font-size: 11px;
  color: var(--inner-text-color);
  line-height: 1.6em;
}
div.loginPage div.loginContainer div.bottom p span {
  color: var(--text-color);
  cursor: pointer;
}

div.registerPage {
  background-image: url("./assets/Images/login_bg.svg");
  background-repeat: no-repeat;
}
div.registerPage span.error {
  color: #e72a31;
  font-size: 12px;
  text-align: left;
  display: block;
  margin-bottom: 10px;
}
div.registerPage div.registerContainer {
  width: 20rem;
  margin: 0 auto;
  padding: 130px 0 100px;
  text-align: center;
}
@media all and (max-width: 640px) {
  div.registerPage div.registerContainer {
    width: 95%;
  }
}
div.registerPage div.registerContainer div.top div.userIconContainer {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid var(--border-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 10px;
}
div.registerPage div.registerContainer div.top div.userIconContainer svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--text-color);
}
div.registerPage div.registerContainer div.top h1 {
  color: var(--text-color);
}
@media all and (max-width: 640px) {
  div.registerPage div.registerContainer div.top h1 {
    font-size: 25px;
  }
}
div.registerPage div.registerContainer div.top h6 {
  font-size: 15px;
  color: var(--inner-text-color);
  margin-bottom: 20px;
}
div.registerPage div.registerContainer div.top div.facebookContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
}
div.registerPage div.registerContainer div.top div.facebookContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.registerPage div.registerContainer div.top div.facebookContainer div.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3d6ac3;
  z-index: 1;
  transition: 0.1s ease-in-out;
  border-radius: 8px;
}
div.registerPage div.registerContainer div.top div.facebookContainer div.contents {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}
div.registerPage div.registerContainer div.top div.facebookContainer div.left {
  line-height: 0;
  margin-right: 50px;
}
div.registerPage div.registerContainer div.top div.facebookContainer div.left svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--text-color);
  line-height: 0;
}
div.registerPage div.registerContainer div.top div.facebookContainer div.right span {
  color: var(--text-color);
  font-weight: 700;
  line-height: 0;
  font-size: 15px;
}
div.registerPage div.registerContainer div.top div.googleContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
div.registerPage div.registerContainer div.top div.googleContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.registerPage div.registerContainer div.top div.googleContainer div.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 1;
  transition: 0.1s ease-in-out;
  border-radius: 8px;
}
div.registerPage div.registerContainer div.top div.googleContainer div.contents {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}
div.registerPage div.registerContainer div.top div.googleContainer div.left {
  line-height: 0;
  margin-right: 50px;
}
div.registerPage div.registerContainer div.top div.googleContainer div.left svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--text-color);
  line-height: 0;
}
div.registerPage div.registerContainer div.top div.googleContainer div.right span {
  color: #3c4043;
  font-weight: 700;
  line-height: 0;
  font-size: 15px;
}
div.registerPage div.registerContainer div.top div.seperate {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
div.registerPage div.registerContainer div.top div.seperate span {
  font-size: 12px;
  color: var(--text-color);
}
div.registerPage div.registerContainer div.top div.seperate span.line {
  height: 0.1px;
  width: 45%;
  background-color: var(--inner-text-color);
}
div.registerPage div.registerContainer div.top div.seperate span.line:first-child {
  margin-right: 8px;
}
div.registerPage div.registerContainer div.top div.seperate span.line:last-child {
  margin-left: 8px;
}
div.registerPage div.registerContainer div.bottom {
  margin-top: 15px;
}
div.registerPage div.registerContainer div.bottom h3 {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}
div.registerPage div.registerContainer div.bottom form {
  margin-bottom: 20px;
}
div.registerPage div.registerContainer div.bottom form div.oneByOne {
  background-color: var(--text-color);
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
}
div.registerPage div.registerContainer div.bottom form div.oneByOne input {
  background: transparent;
  outline: none;
  border: none;
  text-align: left;
  width: 100%;
  padding: 17px 15px;
  border-radius: 8px;
}
div.registerPage div.registerContainer div.bottom form div.oneByOne input::-moz-placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.registerPage div.registerContainer div.bottom form div.oneByOne input::placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo label {
  font-size: 12px;
  color: var(--inner-text-color);
  text-align: left;
  display: block;
  font-weight: 600;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers {
  display: flex;
  height: 100%;
  border-radius: 8px;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers input {
  outline: none;
  border: none;
  text-align: left;
  width: 100%;
  height: 100%;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers input::-moz-placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers input::placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers input::-webkit-outer-spin-button,
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers input[type=number] {
  -moz-appearance: textfield;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.left {
  margin-right: 10px;
  width: 70%;
  height: 100%;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.left input[type=date] {
  padding: 17px 15px;
  border-radius: 8px;
  margin-bottom: 10px;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.right {
  width: 30%;
  height: 100%;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.right div.genderContainer {
  background-color: var(--text-color);
  border-radius: 8px;
  position: relative;
  height: 100%;
  width: 100%;
  margin-bottom: 10px;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.right div.genderContainer svg {
  position: absolute;
  right: 5px;
  width: 15px !important;
  height: 15px !important;
  color: var(--inner-text-color);
  top: 50%;
  transform: translateY(-50%);
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.right div.genderContainer select {
  padding: 17px 15px;
  border-radius: 8px;
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.right div.genderContainer select option {
  padding: 17px 15px;
  background-color: var(--text-color);
  color: var(--black-color);
  border-bottom: 1px solid var(--border-color);
  position: relative;
}
div.registerPage div.registerContainer div.bottom form div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
div.registerPage div.registerContainer div.bottom form div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.registerPage div.registerContainer div.bottom form div.buttonContainer div.background {
  background: rgba(150, 153, 163, 0.16);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}
div.registerPage div.registerContainer div.bottom form div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
}
div.registerPage div.registerContainer div.bottom form h4.forgotPassword {
  color: var(--text-color);
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 6px;
  cursor: pointer;
}
div.registerPage div.registerContainer div.bottom h4.signIn {
  font-size: 12px;
  color: #d5d5d6;
  font-weight: 400;
}
div.registerPage div.registerContainer div.bottom h4.signIn span {
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}
div.registerPage div.registerContainer div.bottom p {
  font-size: 11px;
  color: var(--inner-text-color);
  line-height: 1.6em;
  margin-bottom: 10px;
}
div.registerPage div.registerContainer div.bottom p span {
  color: var(--text-color);
  cursor: pointer;
}

div.commonPage {
  padding: 130px 0 40px;
}
@media all and (max-width: 2500px) {
  div.commonPage {
    padding: 220px 0 40px;
  }
}
@media all and (max-width: 1768px) {
  div.commonPage {
    padding: 180px 0 40px;
  }
}
div.commonPage div.top {
  padding-bottom: 60px;
  position: absolute;
  top: 60px;
  z-index: 8;
  left: 60px;
}
@media all and (max-width: 2500px) {
  div.commonPage div.top {
    top: 180px;
    left: 205px;
  }
}
@media all and (max-width: 1980px) {
  div.commonPage div.top {
    left: 165px;
  }
}
@media all and (max-width: 1768px) {
  div.commonPage div.top {
    top: 150px;
    left: 141px;
  }
}
@media all and (max-width: 1200px) {
  div.commonPage div.top {
    top: 135px;
    left: 111px;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.top {
    top: 108px;
    z-index: 8;
    left: 49px;
  }
}
@media all and (max-width: 480px) {
  div.commonPage div.top {
    left: 33px;
  }
}
div.commonPage div.top h1.heading {
  font-size: 26px;
  margin-bottom: 10px;
  margin-top: 20px;
  color: var(--text-color);
}
@media all and (max-width: 640px) {
  div.commonPage div.top h1.heading {
    font-size: 22px;
    margin-top: 50px;
  }
}
div.commonPage div.top p.desc {
  font-size: 18px;
  color: var(--inner-text-color);
}
@media all and (max-width: 640px) {
  div.commonPage div.top p.desc {
    font-size: 14px;
  }
}
div.commonPage div.bgImageContainer {
  width: 100%;
  display: block;
  height: 508px;
  position: relative;
}
@media all and (max-width: 768px) {
  div.commonPage div.bgImageContainer {
    height: 556px;
  }
}
div.commonPage div.bgImageContainer img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media all and (max-width: 768px) {
  div.commonPage div.bgImageContainer img {
    -o-object-fit: cover;
       object-fit: cover;
  }
}
div.commonPage div.bottomGradient {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(78.83deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 61.46%), linear-gradient(360deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 48.44%, rgba(16, 20, 31, 0.4) 100%);
}
div.commonPage div.bottom {
  margin-top: 40px;
}
div.commonPage div.bottom div.itemsContainer {
  display: flex;
  flex-wrap: wrap;
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount3 {
  width: 32.5%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount3:nth-child(3n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3 {
    width: 32%;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount4 {
  width: 24%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount4:nth-child(4n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4 {
    width: 23.8%;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount5 {
  width: 19%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount5:nth-child(5n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5 {
    width: 18.5%;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount6 {
  width: 15.8%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount6:nth-child(6n) {
  margin-right: 0;
}
@media all and (max-width: 1400px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6 {
    width: 15.5%;
  }
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6 {
    width: 15%;
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.portrait {
    width: 23.5%;
    margin-right: 13px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(5n) {
    margin-right: 13px;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.portrait {
    width: 23%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(5n) {
    margin-right: 13px;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.portrait {
    width: 31%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-right: 13px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.commonPage div.bottom div.itemsContainer div.items.portrait {
    width: 47%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-right: 13px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape {
    width: 32%;
    margin-right: 13px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape {
    width: 47.6%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 13px;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape {
    width: 48%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 13px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape {
    width: 47%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 13px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
div.commonPage div.bottom div.itemsContainer div.magazine.rowCount3 {
  width: 32.5%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.magazine.rowCount3:nth-child(3n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.magazine.rowCount3 {
    width: 32%;
  }
}
div.commonPage div.bottom div.itemsContainer div.magazine.rowCount4 {
  width: 19%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.magazine.rowCount4:nth-child(5n) {
  margin-right: 0;
}
div.commonPage div.bottom div.itemsContainer div.magazine.rowCount5 {
  width: 19%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.magazine.rowCount5:nth-child(5n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.magazine.rowCount5 {
    width: 18.5%;
  }
}
div.commonPage div.bottom div.itemsContainer div.magazine.rowCount6 {
  width: 15.8%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.magazine.rowCount6:nth-child(6n) {
  margin-right: 0;
}
@media all and (max-width: 1400px) {
  div.commonPage div.bottom div.itemsContainer div.magazine.rowCount6 {
    width: 15.5%;
  }
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.magazine.rowCount6 {
    width: 15%;
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.magazine.portrait {
    width: 23.5%;
    margin-right: 13px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.magazine.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.magazine.portrait:nth-child(5n) {
    margin-right: 13px;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.magazine.portrait {
    width: 23%;
  }
  div.commonPage div.bottom div.itemsContainer div.magazine.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.magazine.portrait:nth-child(5n) {
    margin-right: 13px;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.magazine.portrait {
    width: 31%;
  }
  div.commonPage div.bottom div.itemsContainer div.magazine.portrait:nth-child(4n) {
    margin-right: 13px;
  }
  div.commonPage div.bottom div.itemsContainer div.magazine.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.commonPage div.bottom div.itemsContainer div.magazine.portrait {
    width: 47%;
  }
  div.commonPage div.bottom div.itemsContainer div.magazine.portrait:nth-child(3n) {
    margin-right: 13px;
  }
  div.commonPage div.bottom div.itemsContainer div.magazine.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.magazine.landscape {
    width: 32%;
    margin-right: 13px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.magazine.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.magazine.landscape {
    width: 47.6%;
  }
  div.commonPage div.bottom div.itemsContainer div.magazine.landscape:nth-child(2n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.magazine.landscape:nth-child(3n) {
    margin-right: 13px;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.magazine.landscape {
    width: 48%;
  }
  div.commonPage div.bottom div.itemsContainer div.magazine.landscape:nth-child(3n) {
    margin-right: 13px;
  }
  div.commonPage div.bottom div.itemsContainer div.magazine.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.commonPage div.bottom div.itemsContainer div.magazine.landscape {
    width: 47%;
  }
  div.commonPage div.bottom div.itemsContainer div.magazine.landscape:nth-child(3n) {
    margin-right: 13px;
  }
  div.commonPage div.bottom div.itemsContainer div.magazine.landscape:nth-child(2n) {
    margin-right: 0;
  }
}

div.failed {
  padding: 220px 0 140px;
}
div.failed div.failedContainer {
  background-color: #fff;
  width: 25%;
  margin: 0 auto;
  position: relative;
  padding: 70px 20px 30px;
  text-align: center;
  border-radius: 8px;
}
@media all and (max-width: 1200px) {
  div.failed div.failedContainer {
    width: 31%;
  }
}
@media all and (max-width: 980px) {
  div.failed div.failedContainer {
    width: 41%;
  }
}
@media all and (max-width: 768px) {
  div.failed div.failedContainer {
    width: 45%;
  }
}
@media (max-width: 640px) {
  div.failed div.failedContainer {
    width: 52%;
  }
}
@media (max-width: 480px) {
  div.failed div.failedContainer {
    width: 68%;
  }
}
div.failed div.failedContainer h5 {
  color: #000;
  margin-bottom: 20px;
  font-size: 30px;
}
div.failed div.failedContainer p {
  color: #636363;
  font-size: 15px;
  margin-bottom: 40px;
}
div.failed div.failedContainer button {
  width: 100%;
  outline: none;
  border: 1px solid #e50914;
  background-color: #e50914;
  color: #fff;
  padding: 10px 0;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.failed div.failedContainer button:hover {
  background-color: transparent;
  color: #000;
}
div.failed div.failedContainer div.crossIcon {
  text-align: center;
  position: absolute;
  top: -35px;
  right: 0;
  left: 0;
}
div.failed div.failedContainer div.crossIcon svg {
  padding: 20px;
  background-color: #e50914;
  border-radius: 50%;
}

/* div.successContainer {
    padding: 100px 0;
    background-color: #fff;
    width: 30%;
    margin: 0 auto;
} */
div.success {
  padding: 220px 0 140px;
}
div.success div.successContainer {
  background-color: #fff;
  width: 25%;
  margin: 0 auto;
  position: relative;
  padding: 70px 20px 30px;
  text-align: center;
  border-radius: 8px;
}
div.success div.successContainer p.checking {
  color: #28a745;
}
@media all and (max-width: 1200px) {
  div.success div.successContainer {
    width: 31%;
  }
}
@media all and (max-width: 980px) {
  div.success div.successContainer {
    width: 41%;
  }
}
@media all and (max-width: 768px) {
  div.success div.successContainer {
    width: 45%;
  }
}
@media (max-width: 640px) {
  div.success div.successContainer {
    width: 52%;
  }
}
@media (max-width: 480px) {
  div.success div.successContainer {
    width: 68%;
  }
}
div.success div.successContainer h5 {
  color: #000;
  margin-bottom: 20px;
  font-size: 30px;
}
div.success div.successContainer p {
  color: #636363;
  font-size: 15px;
  margin-bottom: 40px;
}
div.success div.successContainer button {
  width: 100%;
  outline: none;
  border: 1px solid #28a745;
  background-color: #28a745;
  color: #fff;
  padding: 10px 0;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.success div.successContainer button:hover {
  background-color: transparent;
  color: #000;
}
div.success div.successContainer div.checkIcon {
  text-align: center;
  position: absolute;
  top: -35px;
  right: 0;
  left: 0;
}
div.success div.successContainer div.checkIcon svg {
  padding: 20px;
  background-color: #28a745;
  border-radius: 50%;
}

div.forgotPassword span.error {
  color: #E72A31;
  font-size: 12px;
  text-align: left;
  display: block;
  margin-bottom: 10px;
}
div.forgotPassword div.forgotPasswordContainer {
  padding: 220px 0;
}
@media all and (max-width: 640px) {
  div.forgotPassword div.forgotPasswordContainer {
    padding: 260px 0;
  }
}
div.forgotPassword div.forgotPasswordContainer h1.heading {
  font-size: 34px;
  color: var(--text-color);
  text-align: center;
  margin-bottom: 20px;
}
@media all and (max-width: 480px) {
  div.forgotPassword div.forgotPasswordContainer h1.heading {
    font-size: 26px;
  }
}
div.forgotPassword div.forgotPasswordContainer div.formContainer {
  text-align: center;
  width: 35rem;
  margin: 0 auto;
}
@media all and (max-width: 640px) {
  div.forgotPassword div.forgotPasswordContainer div.formContainer {
    width: 25rem;
  }
}
@media all and (max-width: 480px) {
  div.forgotPassword div.forgotPasswordContainer div.formContainer {
    width: 100%;
  }
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form input {
  background: transparent;
  outline: none;
  border: 1px solid var(--inner-text-color);
  text-align: left;
  width: 100%;
  padding: 20px 15px;
  border-radius: 8px;
  caret-color: var(--text-color);
  margin-bottom: 30px;
  color: var(--text-color);
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form input:focus {
  border: 1px solid var(--text-color);
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form input::-moz-placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form input::placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 20rem;
  margin: 0 auto;
}
@media all and (max-width: 640px) {
  div.forgotPassword div.forgotPasswordContainer div.formContainer form div.buttonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.forgotPassword div.forgotPasswordContainer div.formContainer form div.buttonContainer {
    width: 10rem;
  }
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.resetPassword {
  padding: 150px 0;
}
div.resetPassword span.error {
  color: #E72A31;
  font-size: 12px;
  text-align: left;
  display: block;
  margin-bottom: 10px;
}
div.resetPassword span.success {
  font-size: 14px;
  display: block;
  text-align: center;
  margin-top: 10px;
}
div.resetPassword h1.heading {
  font-size: 34px;
  padding-bottom: 10px;
  text-align: center;
  color: var(--text-color);
}
@media all and (max-width: 640px) {
  div.resetPassword h1.heading {
    font-size: 22px;
    margin: 0 20px;
  }
}
div.resetPassword div.contents {
  margin-top: 40px;
}
@media all and (max-width: 480px) {
  div.resetPassword div.contents {
    padding: 0 10px;
  }
}
div.resetPassword div.contents div.formSection {
  width: 30%;
  margin: 0 auto;
}
@media all and (max-width: 1200px) {
  div.resetPassword div.contents div.formSection {
    width: 55%;
  }
}
@media all and (max-width: 980px) {
  div.resetPassword div.contents div.formSection {
    width: 75%;
  }
}
@media all and (max-width: 640px) {
  div.resetPassword div.contents div.formSection {
    width: 100%;
  }
}
div.resetPassword div.contents div.formSection form div.inputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
div.resetPassword div.contents div.formSection form div.inputContainer:nth-child(3) {
  margin-bottom: 60px;
}
div.resetPassword div.contents div.formSection form div.inputContainer label {
  font-size: 16px;
  margin-bottom: 8px;
  color: var(--inner-text-color);
}
div.resetPassword div.contents div.formSection form div.inputContainer input {
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  background: transparent;
  text-align: left;
  padding: 20px 15px;
  border: 1px solid var(--inner-text-color);
  caret-color: var(--text-color);
  color: var(--text-color);
  margin-bottom: 10px;
}
div.resetPassword div.contents div.formSection form div.inputContainer input:focus {
  border: 1px solid var(--text-color);
}
@media all and (max-width: 640px) {
  div.resetPassword div.contents div.formSection form div.inputContainer input {
    padding: 16px 15px;
  }
}
div.resetPassword div.contents div.formSection form div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 20rem;
  margin: 0 auto;
}
@media all and (max-width: 640px) {
  div.resetPassword div.contents div.formSection form div.buttonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.resetPassword div.contents div.formSection form div.buttonContainer {
    width: 10rem;
  }
}
div.resetPassword div.contents div.formSection form div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.resetPassword div.contents div.formSection form div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.resetPassword div.contents div.formSection form div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

header.accountsPageHeader {
  height: 100px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  width: 95%;
  justify-content: space-between;
  margin: 0 auto;
}
header.accountsPageHeader div.left div.logoContainer h1 {
  width: 120px;
}
@media all and (max-width: 480px) {
  header.accountsPageHeader div.left div.logoContainer h1 {
    width: 72px;
  }
}
@media all and (max-width: 360px) {
  header.accountsPageHeader div.left div.logoContainer h1 {
    width: 72px;
  }
}
header.accountsPageHeader div.left div.logoContainer h1 img {
  display: block;
  width: 100%;
}
header.accountsPageHeader div.right button {
  background: transparent;
  color: var(--text-color);
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}

div.otpModal div.overlayOtp {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 8;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.otpModal div.otpContainer {
  z-index: 9;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  /* Firefox */
}
@media all and (max-width: 1200px) {
  div.otpModal div.otpContainer {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.otpModal div.otpContainer {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.otpModal div.otpContainer {
    width: 90%;
  }
}
div.otpModal div.otpContainer label {
  font-size: 16px;
  margin-bottom: 10px;
  color: var(--text-color);
}
div.otpModal div.otpContainer button.bg-button {
  margin: 20px auto 0;
  width: 30%;
}
div.otpModal div.otpContainer input {
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  background: transparent;
  text-align: left;
  padding: 20px 15px;
  border: 1px solid var(--inner-text-color);
  caret-color: var(--text-color);
  margin-bottom: 10px;
  color: var(--text-color);
}
div.otpModal div.otpContainer input:focus {
  border: 1px solid var(--text-color);
}
@media all and (max-width: 640px) {
  div.otpModal div.otpContainer input {
    padding: 16px 15px;
  }
}
div.otpModal div.otpContainer input::-webkit-outer-spin-button,
div.otpModal div.otpContainer input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
div.otpModal div.otpContainer input[type=number] {
  -moz-appearance: textfield;
}
div.otpModal div.otpContainer div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 20rem;
  margin: 30px auto 0;
}
@media all and (max-width: 640px) {
  div.otpModal div.otpContainer div.buttonContainer {
    width: 15rem;
    padding: 10px 20px;
  }
}
@media all and (max-width: 480px) {
  div.otpModal div.otpContainer div.buttonContainer {
    width: 10rem;
  }
}
div.otpModal div.otpContainer div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.otpModal div.otpContainer div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.otpModal div.otpContainer div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
div.otpModal div.otpContainer div.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
div.otpModal div.otpContainer div.buttonsContainer button {
  background: var(--button-color);
  color: var(--white-color);
  position: relative;
  z-index: 999;
  cursor: pointer;
  width: 100px;
  height: 40px;
  border: transparent;
  border-radius: 3px;
  font-size: medium;
  font-weight: 400;
  margin: 5px;
}
div.otpModal div.otpContainer div.buttonsContainer button:hover {
  background: var(--white-color);
  color: var(--button-color);
}
div.otpModal div.otpContainer div.buttonsContainer .cancel {
  background: transparent;
  border: 1px solid;
}
div.otpModal div.otpContainer div.buttonsContainer .cancel:hover {
  color: rgba(0, 0, 0, 0.7);
}

div.continueWatching div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.continueWatching div.contents {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.continueWatching div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.continueWatching div.contents {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.continueWatching div.contents {
    width: 90%;
  }
}
div.continueWatching div.contents h1 {
  color: var(--inner-text-color);
  font-size: 22px;
}
div.continueWatching div.contents div.buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
div.continueWatching div.contents div.buttons button.bg-button {
  margin: 20px auto 0;
  width: 30%;
}
div.continueWatching div.contents div.buttons div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  margin-right: 20px;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 10rem;
}
div.continueWatching div.contents div.buttons div.buttonContainer:last-child {
  margin-right: 0;
}
@media all and (max-width: 640px) {
  div.continueWatching div.contents div.buttons div.buttonContainer {
    width: 15rem;
    padding: 10px 20px;
  }
}
@media all and (max-width: 480px) {
  div.continueWatching div.contents div.buttons div.buttonContainer {
    width: 10rem;
  }
}
div.continueWatching div.contents div.buttons div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.continueWatching div.contents div.buttons div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.continueWatching div.contents div.buttons div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.currentPasswordModal div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.currentPasswordModal div.currentPasswordContainer {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  /* Firefox */
}
@media all and (max-width: 1200px) {
  div.currentPasswordModal div.currentPasswordContainer {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.currentPasswordModal div.currentPasswordContainer {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.currentPasswordModal div.currentPasswordContainer {
    width: 90%;
  }
}
div.currentPasswordModal div.currentPasswordContainer label {
  font-size: 16px;
  margin-bottom: 10px;
  color: var(--text-color);
}
div.currentPasswordModal div.currentPasswordContainer button.bg-button {
  margin: 20px auto 0;
  width: 30%;
}
div.currentPasswordModal div.currentPasswordContainer input {
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  background: transparent;
  text-align: left;
  padding: 20px 15px;
  border: 1px solid var(--inner-text-color);
  caret-color: var(--text-color);
  display: block;
  width: 100%;
  color: var(--text-color);
}
div.currentPasswordModal div.currentPasswordContainer input:focus {
  border: 1px solid var(--text-color);
}
@media all and (max-width: 640px) {
  div.currentPasswordModal div.currentPasswordContainer input {
    padding: 16px 15px;
  }
}
div.currentPasswordModal div.currentPasswordContainer input::-webkit-outer-spin-button,
div.currentPasswordModal div.currentPasswordContainer input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
div.currentPasswordModal div.currentPasswordContainer input[type=number] {
  -moz-appearance: textfield;
}
div.currentPasswordModal div.currentPasswordContainer div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 10rem;
  margin: 30px auto 0;
}
@media all and (max-width: 640px) {
  div.currentPasswordModal div.currentPasswordContainer div.buttonContainer {
    width: 15rem;
    padding: 10px 20px;
  }
}
@media all and (max-width: 480px) {
  div.currentPasswordModal div.currentPasswordContainer div.buttonContainer {
    width: 10rem;
  }
}
div.currentPasswordModal div.currentPasswordContainer div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.currentPasswordModal div.currentPasswordContainer div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.currentPasswordModal div.currentPasswordContainer div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.watchWithoutAds div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.watchWithoutAds div.contents {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.watchWithoutAds div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.watchWithoutAds div.contents {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.watchWithoutAds div.contents {
    width: 90%;
  }
}
div.watchWithoutAds div.contents h1 {
  color: var(--inner-text-color);
  font-size: 22px;
}
div.watchWithoutAds div.contents div.buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
div.watchWithoutAds div.contents div.buttons button.bg-button {
  margin: 20px auto 0;
  width: 30%;
}
div.watchWithoutAds div.contents div.buttons div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  margin-right: 20px;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 10rem;
}
div.watchWithoutAds div.contents div.buttons div.buttonContainer:last-child {
  margin-right: 0;
}
@media all and (max-width: 640px) {
  div.watchWithoutAds div.contents div.buttons div.buttonContainer {
    width: 15rem;
    padding: 10px 20px;
  }
}
@media all and (max-width: 480px) {
  div.watchWithoutAds div.contents div.buttons div.buttonContainer {
    width: 10rem;
  }
}
div.watchWithoutAds div.contents div.buttons div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.watchWithoutAds div.contents div.buttons div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.watchWithoutAds div.contents div.buttons div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.logoutAll div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.logoutAll div.closeIconContainer {
  position: fixed;
  top: 20px;
  right: 30px;
  z-index: 999;
}
div.logoutAll div.closeIconContainer svg {
  width: 20px !important;
  height: 20px !important;
}
div.logoutAll div.contents {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.logoutAll div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.logoutAll div.contents {
    width: 80%;
  }
}
@media all and (max-width: 480px) {
  div.logoutAll div.contents {
    width: 90%;
  }
}
div.logoutAll div.contents h1.error {
  font-size: 20px;
  color: #E72A31;
  margin-bottom: 10px;
}
div.logoutAll div.contents p {
  color: var(--text-color);
  font-size: 16px;
}
div.logoutAll div.contents h1 {
  color: var(--text-color);
  font-size: 18px;
}
@media all and (max-width: 768px) {
  div.logoutAll div.contents h1 {
    font-size: 16px;
  }
}
div.logoutAll div.contents div.buttonContainer {
  margin-top: 20px;
}
div.logoutAll div.contents div.buttonContainer button {
  background-color: var(--button-hover);
  padding: 10px 12px;
  color: var(--white-color);
  font-size: 13px;
  cursor: pointer;
  width: 10rem;
  border-radius: 10px;
}

div.cancelSub div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.cancelSub div.closeIconContainer {
  position: fixed;
  top: 20px;
  right: 30px;
  z-index: 999;
}
div.cancelSub div.closeIconContainer svg {
  width: 20px !important;
  height: 20px !important;
  stroke: #E72A31 !important;
}
div.cancelSub div.contents {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.cancelSub div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.cancelSub div.contents {
    width: 80%;
  }
}
@media all and (max-width: 480px) {
  div.cancelSub div.contents {
    width: 90%;
  }
}
div.cancelSub div.contents h1.error {
  font-size: 20px;
  color: #E72A31;
  margin-bottom: 10px;
}
div.cancelSub div.contents p {
  color: var(--text-color);
  font-size: 16px;
}
div.cancelSub div.contents h1 {
  color: var(--inner-text-color);
  font-size: 18px;
}
@media all and (max-width: 768px) {
  div.cancelSub div.contents h1 {
    font-size: 16px;
  }
}
div.cancelSub div.contents div.buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
div.cancelSub div.contents div.buttons button.bg-button {
  margin: 20px auto 0;
  width: 30%;
}
div.cancelSub div.contents div.buttons div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  margin-right: 20px;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 10rem;
}
div.cancelSub div.contents div.buttons div.buttonContainer:last-child {
  margin-right: 0;
}
div.cancelSub div.contents div.buttons div.buttonContainer:last-child div.background {
  background: var(--error-color);
}
@media all and (max-width: 640px) {
  div.cancelSub div.contents div.buttons div.buttonContainer {
    width: 15rem;
    padding: 10px 20px;
  }
}
@media all and (max-width: 480px) {
  div.cancelSub div.contents div.buttons div.buttonContainer {
    width: 10rem;
  }
}
div.cancelSub div.contents div.buttons div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.cancelSub div.contents div.buttons div.buttonContainer div.background {
  background: var(--success-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.cancelSub div.contents div.buttons div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.playNextEpisode div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.playNextEpisode div.contents {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.playNextEpisode div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.playNextEpisode div.contents {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.playNextEpisode div.contents {
    width: 90%;
  }
}
div.playNextEpisode div.contents h1 {
  color: var(--inner-text-color);
  font-size: 22px;
}
div.playNextEpisode div.contents div.buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
div.playNextEpisode div.contents div.buttons button.bg-button {
  margin: 20px auto 0;
  width: 30%;
}
div.playNextEpisode div.contents div.buttons div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  margin-right: 20px;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 10rem;
}
div.playNextEpisode div.contents div.buttons div.buttonContainer:last-child {
  margin-right: 0;
}
@media all and (max-width: 640px) {
  div.playNextEpisode div.contents div.buttons div.buttonContainer {
    width: 15rem;
    padding: 10px 20px;
  }
}
@media all and (max-width: 480px) {
  div.playNextEpisode div.contents div.buttons div.buttonContainer {
    width: 10rem;
  }
}
div.playNextEpisode div.contents div.buttons div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.playNextEpisode div.contents div.buttons div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.playNextEpisode div.contents div.buttons div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.accountSettings {
  padding: 130px 0;
  position: relative;
}
@media all and (max-width: 2500px) {
  div.accountSettings {
    padding: 195px 0;
  }
}
@media all and (max-width: 1768px) {
  div.accountSettings {
    padding: 150px 0;
  }
}
div.accountSettings span.error {
  font-size: 14px;
  color: #E72A31;
  display: block;
  margin-top: 10px;
  font-weight: normal !important;
}
div.accountSettings div.wrapper div.head h1.heading {
  font-size: 34px;
  padding-bottom: 10px;
  color: var(--text-color);
  text-align: center;
}
@media all and (max-width: 640px) {
  div.accountSettings div.wrapper div.head h1.heading {
    font-size: 28px;
  }
}
@media all and (max-width: 640px) {
  div.accountSettings div.wrapper div.head {
    padding-left: 20px;
  }
}
div.accountSettings div.wrapper div.contents {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  padding-top: 100px;
}
@media all and (max-width: 1200px) {
  div.accountSettings div.wrapper div.contents {
    width: 90%;
  }
}
@media all and (max-width: 980px) {
  div.accountSettings div.wrapper div.contents {
    width: 100%;
  }
}
@media all and (max-width: 768px) {
  div.accountSettings div.wrapper div.contents {
    flex-direction: column;
    justify-content: center;
  }
}
@media all and (max-width: 640px) {
  div.accountSettings div.wrapper div.contents {
    padding: 80px 20px;
  }
}
div.accountSettings div.wrapper div.contents div.left {
  width: 40%;
}
@media all and (max-width: 768px) {
  div.accountSettings div.wrapper div.contents div.left {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.accountSettings div.wrapper div.contents div.left div.profileImageContainer {
  width: 250px;
  text-align: center;
}
@media all and (max-width: 768px) {
  div.accountSettings div.wrapper div.contents div.left div.profileImageContainer {
    margin: 0 auto;
  }
}
div.accountSettings div.wrapper div.contents div.left div.profileImageContainer img {
  display: block;
  width: 100%;
  border-radius: 50%;
  height: 250px;
  -o-object-fit: cover;
     object-fit: cover;
}
div.accountSettings div.wrapper div.contents div.left div.profileImageContainer input {
  display: none;
}
div.accountSettings div.wrapper div.contents div.left div.profileImageContainer label {
  color: var(--text-hover);
  font-size: 14px;
  display: inline-block;
  margin-top: 15px;
  cursor: pointer;
  font-weight: 600;
}
div.accountSettings div.wrapper div.contents div.right {
  width: 55%;
}
@media all and (max-width: 768px) {
  div.accountSettings div.wrapper div.contents div.right {
    width: 100%;
  }
}
div.accountSettings div.wrapper div.contents div.right div.formSection form input, div.accountSettings div.wrapper div.contents div.right div.formSection form select {
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  background: transparent;
  text-align: left;
  padding: 20px 15px;
  border: 1px solid var(--inner-text-color);
  caret-color: var(--text-color);
  display: block;
  width: 100%;
  color: var(--text-color);
}
div.accountSettings div.wrapper div.contents div.right div.formSection form input:focus, div.accountSettings div.wrapper div.contents div.right div.formSection form select:focus {
  border: 1px solid var(--text-color);
}
@media all and (max-width: 640px) {
  div.accountSettings div.wrapper div.contents div.right div.formSection form input, div.accountSettings div.wrapper div.contents div.right div.formSection form select {
    padding: 16px 15px;
  }
}
div.accountSettings div.wrapper div.contents div.right div.formSection form select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  color: var(--text-color) !important;
}
div.accountSettings div.wrapper div.contents div.right div.formSection form select option {
  color: #000 !important;
}
div.accountSettings div.wrapper div.contents div.right div.formSection form label {
  color: var(--inner-text-color);
  font-size: 16px;
  display: inline-block;
  margin-bottom: 10px;
}
div.accountSettings div.wrapper div.contents div.right div.formSection form span.success {
  font-size: 12px;
}
div.accountSettings div.wrapper div.contents div.right div.formSection form div.half {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
@media all and (max-width: 480px) {
  div.accountSettings div.wrapper div.contents div.right div.formSection form div.half {
    flex-direction: column;
    margin-bottom: 20px;
  }
}
div.accountSettings div.wrapper div.contents div.right div.formSection form div.half div.fname, div.accountSettings div.wrapper div.contents div.right div.formSection form div.half div.email {
  width: 49%;
}
@media all and (max-width: 480px) {
  div.accountSettings div.wrapper div.contents div.right div.formSection form div.half div.fname, div.accountSettings div.wrapper div.contents div.right div.formSection form div.half div.email {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.accountSettings div.wrapper div.contents div.right div.formSection form div.half div.email input {
  color: var(--inner-text-color);
}
div.accountSettings div.wrapper div.contents div.right div.formSection form div.half div.lname, div.accountSettings div.wrapper div.contents div.right div.formSection form div.half div.country {
  width: 49%;
}
@media all and (max-width: 480px) {
  div.accountSettings div.wrapper div.contents div.right div.formSection form div.half div.lname, div.accountSettings div.wrapper div.contents div.right div.formSection form div.half div.country {
    width: 100%;
  }
}
div.accountSettings div.wrapper div.contents div.right div.formSection form div.full span.cpassword {
  display: inline-block !important;
}
div.accountSettings div.wrapper div.contents div.right div.formSection form div.full div.phone {
  width: 49%;
}
@media all and (max-width: 480px) {
  div.accountSettings div.wrapper div.contents div.right div.formSection form div.full div.phone {
    width: 100%;
  }
}
div.accountSettings div.wrapper div.contents div.right div.formSection form div.full span {
  color: var(--text-hover);
  font-size: 14px;
  display: inline-block;
  margin-top: 10px;
}
div.accountSettings div.wrapper div.contents div.right div.formSection form span {
  display: block !important;
  font-weight: 600;
  cursor: pointer;
}
div.accountSettings div.wrapper div.contents div.right div.formSection form div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 20rem;
  margin: 30px auto 0;
}
@media all and (max-width: 640px) {
  div.accountSettings div.wrapper div.contents div.right div.formSection form div.buttonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.accountSettings div.wrapper div.contents div.right div.formSection form div.buttonContainer {
    width: 10rem;
  }
}
div.accountSettings div.wrapper div.contents div.right div.formSection form div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.accountSettings div.wrapper div.contents div.right div.formSection form div.buttonContainer div.background {
  background: var(--button-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.accountSettings div.wrapper div.contents div.right div.formSection form div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
div.accountSettings div.wrapper div.planDetails {
  width: 80%;
  margin: 30px auto 0;
  padding: 20px 0 0;
  border-top: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 768px) {
  div.accountSettings div.wrapper div.planDetails {
    width: 100%;
  }
}
@media all and (max-width: 480px) {
  div.accountSettings div.wrapper div.planDetails {
    flex-direction: column;
  }
}
div.accountSettings div.wrapper div.planDetails div.leftContainer {
  width: 30%;
}
@media all and (max-width: 480px) {
  div.accountSettings div.wrapper div.planDetails div.leftContainer {
    margin-bottom: 20px;
    width: 100%;
  }
}
div.accountSettings div.wrapper div.planDetails div.leftContainer h4.heading {
  color: var(--inner-text-color);
  font-size: 13px;
  font-weight: normal;
}
div.accountSettings div.wrapper div.planDetails div.rightContainer {
  width: 70%;
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 768px) {
  div.accountSettings div.wrapper div.planDetails div.rightContainer {
    flex-direction: column;
  }
}
@media all and (max-width: 768px) {
  div.accountSettings div.wrapper div.planDetails div.rightContainer div.left {
    margin-bottom: 20px;
  }
}
div.accountSettings div.wrapper div.planDetails div.rightContainer div.left p {
  color: #E72A31;
}
div.accountSettings div.wrapper div.planDetails div.rightContainer div.left h2.planName {
  color: var(--text-color);
  font-size: 14px;
}
div.accountSettings div.wrapper div.planDetails div.rightContainer div.left div.buttonContainer {
  display: flex;
  padding: 12px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 15rem;
  margin: 20px auto 0;
}
@media all and (max-width: 768px) {
  div.accountSettings div.wrapper div.planDetails div.rightContainer div.left div.buttonContainer {
    margin: 20px 0 0;
  }
}
@media all and (max-width: 640px) {
  div.accountSettings div.wrapper div.planDetails div.rightContainer div.left div.buttonContainer {
    width: 12rem;
  }
}
@media all and (max-width: 480px) {
  div.accountSettings div.wrapper div.planDetails div.rightContainer div.left div.buttonContainer {
    width: 15rem;
  }
}
div.accountSettings div.wrapper div.planDetails div.rightContainer div.left div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.accountSettings div.wrapper div.planDetails div.rightContainer div.left div.buttonContainer div.background {
  background: #E72A31;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.accountSettings div.wrapper div.planDetails div.rightContainer div.left div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
div.accountSettings div.wrapper div.planDetails div.rightContainer div.right span {
  display: block;
  color: var(--text-hover);
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  cursor: pointer;
}
div.accountSettings div.wrapper div.planDetails div.rightContainer div.right span:first-child {
  margin-top: 0;
}
div.accountSettings div.wrapper div.settings {
  width: 80%;
  margin: 30px auto 0;
  padding: 20px 0;
  border-top: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 768px) {
  div.accountSettings div.wrapper div.settings {
    width: 100%;
  }
}
@media all and (max-width: 480px) {
  div.accountSettings div.wrapper div.settings {
    flex-direction: column;
  }
}
div.accountSettings div.wrapper div.settings div.leftContainer {
  width: 30%;
}
@media all and (max-width: 480px) {
  div.accountSettings div.wrapper div.settings div.leftContainer {
    margin-bottom: 20px;
    width: 100%;
  }
}
div.accountSettings div.wrapper div.settings div.leftContainer h4.heading {
  color: var(--inner-text-color);
  font-size: 13px;
  font-weight: normal;
}
div.accountSettings div.wrapper div.settings div.rightContainer {
  width: 70%;
}
div.accountSettings div.wrapper div.settings div.rightContainer ul li {
  margin-top: 10px;
}
div.accountSettings div.wrapper div.settings div.rightContainer ul li:first-child {
  margin-top: 0;
}
div.accountSettings div.wrapper div.settings div.rightContainer ul li span {
  display: inline-block;
  color: var(--text-hover);
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

div.billingAndSubscription {
  padding: 130px 0;
  width: 80%;
  margin: 0 auto;
}
@media all and (max-width: 2500px) {
  div.billingAndSubscription {
    padding: 200px 0;
  }
}
@media all and (max-width: 1600px) {
  div.billingAndSubscription {
    padding: 140px 0;
  }
}
@media all and (max-width: 1200px) {
  div.billingAndSubscription {
    width: 100%;
  }
}
div.billingAndSubscription div.wrapper h1.heading {
  font-size: 24px;
  color: var(--text-color);
  margin-bottom: 30px;
}
div.billingAndSubscription div.wrapper div.billingDetails {
  margin-bottom: 30px;
}
div.billingAndSubscription div.wrapper div.billingDetails h6.subHeading {
  font-size: 14px;
  color: var(--inner-text-color);
  font-weight: normal;
  letter-spacing: 0.3px;
  margin-bottom: 20px;
}
div.billingAndSubscription div.wrapper div.billingDetails div.billingDetailsContainer {
  border: 3px solid var(--border-color);
  padding: 20px;
}
div.billingAndSubscription div.wrapper div.billingDetails div.billingDetailsContainer span.head {
  display: block;
  color: var(--inner-text-color);
  margin-bottom: 10px;
}
div.billingAndSubscription div.wrapper div.billingDetails div.billingDetailsContainer span.value {
  display: block;
  color: var(--text-color);
  font-size: 14px;
}
div.billingAndSubscription div.wrapper div.billingDetails div.billingDetailsContainer span.value.bold {
  font-weight: 600;
  margin-bottom: 20px;
}
div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li.head {
  display: flex;
  color: var(--inner-text-color);
  font-size: 14px;
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li.head {
    display: none;
  }
}
div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li {
  border-bottom: 1px solid var(--border-color);
  display: flex;
  padding: 6px;
  color: var(--text-color);
  font-size: 14px;
}
div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.left {
  display: flex;
  width: 80%;
}
@media all and (max-width: 1200px) {
  div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.left {
    width: 100%;
  }
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.left {
    flex-direction: column;
  }
}
div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.left div.date {
  width: 17%;
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.left div.date {
    width: 100%;
    margin-bottom: 10px;
  }
}
div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.left div.transactionType {
  width: 25%;
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.left div.transactionType {
    width: 100%;
  }
}
div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.left div.servicePeriod {
  width: 25%;
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.left div.servicePeriod {
    width: 100%;
    margin-bottom: 10px;
  }
}
div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.left div.paymentMethod {
  width: 25%;
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.left div.paymentMethod {
    width: 100%;
    margin-bottom: 10px;
  }
}
div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.left div.subscriptionName {
  width: 25%;
}
@media all and (max-width: 980px) {
  div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.left div.subscriptionName {
    width: 30%;
  }
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.left div.subscriptionName {
    width: 100%;
  }
}
div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.right {
  width: 20%;
  text-align: right;
}
@media all and (max-width: 980px) {
  div.billingAndSubscription div.wrapper div.subscriptionDetails ul.subscriptionContainer li div.right {
    width: 8%;
  }
}
div.billingAndSubscription div.wrapper div.bottom {
  margin-top: 30px;
}
div.billingAndSubscription div.wrapper div.bottom p {
  color: var(--inner-text-color);
  font-size: 12px;
}
div.billingAndSubscription div.wrapper div.bottom div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 20rem;
  margin: 30px auto 0;
}
@media all and (max-width: 640px) {
  div.billingAndSubscription div.wrapper div.bottom div.buttonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.billingAndSubscription div.wrapper div.bottom div.buttonContainer {
    width: 10rem;
  }
}
div.billingAndSubscription div.wrapper div.bottom div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.billingAndSubscription div.wrapper div.bottom div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.billingAndSubscription div.wrapper div.bottom div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.streamingActivity {
  padding: 130px 0;
  width: 80%;
  margin: 0 auto;
}
@media all and (max-width: 2500px) {
  div.streamingActivity {
    padding: 200px 0;
  }
}
@media all and (max-width: 1768px) {
  div.streamingActivity {
    padding: 160px 0;
  }
}
@media all and (max-width: 1600px) {
  div.streamingActivity {
    padding: 145px 0;
  }
}
@media all and (max-width: 1200px) {
  div.streamingActivity {
    width: 100%;
  }
}
div.streamingActivity h1.heading {
  font-size: 24px;
  color: var(--text-color);
  margin-bottom: 30px;
}
div.streamingActivity p {
  font-size: 18px;
  color: var(--inner-text-color);
  margin-bottom: 30px;
}
div.streamingActivity ul.recentDeviceContainer li {
  border-bottom: 1px dashed #f5deb3;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
div.streamingActivity ul.recentDeviceContainer li span {
  color: #f5deb3;
  display: block;
  font-size: 14px;
}
div.streamingActivity ul.recentDeviceContainer li span:first-child {
  margin-bottom: 10px;
}
div.streamingActivity div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 20rem;
  margin: 30px auto 0;
}
@media all and (max-width: 640px) {
  div.streamingActivity div.buttonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.streamingActivity div.buttonContainer {
    width: 10rem;
  }
}
div.streamingActivity div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.streamingActivity div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.streamingActivity div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.livePlayer {
  width: 100%;
}
div.livePlayer div.videoContainer {
  width: 100%;
  position: relative;
  padding: 50px 0 0px;
}
div.livePlayer div.videoContainer video {
  display: block;
  width: 100%;
}
@media all and (min-width: 768px) {
  div.livePlayer div.videoContainer:hover div.bottom div.details {
    transform: translateY(0);
  }
}
div.livePlayer div.videoContainer div.bottom {
  width: 90%;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  height: 200px;
  overflow: hidden;
}
@media all and (max-width: 768px) {
  div.livePlayer div.videoContainer div.bottom {
    position: relative;
    bottom: unset;
    left: unset;
    transform: unset;
    width: 100%;
    height: auto;
  }
}
div.livePlayer div.videoContainer div.bottom div.details {
  display: flex;
  justify-content: space-between;
  height: 100%;
  transform: translateY(200px);
  transition: 0.3s ease-in-out;
  background-color: rgba(41, 36, 35, 0.6);
  padding: 20px;
  border-radius: 8px;
}
@media all and (max-width: 768px) {
  div.livePlayer div.videoContainer div.bottom div.details {
    flex-direction: column;
    transform: unset;
  }
}
div.livePlayer div.videoContainer div.bottom div.details h1.heading {
  font-size: 28px;
  color: var(--white-color);
  margin-bottom: 10px;
}
@media all and (max-width: 768px) {
  div.livePlayer div.videoContainer div.bottom div.details h1.heading {
    font-size: 22px;
  }
}
div.livePlayer div.videoContainer div.bottom div.details h1.title {
  font-size: 20px;
  color: var(--text-hover);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.livePlayer div.videoContainer div.bottom div.details span.time {
  font-size: 14px;
  color: var(--text-color);
  display: inline-block;
  margin-bottom: 10px;
}
div.livePlayer div.videoContainer div.bottom div.details p.description {
  color: var(--white-color);
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
div.livePlayer div.videoContainer div.bottom div.details div.left {
  width: 48%;
  height: 100%;
}
@media all and (max-width: 768px) {
  div.livePlayer div.videoContainer div.bottom div.details div.left {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.livePlayer div.videoContainer div.bottom div.details div.right {
  width: 48%;
  height: 100%;
}
@media all and (max-width: 768px) {
  div.livePlayer div.videoContainer div.bottom div.details div.right {
    width: 100%;
  }
}

div.showsRow {
  padding-top: 40px;
}
div.showsRow h1.heading {
  font-size: 25px;
  color: var(--text-color);
  margin-bottom: 20px;
}
div.showsRow .swiper-button-prev {
  left: 0 !important;
  top: 50% !important;
}
div.showsRow .swiper-button-prev::after {
  left: 20px !important;
}
div.showsRow .swiper-button-disabled {
  display: none;
}
div.showsRow .swiper-button-next {
  right: 0 !important;
  top: 50% !important;
}
div.showsRow .swiper-button-next::after {
  right: 20px !important;
}
div.showsRow div.seasonContainer {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  width: 20rem;
  margin-bottom: 20px;
  background: transparent;
  padding: 15px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  color: var(--text-color);
  cursor: pointer;
}
@media all and (max-width: 480px) {
  div.showsRow div.seasonContainer {
    width: 100%;
  }
}
div.showsRow div.seasonContainer svg {
  width: 26px !important;
  height: 26px !important;
  transition: 0.3s ease-in-out;
}
div.showsRow div.seasonContainer svg.active {
  transform: rotate(-180deg);
}
div.showsRow div.seasonContainer.zIndexController div.listContainer {
  z-index: 9;
}
div.showsRow div.seasonContainer div.listContainer {
  height: 200px;
  overflow-y: scroll;
  position: absolute;
  top: 55px;
  width: 100%;
  left: 0;
  right: 0;
  z-index: -1;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  cursor: pointer;
}
div.showsRow div.seasonContainer div.listContainer::-webkit-scrollbar {
  display: none;
}
div.showsRow div.seasonContainer div.listContainer.scroll {
  -ms-overflow-style: unset; /* IE and Edge */
  scrollbar-width: unset; /* Firefox */
}
div.showsRow div.seasonContainer div.listContainer.scroll::-webkit-scrollbar {
  display: block;
}
div.showsRow div.seasonContainer div.listContainer.scroll::-webkit-scrollbar {
  width: 5px;
  border-radius: 30px;
}
div.showsRow div.seasonContainer div.listContainer.scroll::-webkit-scrollbar-thumb {
  background-color: var(--bg-color);
  border-radius: 30px;
}
div.showsRow div.seasonContainer div.listContainer.scroll::-webkit-scrollbar-track {
  background-color: var(--grey-color);
}
div.showsRow div.seasonContainer div.list {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  display: none;
}
div.showsRow div.seasonContainer div.list.active {
  display: block;
}
div.showsRow div.seasonContainer div.list div.item {
  width: 100%;
  padding: 8px 10px;
  border-bottom: 1px solid var(--border-color);
  background-color: #1c1f29;
}
div.showsRow div.seasonContainer div.list div.item:last-child {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
div.showsRow div.seasonContainer div.list div.item span {
  font-size: 16px;
  color: var(--text-color);
}

div.subcriptionPage {
  padding: 150px 5px;
  background-color: var(--secondary-bg-color);
}
div.subcriptionPage h1.heading {
  text-align: center;
  font-size: 34px;
  color: var(--text-color);
}
div.subcriptionPage div.subscriptionContainer {
  width: 90%;
  margin: 30px auto 0;
  overflow-x: scroll;
}
div.subcriptionPage div.subscriptionContainer table {
  width: 100%;
  border-collapse: collapse;
}
div.subcriptionPage div.subscriptionContainer table thead tr th {
  width: 80%;
}
div.subcriptionPage div.subscriptionContainer table thead tr th button {
  display: inline-block;
  padding: 10px 25px;
  border-radius: 30px;
  color: var(--text-color);
  cursor: pointer;
}
div.subcriptionPage div.subscriptionContainer table thead tr th button.active {
  background-color: var(--text-hover);
}
div.subcriptionPage div.subscriptionContainer table thead tr th button.no-active {
  background-color: var(--inner-text-color);
}
div.subcriptionPage div.subscriptionContainer table tbody tr {
  border-bottom: 1px solid var(--border-color);
}
div.subcriptionPage div.subscriptionContainer table tbody tr td {
  padding: 10px 0;
}
div.subcriptionPage div.subscriptionContainer table tbody tr td.big span {
  color: var(--text-color);
}
div.subcriptionPage div.subscriptionContainer table tbody tr td.value span {
  display: block;
  text-align: center;
}
div.subcriptionPage div.subscriptionContainer table tbody tr td.value span.no-active {
  color: var(--inner-text-color);
}
div.subcriptionPage div.subscriptionContainer table tbody tr td.value span.no-active svg {
  stroke: var(--inner-text-color) !important;
}
div.subcriptionPage div.subscriptionContainer table tbody tr td.value span.active {
  color: var(--text-hover);
}
div.subcriptionPage div.subscriptionContainer table tbody tr td.value span.active svg {
  stroke: var(--text-hover) !important;
}
div.subcriptionPage div.subscriptionContainer div.buyNowButtonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 15rem;
  margin: 50px auto 0;
}
@media all and (max-width: 640px) {
  div.subcriptionPage div.subscriptionContainer div.buyNowButtonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.subcriptionPage div.subscriptionContainer div.buyNowButtonContainer {
    width: 10rem;
  }
}
div.subcriptionPage div.subscriptionContainer div.buyNowButtonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.subcriptionPage div.subscriptionContainer div.buyNowButtonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.subcriptionPage div.subscriptionContainer div.buyNowButtonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.subscriptionList {
  padding: 130px 5px;
  background-color: var(--sub-bg-color);
}
@media all and (max-width: 2600px) {
  div.subscriptionList {
    padding: 200px 5px;
  }
}
div.subscriptionList h1.heading {
  font-size: 28px;
  text-align: center;
  color: var(--text-color);
}
div.subscriptionList div.wrapper {
  width: 90%;
}
div.subscriptionList div.subscriptionDetails {
  border: 1px solid var(--border-color);
  box-shadow: rgba(255, 255, 255, 0.24) 0px 3px 8px;
  padding: 15px 28px;
  margin-top: 50px;
  color: var(--text-color);
}
div.subscriptionList div.subscriptionDetails h2.heading {
  font-size: 22px;
  color: var(--sub-heading-color);
  text-align: center;
}
div.subscriptionList div.subscriptionDetails ul.conditions {
  list-style: square;
  margin-top: 30px;
}
div.subscriptionList div.subscriptionDetails ul.conditions li {
  line-height: 28px;
}
div.subscriptionList div.subscriptionDetails ul.conditions li span {
  font-size: 16px;
}
div.subscriptionList div.pricing {
  margin-top: 30px;
}
div.subscriptionList div.pricing ul {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
}
div.subscriptionList div.pricing ul li {
  margin-right: 20px;
  margin-bottom: 30px;
  padding: 60px 60px;
  border: 1px solid var(--border-color);
  width: 20rem;
  text-align: center;
  box-shadow: rgba(255, 255, 255, 0.24) 0px 3px 8px;
}
div.subscriptionList div.pricing ul li:last-child {
  margin-right: 0;
}
div.subscriptionList div.pricing ul li button {
  display: block;
  border: none;
  outline: none;
  padding: 10px 25px;
  background-color: transparent;
  border: 1px solid var(--border-color);
  margin: 0 auto 20px;
  color: #fff;
  border-radius: 30px;
  transition: 0.5s all ease-in-out;
  cursor: pointer;
}
div.subscriptionList div.pricing ul li button:hover {
  background-color: var(--sub-heading-color);
  border: 1px solid gray;
  color: gray;
}
div.subscriptionList div.pricing ul li span.category {
  display: block;
  margin-bottom: 10px;
  color: var(--text-color);
}
div.subscriptionList div.pricing ul li span.price {
  display: block;
  color: var(--text-color);
}
div.subscriptionList div.pricing ul li span.price.discount span.priceCut {
  margin-right: 10px;
  text-decoration: line-through;
  display: block;
}

/* div.subscriptionList div.subscriptionDetails ul.conditions li::before { 
    content: "-";
    margin-right: 4px;
} */
@media all and (max-width: 1200px) {
  div.subscriptionList div.subscriptionDetails h2.heading {
    font-size: 20px;
  }
  div.subscriptionList div.subscriptionDetails ul.conditions li {
    font-size: 15px;
  }
}
@media all and (max-width: 980px) {
  div.subscriptionList div.subscriptionDetails h2.heading {
    font-size: 19px;
  }
}
@media all and (max-width: 768px) {
  div.subscriptionList div.subscriptionDetails h2.heading {
    font-size: 18px;
  }
}
@media all and (max-width: 640px) {
  div.subscriptionList div.pricing ul {
    flex-direction: column;
  }
  div.subscriptionList div.pricing ul li {
    margin-right: 0;
    width: 80%;
    margin: 0 auto 20px;
  }
  div.subscriptionList div.pricing ul li:last-child {
    margin-bottom: 0;
  }
  div.subscriptionList div.pricing ul li button {
    margin: 0 auto 20px;
  }
}
@media all and (max-width: 480px) {
  div.subscriptionList div.pricing ul li {
    width: 100%;
  }
  div.subscriptionList h1.heading {
    font-size: 25px;
  }
}
div.paymentScreen {
  padding: 100px 0;
  background-color: var(--sub-bg-color);
}
div.paymentScreen .paypal-button-container {
  min-width: 326px !important;
  max-width: 284px !important;
}
div.paymentScreen span.success {
  color: var(--success-color);
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper {
    width: 90%;
  }
}
div.paymentScreen div.wrapper h1.heading {
  text-align: center;
  font-size: 30px;
  color: var(--text-color);
  margin-bottom: 30px;
  text-transform: uppercase;
}
@media all and (max-width: 480px) {
  div.paymentScreen div.wrapper h1.heading {
    font-size: 25px;
  }
}
div.paymentScreen div.wrapper div.mainContainer {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
div.paymentScreen div.wrapper div.mainContainer.centerDiv {
  justify-content: center;
}
div.paymentScreen div.wrapper div.mainContainer.modifyDiv {
  justify-content: space-evenly;
}
@media all and (max-width: 980px) {
  div.paymentScreen div.wrapper div.mainContainer {
    flex-direction: column;
    width: 80%;
  }
  div.paymentScreen div.wrapper div.mainContainer.modifyDiv {
    align-items: center;
  }
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer {
    width: 100%;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.left {
  width: 50%;
  border-right: 2px solid var(--border-color);
}
div.paymentScreen div.wrapper div.mainContainer div.left.small {
  width: 33.3%;
}
div.paymentScreen div.wrapper div.mainContainer div.left.no-border {
  border-right: none;
}
@media all and (max-width: 1200px) {
  div.paymentScreen div.wrapper div.mainContainer div.left.small {
    width: 40%;
  }
}
@media all and (max-width: 980px) {
  div.paymentScreen div.wrapper div.mainContainer div.left {
    width: 100%;
  }
  div.paymentScreen div.wrapper div.mainContainer div.left.small {
    width: 90%;
    border-right: none;
    border-bottom: 2px solid var(--border-color);
  }
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer label {
  font-size: 20px;
  display: block;
  color: var(--text-color);
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer {
  margin-top: 20px;
  display: flex;
  align-items: center;
  width: 25rem;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer.small {
  width: 25rem;
}
@media all and (max-width: 1600px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer.small {
    width: 23rem;
  }
}
@media all and (max-width: 1400px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer {
    width: 25rem;
  }
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer.small {
    width: 21rem;
  }
}
@media all and (max-width: 1200px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer {
    width: 24rem;
  }
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer.small {
    width: 18rem;
  }
}
@media all and (max-width: 980px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer {
    width: 22rem;
  }
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer.small {
    width: 90%;
  }
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer {
    width: 90%;
  }
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer.small {
    width: 90%;
  }
}
@media all and (max-width: 480px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer {
    flex-direction: column;
  }
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer.small {
    flex-direction: column;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer input {
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  background: #000;
  text-align: left;
  padding: 20px 15px;
  border: 1px solid transparent;
  caret-color: var(--text-color);
  display: block;
  width: 100%;
  color: var(--text-color);
  margin-right: 20px;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer input:focus {
  border: 1px solid var(--text-color);
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer input {
    padding: 16px 15px;
  }
}
@media all and (max-width: 480px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer input {
    margin-bottom: 20px;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 15rem;
  margin: 0 auto;
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer div.buttonContainer {
    width: 15rem;
    padding: 16px 20px;
  }
}
@media all and (max-width: 480px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer div.buttonContainer {
    width: 10rem;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer div.buttonContainer div.background {
  background: var(--button-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer {
  margin-top: 30px;
  margin-bottom: 20px;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer h2.heading {
  font-size: 20px;
  color: var(--text-color);
  font-weight: 400;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways {
  display: flex;
  flex-wrap: wrap;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li {
  margin: 5px;
  width: 90px;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li:last-child {
  margin-right: 0;
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li {
    width: 70px;
  }
}
@media all and (max-width: 480px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li {
    width: 20%;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li div.imageContainer {
  width: 100%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li div.imageContainer:hover {
  transform: scale(1.1);
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li div.imageContainer img {
  width: 100%;
  display: block;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li div.imageContainer img.ideabiz-icon {
  border-radius: 5px;
}
div.paymentScreen div.wrapper div.mainContainer div.center {
  width: 33.3%;
  padding: 0 40px 0;
  border-right: 2px solid var(--border-color);
}
div.paymentScreen div.wrapper div.mainContainer div.center.small {
  width: 33.3%;
}
div.paymentScreen div.wrapper div.mainContainer div.center.no-border {
  border-right: none;
  display: flex;
  width: 50%;
  justify-content: center;
}
@media all and (max-width: 980px) {
  div.paymentScreen div.wrapper div.mainContainer div.center {
    width: 90%;
    padding: 40px 0 0;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid var(--border-color);
  }
  div.paymentScreen div.wrapper div.mainContainer div.center.small {
    width: 90%;
    border-right: none;
    border-bottom: 2px solid var(--border-color);
  }
  div.paymentScreen div.wrapper div.mainContainer div.center.no-border {
    border-bottom: none;
    width: 90%;
    justify-content: left;
  }
}
@media all and (max-width: 768px) {
  div.paymentScreen div.wrapper div.mainContainer div.center {
    width: 100%;
  }
  div.paymentScreen div.wrapper div.mainContainer div.center.small {
    width: 90%;
    border-right: none;
    border-bottom: 2px solid var(--border-color);
  }
  div.paymentScreen div.wrapper div.mainContainer div.center.no-border {
    border-bottom: none;
    width: 100%;
    justify-content: left;
  }
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer div.center.small {
    width: 90%;
    border-right: none;
    border-bottom: 2px solid var(--border-color);
  }
  div.paymentScreen div.wrapper div.mainContainer div.center.no-border {
    border-bottom: none;
    width: 90%;
    justify-content: left;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.center iframe {
  z-index: 2 !important;
}
div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer {
  margin-bottom: 20px;
}
div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer h2.heading {
  font-size: 20px;
  color: var(--text-color);
  font-weight: 400;
}
div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysCinet {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysCinet li {
  margin-right: 20px;
  width: 250px;
}
div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysCinet li:last-child {
  margin-right: 0;
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysCinet li {
    width: 70px;
  }
}
@media all and (max-width: 480px) {
  div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysCinet li {
    width: 20%;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysCinet li div.cinetImageContainer {
  width: 100%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysCinet li div.cinetImageContainer:hover {
  transform: scale(1.1);
}
div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysCinet li div.cinetImageContainer img {
  width: 100%;
  display: block;
}
div.paymentScreen div.wrapper div.mainContainer div.right {
  width: 50%;
  padding: 0 40px 0;
}
div.paymentScreen div.wrapper div.mainContainer div.right.small {
  width: 33.3%;
}
@media all and (max-width: 980px) {
  div.paymentScreen div.wrapper div.mainContainer div.right {
    width: 90%;
    padding: 40px 0 0;
    border-left: none;
  }
  div.paymentScreen div.wrapper div.mainContainer div.right.small {
    width: 90%;
  }
}
@media all and (max-width: 768px) {
  div.paymentScreen div.wrapper div.mainContainer div.right {
    width: 100%;
  }
  div.paymentScreen div.wrapper div.mainContainer div.right.small {
    width: 90%;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.right iframe {
  z-index: 2 !important;
}

div.ideabiz-containter {
  min-height: 100vh;
}

div.paymentProcessingScreen {
  padding: 100px 0;
  background-color: var(--sub-bg-color);
}
div.paymentProcessingScreen .paypal-button-container {
  min-width: 326px !important;
  max-width: 284px !important;
}
div.paymentProcessingScreen span.success {
  color: var(--success-color);
}
@media all and (max-width: 640px) {
  div.paymentProcessingScreen div.wrapper {
    width: 90%;
  }
}
div.paymentProcessingScreen div.paymentContainer {
  width: 90%;
  height: 50rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

div.ChangePassword {
  padding: 130px 0 120px;
}
@media all and (max-width: 2500px) {
  div.ChangePassword {
    padding: 200px 0;
  }
}
@media all and (max-width: 1768px) {
  div.ChangePassword {
    padding: 160px 0;
  }
}
@media all and (max-width: 1600px) {
  div.ChangePassword {
    padding: 145px 0;
  }
}
div.ChangePassword span.error {
  font-size: 14px;
  color: #E72A31;
  display: block;
  margin-top: 10px;
}
div.ChangePassword span.error.center {
  text-align: center;
}
div.ChangePassword span.success {
  font-size: 14px;
  display: block;
  text-align: center;
  margin-top: 10px;
}
div.ChangePassword h1.heading {
  font-size: 34px;
  padding-bottom: 10px;
  color: var(--text-color);
  text-align: center;
}
@media all and (max-width: 640px) {
  div.ChangePassword h1.heading {
    font-size: 22px;
    margin: 0 20px;
  }
}
div.ChangePassword div.contents {
  margin-top: 40px;
}
@media all and (max-width: 480px) {
  div.ChangePassword div.contents {
    padding: 0 10px;
  }
}
div.ChangePassword div.contents div.formSection {
  width: 30%;
  margin: 0 auto;
}
@media all and (max-width: 1200px) {
  div.ChangePassword div.contents div.formSection {
    width: 55%;
  }
}
@media all and (max-width: 980px) {
  div.ChangePassword div.contents div.formSection {
    width: 75%;
  }
}
@media all and (max-width: 640px) {
  div.ChangePassword div.contents div.formSection {
    width: 85%;
  }
}
div.ChangePassword div.contents div.formSection form div.inputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
div.ChangePassword div.contents div.formSection form div.inputContainer:nth-child(3) {
  margin-bottom: 60px;
}
div.ChangePassword div.contents div.formSection form div.inputContainer label {
  color: var(--inner-text-color);
  font-size: 16px;
  display: inline-block;
  margin-bottom: 10px;
}
div.ChangePassword div.contents div.formSection form div.inputContainer input {
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  background: transparent;
  text-align: left;
  padding: 20px 15px;
  border: 1px solid var(--inner-text-color);
  caret-color: var(--text-color);
  display: block;
  width: 100%;
  color: var(--text-color);
}
div.ChangePassword div.contents div.formSection form div.inputContainer input:focus {
  border: 1px solid var(--text-color);
}
@media all and (max-width: 640px) {
  div.ChangePassword div.contents div.formSection form div.inputContainer input {
    padding: 16px 15px;
  }
}
div.ChangePassword div.contents div.formSection form div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 20rem;
  margin: 30px auto 0;
}
@media all and (max-width: 640px) {
  div.ChangePassword div.contents div.formSection form div.buttonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.ChangePassword div.contents div.formSection form div.buttonContainer {
    width: 10rem;
  }
}
div.ChangePassword div.contents div.formSection form div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.ChangePassword div.contents div.formSection form div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.ChangePassword div.contents div.formSection form div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.searchPage {
  padding: 130px 0 40px;
}
@media all and (max-width: 2500px) {
  div.searchPage {
    padding: 210px 0 40px;
  }
}
@media all and (max-width: 1980px) {
  div.searchPage {
    padding: 160px 0 40px;
  }
}
@media all and (max-width: 1600px) {
  div.searchPage {
    padding: 140px 0 40px;
  }
}
div.searchPage div.top {
  padding-bottom: 60px;
  border-bottom: 1px solid var(--border-color);
}
div.searchPage div.top h1.heading {
  font-size: 46px;
  margin-bottom: 10px;
  color: var(--text-color);
}
@media all and (max-width: 640px) {
  div.searchPage div.top h1.heading {
    font-size: 36px;
  }
}
div.searchPage div.top p.desc {
  font-size: 18px;
  color: var(--inner-text-color);
}
@media all and (max-width: 640px) {
  div.searchPage div.top p.desc {
    font-size: 14px;
  }
}
div.searchPage div.bottom {
  margin-top: 10px;
}
div.searchPage div.bottom div.categories {
  display: flex;
  margin-bottom: 20px;
}
div.searchPage div.bottom div.categories button {
  background-color: transparent;
  outline: none;
  color: var(--text-color);
  padding: 8px 30px;
  border-radius: 30px;
  font-size: 16px;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid transparent;
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.categories button {
    padding: 8px 15px;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.categories button {
    padding: 8px 6px;
    font-size: 13px;
  }
}
@media all and (max-width: 480px) {
  div.searchPage div.bottom div.categories button {
    padding: 8px 8px;
    font-size: 10px;
  }
}
@media all and (max-width: 360px) {
  div.searchPage div.bottom div.categories button {
    padding: 8px 5px;
  }
}
div.searchPage div.bottom div.categories button:last-child {
  margin-right: 0;
}
div.searchPage div.bottom div.categories button.active {
  border: 1px solid var(--grey-color);
  background-color: var(--secondary-btn-hover);
  color: var(--grey-color);
}
div.searchPage div.bottom div.categories button:hover {
  background-color: var(--button-hover);
  color: var(--grey-color);
}
div.searchPage div.bottom div.itemsContainer {
  display: flex;
  flex-wrap: wrap;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount3 {
  width: 32.5%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount3:nth-child(3n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3 {
    width: 32%;
  }
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount4 {
  width: 24%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount4:nth-child(4n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4 {
    width: 23.8%;
  }
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount5 {
  width: 19%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount5:nth-child(5n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5 {
    width: 18.5%;
  }
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount6 {
  width: 15.8%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount6:nth-child(6n) {
  margin-right: 0;
}
@media all and (max-width: 1400px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6 {
    width: 15.5%;
  }
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6 {
    width: 15%;
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.portrait {
    width: 23.5%;
    margin-right: 13px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(5n) {
    margin-right: 13px;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.portrait {
    width: 23%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(5n) {
    margin-right: 13px;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.portrait {
    width: 31%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-right: 13px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.searchPage div.bottom div.itemsContainer div.items.portrait {
    width: 47%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-right: 13px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.landscape {
    width: 32%;
    margin-right: 13px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.landscape {
    width: 47.6%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 13px;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.landscape {
    width: 48%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 13px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.searchPage div.bottom div.itemsContainer div.items.landscape {
    width: 47%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 13px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-right: 0;
  }
}

div.myList {
  padding: 130px 0 40px;
}
@media all and (max-width: 2500px) {
  div.myList {
    padding: 200px 0 40px;
  }
}
@media all and (max-width: 1768px) {
  div.myList {
    padding: 150px 0 40px;
  }
}
@media all and (max-width: 768px) {
  div.myList {
    padding: 120px 0 40px;
  }
}
@media all and (max-width: 360px) {
  div.myList {
    padding: 135px 0 40px;
  }
}
div.myList div.top {
  padding-bottom: 60px;
  border-bottom: 1px solid var(--border-color);
}
div.myList div.top h1.heading {
  font-size: 46px;
  margin-bottom: 10px;
  color: var(--text-color);
}
@media all and (max-width: 640px) {
  div.myList div.top h1.heading {
    font-size: 36px;
  }
}
div.myList div.top p.desc {
  font-size: 18px;
  color: var(--inner-text-color);
}
@media all and (max-width: 640px) {
  div.myList div.top p.desc {
    font-size: 14px;
  }
}
div.myList div.bottom {
  margin-top: 40px;
}
div.myList div.bottom div.itemsContainer {
  display: flex;
  flex-wrap: wrap;
}
div.myList div.bottom div.itemsContainer div.items.rowCount3 {
  width: 32.5%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.myList div.bottom div.itemsContainer div.items.rowCount3:nth-child(3n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3 {
    width: 32%;
  }
}
div.myList div.bottom div.itemsContainer div.items.rowCount4 {
  width: 24%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.myList div.bottom div.itemsContainer div.items.rowCount4:nth-child(4n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4 {
    width: 23.8%;
  }
}
div.myList div.bottom div.itemsContainer div.items.rowCount5 {
  width: 19%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.myList div.bottom div.itemsContainer div.items.rowCount5:nth-child(5n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5 {
    width: 18.5%;
  }
}
div.myList div.bottom div.itemsContainer div.items.rowCount6 {
  width: 15.8%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.myList div.bottom div.itemsContainer div.items.rowCount6:nth-child(6n) {
  margin-right: 0;
}
@media all and (max-width: 1400px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6 {
    width: 15.5%;
  }
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6 {
    width: 15%;
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.portrait {
    width: 23.5%;
    margin-right: 13px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(5n) {
    margin-right: 13px;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.portrait {
    width: 23%;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(5n) {
    margin-right: 13px;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.portrait {
    width: 31%;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-right: 13px;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList div.bottom div.itemsContainer div.items.portrait {
    width: 47%;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-right: 13px;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.landscape {
    width: 32%;
    margin-right: 13px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.landscape {
    width: 47.6%;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 13px;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.landscape {
    width: 48%;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 13px;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList div.bottom div.itemsContainer div.items.landscape {
    width: 47%;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 13px;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-right: 0;
  }
}

div.privacyPolicy {
  padding: 130px 0;
}
@media all and (max-width: 2500px) {
  div.privacyPolicy {
    padding: 200px 0 0 0;
  }
}
@media all and (max-width: 2200px) {
  div.privacyPolicy {
    padding: 220px 0 0 0;
  }
}
@media all and (max-width: 1980px) {
  div.privacyPolicy {
    padding: 170px 0 0 0;
  }
}
@media all and (max-width: 1600px) {
  div.privacyPolicy {
    padding: 145px 0 0 0;
  }
}

div.termsAndConditions {
  padding: 130px 0;
}
@media all and (max-width: 2500px) {
  div.termsAndConditions {
    padding: 185px 0;
  }
}
@media all and (max-width: 1980px) {
  div.termsAndConditions {
    padding: 165px 0;
  }
}
@media all and (max-width: 1600px) {
  div.termsAndConditions {
    padding: 140px 0;
  }
}
@media all and (max-width: 1400px) {
  div.termsAndConditions {
    padding: 135px 0;
  }
}

div.contactUs {
  padding: 130px 0;
}

div.contactSupport {
  padding: 130px 0;
}
@media all and (max-width: 2500px) {
  div.contactSupport {
    padding: 200px 0;
  }
}
@media all and (max-width: 1768px) {
  div.contactSupport {
    padding: 150px 0;
  }
}
div.contactSupport h1.heading {
  font-size: 28px;
  margin-bottom: 30px;
  color: var(--text-color);
  text-align: center;
}
@media all and (max-width: 640px) {
  div.contactSupport h1.heading {
    font-size: 28px;
  }
}
div.contactSupport div.formContainer {
  width: 50%;
  margin: 0 auto;
}
@media all and (max-width: 980px) {
  div.contactSupport div.formContainer {
    width: 100%;
  }
}
div.contactSupport div.formContainer form div.full {
  margin-bottom: 20px;
}
div.contactSupport div.formContainer form div.full input,
div.contactSupport div.formContainer form div.full select {
  color: var(--text-color) !important;
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  background: transparent;
  text-align: left;
  padding: 20px 15px;
  border: 1px solid var(--inner-text-color);
  caret-color: var(--text-color);
  display: block;
  width: 100%;
  color: var(--text-color);
}
div.contactSupport div.formContainer form div.full input option,
div.contactSupport div.formContainer form div.full select option {
  color: #000 !important;
}
div.contactSupport div.formContainer form div.full input:focus,
div.contactSupport div.formContainer form div.full select:focus {
  border: 1px solid var(--text-color);
}
@media all and (max-width: 640px) {
  div.contactSupport div.formContainer form div.full input,
  div.contactSupport div.formContainer form div.full select {
    padding: 16px 15px;
  }
}
div.contactSupport div.formContainer form div.full label {
  color: var(--inner-text-color);
  font-size: 16px;
  display: inline-block;
  margin-bottom: 10px;
}
div.contactSupport div.formContainer form div.full.message {
  display: flex;
  flex-direction: column;
}
div.contactSupport div.formContainer form div.full.message textarea {
  height: 150px;
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  background: transparent;
  caret-color: var(--text-color);
  color: var(--text-color);
  padding: 20px 15px;
}
div.contactSupport div.formContainer form div.full.message textarea:focus {
  border: 1px solid var(--text-color);
}
div.contactSupport div.formContainer form div.full div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 20rem;
  margin: 30px auto 0;
}
@media all and (max-width: 640px) {
  div.contactSupport div.formContainer form div.full div.buttonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.contactSupport div.formContainer form div.full div.buttonContainer {
    width: 10rem;
  }
}
div.contactSupport div.formContainer form div.full div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.contactSupport div.formContainer form div.full div.buttonContainer div.background {
  background: var(--button-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.contactSupport div.formContainer form div.full div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.aboutUs {
  padding: 130px 0;
  background-color: var(--bg-color);
}
@media all and (max-width: 2600px) {
  div.aboutUs {
    padding: 250px 0;
  }
}
@media all and (max-width: 2500px) {
  div.aboutUs {
    padding: 220px 0;
  }
}
div.aboutUs h1 {
  margin-left: 10px;
}
div.aboutUs h1.noData {
  text-align: center;
  color: var(--heading-color);
  margin-left: 10px;
}
@media all and (max-width: 2600px) {
  div.aboutUs {
    padding: 250px 0;
  }
}
@media all and (max-width: 2500px) {
  div.aboutUs {
    padding: 220px 0;
  }
}
@media all and (max-width: 1600px) {
  div.aboutUs {
    padding: 165px 0;
  }
}

div.loading {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--bg-color);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.loading div.loadingOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--bg-color);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.loading div.loadingText {
  display: flex;
  align-items: flex-end;
  z-index: 999;
}
div.loading div.loadingText h1 {
  color: var(--inner-text-color);
  margin-right: 10px;
  font-size: 28px;
}
div.loading div.loadingText span {
  margin-right: 10px;
  color: var(--inner-text-color);
  animation: blinking 0.6s ease-in-out infinite;
}
div.loading div.loadingText span:nth-child(odd) {
  color: var(--text-hover);
}
div.loading div.loadingText span:nth-child(4) {
  animation: blinking 0.7s ease-in-out infinite;
}
div.loading div.loadingText span:last-child {
  margin-right: 0;
}
div.loading .loader {
  width: 8px;
  height: 40px;
  border-radius: 4px;
  display: block;
  margin: 20px auto;
  position: relative;
  background: currentColor;
  color: #fff;
  box-sizing: border-box;
  animation: animloader 0.3s 0.3s linear infinite alternate;
  z-index: 999;
}
div.loading .loader::after, div.loading .loader::before {
  content: "";
  width: 8px;
  height: 40px;
  border-radius: 4px;
  background: currentColor;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  box-sizing: border-box;
  animation: animloader 0.3s 0.45s linear infinite alternate;
}
div.loading .loader::before {
  left: -20px;
  animation-delay: 0s;
}
@keyframes animloader {
  0% {
    height: 48px;
  }
  100% {
    height: 4px;
  }
}

div.newsCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: var(--bg-color);
  transition: 0.4s ease-in-out;
}
div.newsCard:hover {
  background-color: var(--secondary-bg-color);
}
@media all and (max-width: 980px) {
  div.newsCard {
    flex-direction: column;
  }
}
div.newsCard div.left {
  width: 15%;
}
@media all and (max-width: 1200px) {
  div.newsCard div.left {
    width: 50%;
  }
}
@media all and (max-width: 980px) {
  div.newsCard div.left {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.newsCard div.left div.imageContainer {
  width: 100%;
}
div.newsCard div.left div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 8px;
}
div.newsCard div.right {
  width: 80%;
}
@media all and (max-width: 980px) {
  div.newsCard div.right {
    width: 100%;
  }
}
div.newsCard div.right h1.title {
  font-size: 22px;
  margin-bottom: 20px;
  color: var(--text-color);
}
@media all and (max-width: 1200px) {
  div.newsCard div.right h1.title {
    margin-bottom: 10px;
  }
}
@media all and (max-width: 640px) {
  div.newsCard div.right h1.title {
    font-size: 20px;
  }
}
div.newsCard div.right p {
  font-size: 16px;
  color: var(--inner-text-color);
  margin-bottom: 10px;
}
@media all and (max-width: 1200px) {
  div.newsCard div.right p {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
@media all and (max-width: 640px) {
  div.newsCard div.right p {
    font-size: 13px;
  }
}
div.newsCard div.right div.dateSection {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
@media all and (max-width: 1200px) {
  div.newsCard div.right div.dateSection {
    margin-bottom: 10px;
  }
}
div.newsCard div.right div.dateSection span.icon {
  width: 20px;
  display: inline-block;
  margin-right: 10px;
}
div.newsCard div.right div.dateSection span.icon img {
  width: 100%;
  display: block;
}
div.newsCard div.right div.dateSection span.date {
  font-size: 14px;
  color: var(--text-hover);
}
@media all and (max-width: 640px) {
  div.newsCard div.right div.dateSection span.date {
    font-size: 13px;
  }
}
div.newsCard div.right div.buttonContainer {
  display: flex;
  padding: 12px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 15rem;
  margin: 20px 0 0;
}
@media all and (max-width: 768px) {
  div.newsCard div.right div.buttonContainer {
    margin: 20px 0 0;
  }
}
@media all and (max-width: 640px) {
  div.newsCard div.right div.buttonContainer {
    width: 12rem;
  }
}
@media all and (max-width: 480px) {
  div.newsCard div.right div.buttonContainer {
    width: 15rem;
  }
}
div.newsCard div.right div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.newsCard div.right div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.newsCard div.right div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.latestNews {
  padding: 130px 0 80px;
  position: relative;
}
div.latestNews div.head {
  display: flex;
  justify-content: right;
}
@media all and (max-width: 640px) {
  div.latestNews div.head {
    padding-right: 20px;
  }
}
div.latestNews div.head div.logoContainer {
  width: 150px;
}
@media all and (max-width: 640px) {
  div.latestNews div.head div.logoContainer {
    width: 120px;
  }
}
div.latestNews div.head div.logoContainer img {
  display: block;
  width: 100%;
}
div.latestNews div.body h1.heading {
  font-size: 34px;
  color: var(--text-color);
  margin-bottom: 30px;
}
div.latestNews div.body div.gradientBg {
  background: url(./assets/Icons/gradient.svg) repeat-x center bottom;
  background-size: contain;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 200px;
}
div.latestNews div.body div.gradientBg div.buttonContainer {
  text-align: center;
}
div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
  border: 1px solid var(--text-hover);
  background-color: transparent;
  color: var(--text-hover);
  padding: 6px 20px;
  font-size: 14px;
  border-radius: 6px;
  width: 15%;
  transition: 0.3s ease-in-out;
  margin: 140px auto 0;
  cursor: pointer;
}
@media all and (max-width: 1200px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 20%;
  }
}
@media all and (max-width: 980px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 25%;
  }
}
@media all and (max-width: 769px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 30%;
  }
}
@media all and (max-width: 769px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 30%;
  }
}
@media all and (max-width: 640px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 35%;
  }
}
@media all and (max-width: 480px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 45%;
  }
}
@media all and (max-width: 360px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 50%;
  }
}
div.latestNews div.body div.gradientBg div.buttonContainer button.showMore svg {
  transition: 0.3s ease-in-out;
  margin-left: 6px;
}
div.latestNews div.body div.gradientBg div.buttonContainer button.showMore:hover {
  background-color: var(--text-hover);
  color: var(--text-color);
}

div.articleInfo {
  padding: 130px 0 80px;
}
div.articleInfo div.wrapper {
  width: 80%;
}
div.articleInfo div.wrapper h1.heading {
  font-size: 32px;
  color: var(--text-color);
}
@media all and (max-width: 640px) {
  div.articleInfo div.wrapper h1.heading {
    padding-left: 20px;
  }
}
div.articleInfo div.wrapper div.dateSection {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
@media all and (max-width: 640px) {
  div.articleInfo div.wrapper div.dateSection {
    padding-left: 20px;
  }
}
div.articleInfo div.wrapper div.dateSection span.icon {
  width: 20px;
  display: block;
  margin-right: 10px;
}
div.articleInfo div.wrapper div.dateSection span.icon img {
  display: block;
  width: 100%;
}
div.articleInfo div.wrapper div.dateSection span.date {
  font-size: 12px;
  color: var(--text-hover);
}
div.articleInfo div.wrapper div.articleDetails {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 20px auto 0;
}
@media all and (max-width: 980px) {
  div.articleInfo div.wrapper div.articleDetails {
    width: 90%;
  }
}
@media all and (max-width: 768px) {
  div.articleInfo div.wrapper div.articleDetails {
    flex-direction: column;
  }
}
div.articleInfo div.wrapper div.articleDetails div.left {
  width: 100%;
  margin-bottom: 30px;
}
@media all and (max-width: 768px) {
  div.articleInfo div.wrapper div.articleDetails div.left {
    width: 100%;
    margin-bottom: 30px;
  }
}
div.articleInfo div.wrapper div.articleDetails div.left div.imageContainer {
  width: 100%;
  margin: 0 auto;
}
div.articleInfo div.wrapper div.articleDetails div.left div.imageContainer img {
  display: block;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
div.articleInfo div.wrapper div.articleDetails div.right {
  width: 100%;
}
@media all and (max-width: 768px) {
  div.articleInfo div.wrapper div.articleDetails div.right {
    width: 100%;
  }
}
div.articleInfo div.wrapper div.articleDetails div.right p {
  font-size: 15px;
  margin-bottom: 20px;
  color: var(--inner-text-color);
}
div.articleInfo div.wrapper div.articleDetails div.right p:last-child {
  margin-bottom: 0;
}
@media all and (max-width: 980px) {
  div.articleInfo div.wrapper div.articleDetails div.right p {
    font-size: 13px;
  }
}
div.articleInfo div.wrapper div.videoContainer {
  width: 80%;
  margin: 90px auto 0;
}
div.articleInfo div.wrapper div.videoContainer video {
  display: block;
  width: 100%;
}
@media all and (max-width: 980px) {
  div.articleInfo div.wrapper div.videoContainer {
    width: 90%;
  }
}
div.articleInfo div.wrapper div.buttonContainer {
  text-align: center;
  margin-top: 50px;
}
div.articleInfo div.wrapper div.buttonContainer button {
  background-color: transparent;
  border: 1px solid var(--primary-color);
  padding: 6px 10px;
  width: 15%;
  border-radius: 10px;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  transition: 0.4s ease-in-out;
}
@media all and (max-width: 980px) {
  div.articleInfo div.wrapper div.buttonContainer button {
    width: 20%;
    font-size: 14px;
  }
}
@media all and (max-width: 641px) {
  div.articleInfo div.wrapper div.buttonContainer button {
    width: 25%;
  }
}
@media all and (max-width: 480px) {
  div.articleInfo div.wrapper div.buttonContainer button {
    width: 35%;
    padding: 6px 15px;
  }
}
div.articleInfo div.wrapper div.buttonContainer button span {
  color: var(--primary-color);
  margin: 0 10px;
  transition: 0.4s ease-in-out;
}
div.articleInfo div.wrapper div.buttonContainer button svg {
  transition: 0.4s ease-in-out;
}
div.articleInfo div.wrapper div.buttonContainer button:hover {
  border-color: var(--icons-color);
}
div.articleInfo div.wrapper div.buttonContainer button:hover span {
  color: var(--icons-color);
}
div.articleInfo div.wrapper div.buttonContainer button:hover svg {
  fill: var(--icons-color);
}

div.landingScreen {
  padding: 100px 0 0;
}
div.landingScreen h1.heading {
  color: var(--text-color);
  margin-bottom: 10px;
  font-size: 45px;
  font-weight: 500;
}
@media all and (max-width: 1400px) {
  div.landingScreen h1.heading {
    font-size: 38px;
  }
}
@media all and (max-width: 1200px) {
  div.landingScreen h1.heading {
    font-size: 31px;
  }
}
@media all and (max-width: 768px) {
  div.landingScreen h1.heading {
    font-size: 27px;
  }
}
div.landingScreen p.description {
  color: var(--text-color);
  margin-bottom: 10px;
  font-size: 23px;
}
@media all and (max-width: 1400px) {
  div.landingScreen p.description {
    font-size: 19px;
  }
}
@media all and (max-width: 1200px) {
  div.landingScreen p.description {
    font-size: 17px;
  }
}
div.landingScreen div.buttonContainer {
  display: flex;
  padding: 12px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 15rem;
}
@media all and (max-width: 768px) {
  div.landingScreen div.buttonContainer {
    margin: 20px 0 0;
    width: 13rem;
  }
}
@media all and (max-width: 640px) {
  div.landingScreen div.buttonContainer {
    width: 12rem;
    margin: 0 auto;
  }
}
@media all and (max-width: 480px) {
  div.landingScreen div.buttonContainer {
    width: 15rem;
  }
}
div.landingScreen div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.landingScreen div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.landingScreen div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
@media all and (max-width: 768px) {
  div.landingScreen div.buttonContainer button {
    font-size: 13px;
  }
}
div.landingScreen div.spotlight {
  display: flex;
  align-items: center;
  background-color: var(--black-color);
  height: 500px;
}
@media all and (max-width: 640px) {
  div.landingScreen div.spotlight {
    flex-direction: column-reverse;
    height: 269px;
  }
}
div.landingScreen div.spotlight.rightType {
  position: relative;
}
div.landingScreen div.spotlight.rightType div.left {
  width: 45%;
  position: absolute;
  left: 7%;
  z-index: 3;
  height: 100%;
  padding-top: 120px;
  background: linear-gradient(to right, #000 80%, transparent 100%);
}
@media all and (max-width: 640px) {
  div.landingScreen div.spotlight.rightType div.left {
    background: linear-gradient(to top, #000 96%, transparent 100%);
    left: unset;
    width: 100%;
    text-align: center;
    padding-top: 40px;
    bottom: -44%;
  }
}
div.landingScreen div.spotlight.rightType div.right {
  width: 60%;
  position: absolute;
  right: 0;
  height: 100%;
  z-index: 1;
}
@media all and (max-width: 640px) {
  div.landingScreen div.spotlight.rightType div.right {
    left: unset;
    top: 0;
    width: 100%;
    height: 52%;
  }
}
div.landingScreen div.spotlight.rightType div.right div.imageContainer {
  width: 100%;
  height: 100%;
}
div.landingScreen div.spotlight.rightType div.right div.imageContainer img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
div.landingScreen div.watchNowSection {
  display: flex;
  color: var(--black-color);
  align-items: center;
  background-color: var(--black-color);
  position: relative;
  height: 500px;
}
@media all and (max-width: 640px) {
  div.landingScreen div.watchNowSection {
    flex-direction: column;
    height: 269px;
    margin-top: 99px;
  }
}
div.landingScreen div.watchNowSection div.left {
  width: 60%;
  height: 100%;
  position: absolute;
}
@media all and (max-width: 640px) {
  div.landingScreen div.watchNowSection div.left {
    left: unset;
    top: 0;
    width: 100%;
    height: 55%;
  }
}
div.landingScreen div.watchNowSection div.left div.imageContainer {
  height: 100%;
  width: 100%;
}
div.landingScreen div.watchNowSection div.left div.imageContainer img {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  display: block;
  width: 100%;
}
div.landingScreen div.watchNowSection div.right {
  width: 50%;
  position: absolute;
  right: 0;
  z-index: 3;
  height: 100%;
  padding-top: 120px;
  background: linear-gradient(to left, #000 87%, transparent 100%);
}
@media all and (max-width: 640px) {
  div.landingScreen div.watchNowSection div.right {
    background: linear-gradient(to top, #000 96%, transparent 100%);
    right: unset;
    width: 100%;
    text-align: center;
    padding-top: 40px;
    bottom: -47%;
  }
}
div.landingScreen div.watchNowSection div.right h1,
div.landingScreen div.watchNowSection div.right p,
div.landingScreen div.watchNowSection div.right div.buttonContainer {
  margin-left: 80px;
}
@media all and (max-width: 640px) {
  div.landingScreen div.watchNowSection div.right h1,
  div.landingScreen div.watchNowSection div.right p,
  div.landingScreen div.watchNowSection div.right div.buttonContainer {
    margin-left: 0;
  }
}
@media all and (max-width: 640px) {
  div.landingScreen div.watchNowSection div.right div.buttonContainer {
    margin: 0 auto;
  }
}
div.landingScreen div.watchNowSection div.right p {
  font-size: 20px;
}
@media all and (max-width: 1400px) {
  div.landingScreen div.watchNowSection div.right p {
    font-size: 19px;
  }
}
@media all and (max-width: 1200px) {
  div.landingScreen div.watchNowSection div.right p {
    font-size: 17px;
  }
}
div.landingScreen div.other.rightType {
  display: flex;
  align-items: center;
  background-color: var(--black-color);
  position: relative;
  height: 500px;
}
@media all and (max-width: 640px) {
  div.landingScreen div.other.rightType {
    flex-direction: column-reverse;
    height: 269px;
  }
}
div.landingScreen div.other.rightType div.left {
  width: 45%;
  position: absolute;
  left: 7%;
  z-index: 3;
  height: 100%;
  padding-top: 120px;
  background: linear-gradient(to right, #000 80%, transparent 100%);
}
@media all and (max-width: 640px) {
  div.landingScreen div.other.rightType div.left {
    background: linear-gradient(to top, #000 96%, transparent 100%);
    left: unset;
    width: 100%;
    text-align: center;
    padding-top: 40px;
    bottom: -91%;
  }
}
div.landingScreen div.other.rightType div.right {
  width: 60%;
  position: absolute;
  right: 0;
  height: 100%;
  z-index: 1;
}
@media all and (max-width: 640px) {
  div.landingScreen div.other.rightType div.right {
    left: unset;
    top: 129px;
    width: 100%;
    height: 52%;
  }
}
div.landingScreen div.other.rightType div.right div.imageContainer {
  width: 100%;
  height: 100%;
}
div.landingScreen div.other.rightType div.right div.imageContainer img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
div.landingScreen div.device {
  display: flex;
  background: var(--text-color);
  padding-top: 30px;
  justify-content: space-between;
  padding-bottom: 100px;
}
@media all and (max-width: 640px) {
  div.landingScreen div.device {
    margin-top: 245px;
    flex-direction: column;
  }
}
div.landingScreen div.device div.left {
  width: 40%;
  padding-left: 60px;
}
@media all and (max-width: 768px) {
  div.landingScreen div.device div.left {
    padding-left: 30px;
  }
}
@media all and (max-width: 640px) {
  div.landingScreen div.device div.left {
    width: 100%;
    margin-bottom: 30px;
  }
}
div.landingScreen div.device div.left h1.heading {
  color: var(--black-color);
}
div.landingScreen div.device div.left p.description {
  color: var(--inner-text-color);
}
div.landingScreen div.device div.right {
  width: 40%;
}
@media all and (max-width: 1980px) {
  div.landingScreen div.device div.right {
    width: 43%;
  }
}
@media all and (max-width: 1600px) {
  div.landingScreen div.device div.right {
    width: 46%;
  }
}
@media all and (max-width: 1400px) {
  div.landingScreen div.device div.right {
    width: 55%;
  }
}
@media all and (max-width: 1200px) {
  div.landingScreen div.device div.right {
    width: 46%;
  }
}
@media all and (max-width: 980px) {
  div.landingScreen div.device div.right {
    width: 55%;
  }
}
@media all and (max-width: 768px) {
  div.landingScreen div.device div.right {
    width: 56%;
  }
}
@media all and (max-width: 640px) {
  div.landingScreen div.device div.right {
    width: 91%;
    margin: 0 auto;
  }
}
div.landingScreen div.device div.right ul.devices {
  display: flex;
  flex-wrap: wrap;
}
div.landingScreen div.device div.right ul.devices li {
  cursor: pointer;
  width: 17rem;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  margin-bottom: 6px;
  background: rgb(54, 124, 160);
  background: linear-gradient(90deg, rgb(54, 124, 160) 0%, rgb(14, 64, 115) 35%, rgb(0, 41, 97) 100%);
  transition: 0.2s ease-in-out;
}
div.landingScreen div.device div.right ul.devices li:nth-child(3n) {
  margin-right: 0;
}
@media all and (max-width: 2200px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 15rem;
  }
}
@media all and (max-width: 1980px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 13rem;
    height: 9rem;
  }
}
@media all and (max-width: 1200px) {
  div.landingScreen div.device div.right ul.devices li:nth-child(3n) {
    margin-right: 6px;
  }
  div.landingScreen div.device div.right ul.devices li:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 980px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 12rem;
    height: 7rem;
  }
}
@media all and (max-width: 768px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 10rem;
  }
}
@media all and (max-width: 640px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 13rem;
  }
}
@media all and (max-width: 480px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 10rem;
  }
}
@media all and (max-width: 360px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 100%;
    margin-right: 0;
  }
  div.landingScreen div.device div.right ul.devices li:nth-child(3n) {
    margin-right: 0;
  }
}
div.landingScreen div.device div.right ul.devices li:hover {
  box-shadow: 0px 1px 6px 4px #333;
}
div.landingScreen div.device div.right ul.devices li span {
  color: var(--text-color);
  font-weight: 700;
  font-size: 30px;
}
@media all and (max-width: 980px) {
  div.landingScreen div.device div.right ul.devices li span {
    font-size: 24px;
  }
}

div.notFound {
  padding: 60px 0;
}
@media all and (max-width: 768px) {
  div.notFound {
    padding: 80px 0;
  }
}
@media all and (max-width: 480px) {
  div.notFound {
    padding: 100px 0;
  }
}
div.notFound div.imageContainer {
  width: 40%;
  margin: 0 auto;
}
@media all and (max-width: 768px) {
  div.notFound div.imageContainer {
    width: 50%;
  }
}
@media all and (max-width: 480px) {
  div.notFound div.imageContainer {
    width: 60%;
  }
}
div.notFound div.imageContainer img {
  width: 100%;
  display: block;
}
div.notFound div.content {
  text-align: center;
}
div.notFound div.content h1 {
  font-size: 80px;
  color: var(--text-hover);
  line-height: 0.8em;
}
@media all and (max-width: 768px) {
  div.notFound div.content h1 {
    font-size: 70px;
  }
}
@media all and (max-width: 480px) {
  div.notFound div.content h1 {
    font-size: 60px;
  }
}
div.notFound div.content p {
  font-size: 25px;
  color: var(--inner-text-color);
}
@media all and (max-width: 768px) {
  div.notFound div.content p {
    font-size: 20px;
  }
}
@media all and (max-width: 480px) {
  div.notFound div.content p {
    font-size: 16px;
  }
}
@media all and (max-width: 360px) {
  div.notFound div.content p {
    font-size: 14px;
  }
}
div.notFound div.content div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 20rem;
  margin: 30px auto 0;
}
@media all and (max-width: 640px) {
  div.notFound div.content div.buttonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.notFound div.content div.buttonContainer {
    width: 10rem;
  }
}
div.notFound div.content div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.notFound div.content div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.notFound div.content div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.tvAppIndex {
  padding: 100px 0;
}
div.tvAppIndex div.tvActivationReverse div.topHeader {
  padding: 20px 10px;
  border-bottom: 1px solid var(--border-color);
}
div.tvAppIndex div.tvActivationReverse div.topHeader h1 {
  font-size: 22px;
  color: var(--text-color);
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer {
  padding: 50px 0 20px;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer h2 {
  color: var(--text-color);
  font-size: 18px;
  margin-bottom: 20px;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer p {
  font-size: 16px;
  color: var(--text-color);
  margin-bottom: 15px;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer form label {
  color: var(--text-color);
  font-size: 15px;
  display: block;
  margin-bottom: 10px;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer form input {
  display: block;
  outline: none;
  border: none;
  padding: 15px 15px;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 30%;
}
@media all and (max-width: 640px) {
  div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer form input {
    width: 100%;
  }
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer form button {
  width: 8rem;
  padding: 6px 1px 9px;
  border-radius: 2px;
  background-color: var(--secondary-color);
  color: var(--white-color);
  margin-right: 10px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  font-size: 13px;
  margin-bottom: 10px;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer form button:hover {
  background-color: var(--secondary-hover-color);
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer p.terms {
  font-size: 15px;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer p.terms span {
  color: rgb(63, 131, 242);
  cursor: pointer;
}
div.tvAppIndex div.linkTvApp {
  padding: 130px 0;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer {
  width: 35%;
  margin: 0 auto;
  background-color: #22293b;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  text-align: center;
  border-radius: 10px;
  padding: 50px 60px;
}
@media all and (max-width: 1200px) {
  div.tvAppIndex div.linkTvApp div.authCodeContainer {
    width: 40%;
  }
}
@media all and (max-width: 980px) {
  div.tvAppIndex div.linkTvApp div.authCodeContainer {
    width: 50%;
  }
}
@media all and (max-width: 768px) {
  div.tvAppIndex div.linkTvApp div.authCodeContainer {
    width: 60%;
  }
}
@media all and (max-width: 640px) {
  div.tvAppIndex div.linkTvApp div.authCodeContainer {
    width: 75%;
  }
}
@media all and (max-width: 480px) {
  div.tvAppIndex div.linkTvApp div.authCodeContainer {
    width: 90%;
    padding: 40px 40px;
  }
}
div.tvAppIndex div.linkTvApp div.authCodeContainer h1.heading {
  color: var(--text-color);
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 8px;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer p {
  color: var(--white-color);
  font-size: 14px;
  margin-bottom: 30px;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer span.loading {
  display: block;
  color: var(--success-color);
  margin-bottom: 20px;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer div.authCodeField {
  border: 1px solid var(--success-color);
  padding: 10px;
  font-size: 24px;
  color: var(--success-color);
  letter-spacing: 10px;
  font-weight: 700;
  margin-bottom: 15px;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer div.authCodeField.expired {
  border: 1px solid var(--error-color);
  color: var(--error-color);
}
div.tvAppIndex div.linkTvApp div.authCodeContainer div.codeExpired span {
  color: #fff;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer div.codeExpired div.regenerateButton {
  margin-top: 10px;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer div.codeExpired div.regenerateButton button {
  background-image: linear-gradient(0deg, #2175d9, #2789ff);
  width: 12rem;
  border-radius: 4px;
  color: #fff;
  padding: 10px;
  font-size: 13px;
  line-height: 1.2;
  letter-spacing: 0.29px;
  font-weight: 500;
  cursor: pointer;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer span.timer {
  color: #fff;
  font-size: 16px;
}

div.livePlayer {
  padding: 130px 0;
}
@media all and (max-width: 2500px) {
  div.livePlayer {
    padding: 200px 0 0 0;
  }
}
@media all and (max-width: 1980px) {
  div.livePlayer {
    padding: 165px 0 0 0;
  }
}
@media all and (max-width: 980px) {
  div.livePlayer {
    padding: 150px 0 0 0;
  }
}
div.livePlayer div.videoContainer {
  height: 100vh;
}
div.livePlayer div.videoContainer .video-js {
  padding-top: 0 !important;
  height: 100vh !important;
  width: 100% !important;
}
div.livePlayer div.videoContainer video {
  display: block;
  height: 100%;
}

div.podcastModal .rhap_container {
  background-color: #303030 !important;
  border-radius: 5px;
}
div.podcastModal .rhap_time {
  color: #a29b9b !important;
}
div.podcastModal div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.podcastModal div.closeIconContainer {
  position: fixed;
  top: 20px;
  right: 30px;
  z-index: 9999999;
  cursor: pointer;
}
div.podcastModal div.closeIconContainer svg {
  width: 20px !important;
  height: 20px !important;
}
div.podcastModal div.contents {
  z-index: 99999999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1400px) {
  div.podcastModal div.contents {
    width: 30%;
  }
}
@media all and (max-width: 1200px) {
  div.podcastModal div.contents {
    width: 60%;
  }
}
@media all and (max-width: 768px) {
  div.podcastModal div.contents {
    width: 75%;
  }
}
@media all and (max-width: 480px) {
  div.podcastModal div.contents {
    width: 90%;
  }
}
div.podcastModal div.contents div.podcastContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}
div.podcastModal div.contents div.podcastContainer div.left {
  width: 100%;
}
@media all and (max-width: 980px) {
  div.podcastModal div.contents div.podcastContainer div.left {
    width: 100%;
    margin-right: 0;
  }
}
div.podcastModal div.contents div.podcastContainer div.left div.imageContainer {
  width: 100%;
}
div.podcastModal div.contents div.podcastContainer div.left div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 5px;
}
div.podcastModal div.contents div.podcastContainer div.right {
  width: 100%;
}
@media all and (max-width: 980px) {
  div.podcastModal div.contents div.podcastContainer div.right {
    width: 100%;
  }
}
div.podcastModal div.contents div.podcastContainer div.right h1.title {
  color: var(--text-color);
  font-size: 20px;
  margin-bottom: 10px;
}
@media all and (max-width: 640px) {
  div.podcastModal div.contents div.podcastContainer div.right div.audioContainer {
    width: 100%;
  }
}
div.podcastModal div.contents div.podcastContainer div.right div.audioContainer audio {
  width: 100%;
  display: block;
}

div.emailForm div.MuiPaper-root {
  background-color: var(--bg-color);
  padding: 30px;
  width: 33% !important;
}
@media all and (max-width: 1400px) {
  div.emailForm div.MuiPaper-root {
    width: 38% !important;
  }
}
@media all and (max-width: 1200px) {
  div.emailForm div.MuiPaper-root {
    width: 45% !important;
  }
}
@media all and (max-width: 980px) {
  div.emailForm div.MuiPaper-root {
    width: 55% !important;
  }
}
@media all and (max-width: 768px) {
  div.emailForm div.MuiPaper-root {
    width: 65% !important;
  }
}
@media all and (max-width: 640px) {
  div.emailForm div.MuiPaper-root {
    width: 85% !important;
  }
}
@media all and (max-width: 480px) {
  div.emailForm div.MuiPaper-root {
    width: 100% !important;
    padding: 10px 0;
    margin: 20px !important;
  }
}
div.emailForm div.MuiPaper-root h1 {
  width: 110px;
  margin: 0 auto 10px;
}
div.emailForm div.MuiPaper-root h1 img {
  display: block;
  width: 100%;
}
div.emailForm div.MuiPaper-root h2 {
  text-align: center;
  color: var(--text-color);
}
div.emailForm div.MuiPaper-root form label {
  display: block;
  color: var(--text-color);
  font-size: 14px;
  margin-bottom: 6px;
}
div.emailForm div.MuiPaper-root form input {
  display: block;
  padding: 12px 20px;
  outline: none;
  border: none;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
}
div.emailForm div.MuiPaper-root form div.buttonContainer {
  display: flex;
  padding: 15px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
@media all and (max-width: 480px) {
  div.emailForm div.MuiPaper-root form div.buttonContainer {
    padding: 10px 1px;
  }
}
div.emailForm div.MuiPaper-root form div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.emailForm div.MuiPaper-root form div.buttonContainer div.background {
  background: var(--button-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}
div.emailForm div.MuiPaper-root form div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  z-index: 9;
  cursor: pointer;
}

div.verifyEmailModal div.MuiPaper-root {
  background-color: var(--bg-color);
  padding: 20px;
  width: 33%;
}
@media all and (max-width: 1400px) {
  div.verifyEmailModal div.MuiPaper-root {
    width: 38%;
  }
}
@media all and (max-width: 1200px) {
  div.verifyEmailModal div.MuiPaper-root {
    width: 45%;
  }
}
@media all and (max-width: 980px) {
  div.verifyEmailModal div.MuiPaper-root {
    width: 55%;
  }
}
@media all and (max-width: 768px) {
  div.verifyEmailModal div.MuiPaper-root {
    width: 65%;
  }
}
@media all and (max-width: 640px) {
  div.verifyEmailModal div.MuiPaper-root {
    width: 85%;
  }
}
@media all and (max-width: 480px) {
  div.verifyEmailModal div.MuiPaper-root {
    width: 95%;
    padding: 0;
    margin: 20px !important;
  }
}
div.verifyEmailModal div.MuiPaper-root div.top {
  text-align: center;
}
div.verifyEmailModal div.MuiPaper-root div.top svg {
  width: 70px !important;
  height: 70px !important;
}
@media all and (max-width: 480px) {
  div.verifyEmailModal div.MuiPaper-root div.top svg {
    width: 50px !important;
    height: 50px !important;
  }
}
div.verifyEmailModal div.MuiPaper-root div.bottom {
  color: var(--text-color);
  text-align: center;
}
div.verifyEmailModal div.MuiPaper-root div.bottom div.heading h1 {
  font-size: 22px;
  margin-bottom: 10px;
}
@media all and (max-width: 480px) {
  div.verifyEmailModal div.MuiPaper-root div.bottom div.heading h1 {
    font-size: 18px;
  }
}
div.verifyEmailModal div.MuiPaper-root div.bottom p {
  font-size: 15px;
}
@media all and (max-width: 480px) {
  div.verifyEmailModal div.MuiPaper-root div.bottom p {
    font-size: 14px;
  }
}
div.verifyEmailModal div.MuiPaper-root div.bottom p span {
  color: rgb(100, 180, 241);
}
@media all and (max-width: 480px) {
  div.verifyEmailModal div.MuiPaper-root div.bottom p span {
    font-size: 14px;
  }
}

div.loginRequestModal {
  text-align: center;
}
div.loginRequestModal div.MuiPaper-root {
  background-color: var(--bg-color);
  padding: 30px;
  width: 33%;
}
@media all and (max-width: 1400px) {
  div.loginRequestModal div.MuiPaper-root {
    width: 38%;
  }
}
@media all and (max-width: 1200px) {
  div.loginRequestModal div.MuiPaper-root {
    width: 45%;
  }
}
@media all and (max-width: 980px) {
  div.loginRequestModal div.MuiPaper-root {
    width: 55%;
  }
}
@media all and (max-width: 768px) {
  div.loginRequestModal div.MuiPaper-root {
    width: 65%;
  }
}
@media all and (max-width: 640px) {
  div.loginRequestModal div.MuiPaper-root {
    width: 85%;
  }
}
@media all and (max-width: 480px) {
  div.loginRequestModal div.MuiPaper-root {
    width: 95%;
    padding: 0;
    margin: 20px !important;
  }
}
div.loginRequestModal div.MuiPaper-root span.MuiSkeleton-root {
  background-color: rgba(255, 255, 255, 0.11) !important;
}
div.loginRequestModal div.MuiPaper-root span.MuiSkeleton-root::after {
  background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.24), transparent) !important;
}
div.loginRequestModal h4 {
  font-size: 17px;
  color: var(--text-color);
  margin-bottom: 8px;
}
div.loginRequestModal div.requestDetails {
  background-color: var(--secondary-bg-color);
  padding: 15px 70px 15px 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}
@media all and (max-width: 480px) {
  div.loginRequestModal div.requestDetails {
    padding: 15px 13px 15px 10px;
  }
}
div.loginRequestModal div.requestDetails div.oneRow {
  display: flex;
  justify-content: space-between;
}
div.loginRequestModal div.requestDetails div.oneRow span {
  color: var(--text-color);
  margin-bottom: 8px;
  font-size: 15px;
}
div.loginRequestModal div.requestDetails div.oneRow span.label {
  font-style: italic;
}
div.loginRequestModal p {
  color: var(--text-color);
  margin-bottom: 10px;
  font-size: 14px;
}
div.loginRequestModal div.buttonContainer {
  display: flex;
  padding: 15px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
@media all and (max-width: 480px) {
  div.loginRequestModal div.buttonContainer {
    padding: 10px 1px;
  }
}
div.loginRequestModal div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.loginRequestModal div.buttonContainer div.background {
  background: var(--button-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}
div.loginRequestModal div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  z-index: 9;
  cursor: pointer;
}

div.appLoginModal div.MuiPaper-root {
  background-color: var(--bg-color);
  padding: 20px;
  width: 33% !important;
}
@media all and (max-width: 1400px) {
  div.appLoginModal div.MuiPaper-root {
    width: 38% !important;
  }
}
@media all and (max-width: 1200px) {
  div.appLoginModal div.MuiPaper-root {
    width: 45% !important;
  }
}
@media all and (max-width: 980px) {
  div.appLoginModal div.MuiPaper-root {
    width: 55% !important;
  }
}
@media all and (max-width: 768px) {
  div.appLoginModal div.MuiPaper-root {
    width: 65% !important;
  }
}
@media all and (max-width: 640px) {
  div.appLoginModal div.MuiPaper-root {
    width: 85% !important;
  }
}
@media all and (max-width: 480px) {
  div.appLoginModal div.MuiPaper-root {
    width: 95% !important;
    padding: 0;
    margin: 20px !important;
  }
}
div.appLoginModal div.MuiPaper-root p {
  color: var(--text-color);
  text-align: center;
  font-size: 16px;
  margin-bottom: 10px;
}
@media all and (max-width: 480px) {
  div.appLoginModal div.MuiPaper-root p {
    font-size: 14px;
  }
}
div.appLoginModal div.MuiPaper-root div.buttonContainer {
  display: flex;
  padding: 15px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
@media all and (max-width: 480px) {
  div.appLoginModal div.MuiPaper-root div.buttonContainer {
    padding: 10px 1px;
  }
}
div.appLoginModal div.MuiPaper-root div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.appLoginModal div.MuiPaper-root div.buttonContainer div.background {
  background: var(--button-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}
div.appLoginModal div.MuiPaper-root div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  z-index: 9;
  cursor: pointer;
}
div.appLoginModal div.MuiPaper-root span {
  color: var(--text-color);
  text-align: center;
  font-size: 14px;
  display: block;
}

div.relet_video_container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  cursor: pointer;
}
@media all and (max-width: 2500px) {
  div.relet_video_container {
    width: 100%;
  }
}
@media all and (max-width: 640px) {
  div.relet_video_container {
    width: 100%;
  }
}
div.relet_video_container div.video_image_container {
  width: 100%;
}
@media all and (max-width: 768px) {
  div.relet_video_container div.video_image_container {
    width: 60%;
  }
}
div.relet_video_container div.video_image_container img {
  width: 100%;
  border-radius: 10px;
}
div.relet_video_container div.videoCard__info {
  width: 100%;
  margin-left: 6px;
}
div.relet_video_container div.videoCard__info div.videoCard__text {
  margin-left: 10px;
  overflow: hidden;
}
@media all and (max-width: 1600px) {
  div.relet_video_container div.videoCard__info div.videoCard__text {
    margin-left: 5px;
  }
}
@media all and (max-width: 640px) {
  div.relet_video_container div.videoCard__info div.videoCard__text {
    font-size: 15px;
  }
}
div.relet_video_container div.videoCard__info div.videoCard__text h4 {
  font-size: 12px;
  font-family: sans-serif;
  color: var(--text-color);
  font-weight: 600;
  margin-bottom: 50px;
}
@media all and (max-width: 2500px) {
  div.relet_video_container div.videoCard__info div.videoCard__text h4 {
    font-size: 19px;
  }
}
@media all and (max-width: 2200px) {
  div.relet_video_container div.videoCard__info div.videoCard__text h4 {
    font-size: 17px;
    margin-bottom: 40px;
  }
}
@media all and (max-width: 1980px) {
  div.relet_video_container div.videoCard__info div.videoCard__text h4 {
    font-size: 15px;
  }
}
@media all and (max-width: 1768px) {
  div.relet_video_container div.videoCard__info div.videoCard__text h4 {
    font-size: 13px;
    margin-bottom: 30px;
  }
}
@media all and (max-width: 1600px) {
  div.relet_video_container div.videoCard__info div.videoCard__text h4 {
    font-size: 12px;
    margin-bottom: 25px;
  }
}
@media all and (max-width: 1400px) {
  div.relet_video_container div.videoCard__info div.videoCard__text h4 {
    font-size: 12px;
    margin-bottom: 10px;
  }
}
@media all and (max-width: 1200px) {
  div.relet_video_container div.videoCard__info div.videoCard__text h4 {
    font-size: 12px;
    margin-bottom: 5px;
  }
}
@media all and (max-width: 980px) {
  div.relet_video_container div.videoCard__info div.videoCard__text h4 {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
@media all and (max-width: 768px) {
  div.relet_video_container div.videoCard__info div.videoCard__text h4 {
    font-size: 15px;
    margin-bottom: 5px;
  }
}
@media all and (max-width: 640px) {
  div.relet_video_container div.videoCard__info div.videoCard__text h4 {
    font-size: 15px;
    margin-bottom: 30px;
  }
}
@media all and (max-width: 480px) {
  div.relet_video_container div.videoCard__info div.videoCard__text h4 {
    font-size: 11px;
    margin-bottom: 20px;
  }
}
@media all and (max-width: 360px) {
  div.relet_video_container div.videoCard__info div.videoCard__text h4 {
    font-size: 10px;
    margin-bottom: 15px;
  }
}
div.relet_video_container div.videoCard__info div.videoCard__text p {
  font-size: 13px;
  color: gray;
  font-weight: 400;
}
@media all and (max-width: 2500px) {
  div.relet_video_container div.videoCard__info div.videoCard__text p {
    font-size: 19px;
  }
}
@media all and (max-width: 1980px) {
  div.relet_video_container div.videoCard__info div.videoCard__text p {
    font-size: 14px;
  }
}
@media all and (max-width: 1400px) {
  div.relet_video_container div.videoCard__info div.videoCard__text p {
    font-size: 12px;
  }
}
@media all and (max-width: 1200px) {
  div.relet_video_container div.videoCard__info div.videoCard__text p {
    font-size: 10px;
  }
}
@media all and (max-width: 980px) {
  div.relet_video_container div.videoCard__info div.videoCard__text p {
    font-size: 13px;
  }
}
@media all and (max-width: 768px) {
  div.relet_video_container div.videoCard__info div.videoCard__text p {
    font-size: 13px;
  }
}
@media all and (max-width: 640px) {
  div.relet_video_container div.videoCard__info div.videoCard__text p {
    font-size: 13px;
  }
}
div.relet_video_container div.videoCard__info div.videoCard__text div.add_remove_icon {
  display: flex;
  margin-left: 0px;
  color: gray;
  font-size: 11px;
}
div.relet_video_container div.videoCard__info div.videoCard__text div.add_remove_icon div.addToList {
  display: flex;
  align-items: center;
}
@media all and (max-width: 768px) {
  div.relet_video_container div.videoCard__info div.videoCard__text div.add_remove_icon div.addToList {
    font-size: 13px;
  }
}

div.menuBodyContainer {
  width: 100%;
}
div.menuBodyContainer div.menubarContainer {
  padding: 13px 10px;
  color: var(--text-color);
  font-family: sans-serif;
  font-size: 12px;
  position: fixed;
  width: 15%;
  z-index: 99;
  bottom: 0;
  top: 12%;
  background-color: var(--bg-color);
  animation: sidebar-slide-in 0.5s ease-in-out;
  animation-fill-mode: both;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
@media all and (max-width: 2500px) {
  div.menuBodyContainer div.menubarContainer {
    width: 13%;
  }
}
@media all and (max-width: 1600px) {
  div.menuBodyContainer div.menubarContainer {
    width: 15%;
  }
}
@media all and (max-width: 768px) {
  div.menuBodyContainer div.menubarContainer {
    width: 17%;
  }
}
@media all and (max-width: 640px) {
  div.menuBodyContainer div.menubarContainer {
    width: 25%;
  }
}
@keyframes sidebar-slide-in {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes sidebar-slide-out {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
div.menuBodyContainer div.menubarContainer div.menuIcon {
  align-items: center;
  font-size: 13px;
  display: flex;
  background-color: var(--bg-color);
  height: 50px;
  margin-top: 10px;
  justify-content: center;
}
@media all and (max-width: 2500px) {
  div.menuBodyContainer div.menubarContainer div.menuIcon {
    height: 62px;
    justify-content: center;
  }
}
div.menuBodyContainer div.menubarContainer div.menuIcon:hover {
  background-color: var(--grey-color);
  border-radius: 10px;
}
div.menuBodyContainer div.menubarContainer div.menuIcon:hover svg {
  fill: var(--black-color);
}
div.menuBodyContainer div.menubarContainer div.menuIcon:hover li {
  color: var(--black-color);
}
div.menuBodyContainer div.menubarContainer div.menuIcon svg {
  width: 25px;
  height: 25px;
  fill: white;
}
@media all and (max-width: 360px) {
  div.menuBodyContainer div.menubarContainer div.menuIcon svg {
    width: 20px;
  }
}
div.menuBodyContainer div.menubarContainer div.menuIcon li {
  font-size: 13px;
  font-weight: 100;
  margin-left: 10%;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
div.menuBodyContainer div.menubarContainer.active {
  align-items: center;
  width: 100%;
}
div.menuBodyContainer div.overlay {
  position: fixed;
  top: -100%;
  bottom: 0;
  right: 0;
  left: 100%;
  width: 1000%;
  background-color: transparent;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease-in-out;
}

div.menubarMinContainer {
  padding: 10px 10px;
  color: var(--text-color);
  font-family: sans-serif;
  font-size: 12px;
  width: 5%;
  z-index: 1;
  bottom: 0;
  top: 12%;
  position: fixed !important;
  background-color: var(--bg-color);
  justify-content: center;
  border-right: 2px solid var(--border-color);
}
@media all and (max-width: 2500px) {
  div.menubarMinContainer {
    top: 15%;
  }
}
@media all and (max-width: 1400px) {
  div.menubarMinContainer {
    width: 6%;
  }
}
@media all and (max-width: 1200px) {
  div.menubarMinContainer {
    width: 7%;
  }
}
@media all and (max-width: 980px) {
  div.menubarMinContainer {
    width: 9%;
  }
}
@media all and (max-width: 768px) {
  div.menubarMinContainer {
    width: 10%;
  }
}
@media all and (max-width: 640px) {
  div.menubarMinContainer {
    display: none;
    border: none;
  }
}
div.menubarMinContainer div.menuIconMin {
  align-items: center;
  font-size: 13px;
  display: flex;
  background-color: var(--bg-color);
  height: 50px;
  margin-top: 10px;
  justify-content: center;
}
@media all and (max-width: 2500px) {
  div.menubarMinContainer div.menuIconMin {
    height: 62px;
    justify-content: center;
  }
}
div.menubarMinContainer div.menuIconMin:hover {
  background-color: var(--grey-color);
  border-radius: 10px;
  width: 100%;
}
div.menubarMinContainer div.menuIconMin:hover svg {
  fill: var(--black-color);
}
div.menubarMinContainer div.menuIconMin svg {
  width: 25px;
  height: 25px;
  fill: white;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

div.headerCategories {
  padding: 0 20px 30px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: fixed;
  top: 15%;
  z-index: 10;
  background-color: var(--bg-color);
}
@media all and (max-width: 980px) {
  div.headerCategories .swiper {
    overflow: visible !important;
  }
}
@media all and (max-width: 980px) {
  div.headerCategories .swiper .swiper-slide {
    width: unset !important;
  }
}
div.headerCategories .swiper .swiper-button-prev {
  width: 83px !important;
  left: -7px;
}
div.headerCategories .swiper .swiper-button-prev::after {
  background: linear-gradient(90deg, rgb(16, 20, 31) 0%, rgb(16, 20, 31) 50%, rgba(16, 20, 31, 0.1629901961) 100%);
  border-radius: unset;
  width: 90px !important;
}
@media all and (max-width: 1200px) {
  div.headerCategories .swiper .swiper-button-prev::after {
    height: 40px;
  }
}
@media all and (max-width: 980px) {
  div.headerCategories .swiper .swiper-button-prev {
    display: none;
  }
}
div.headerCategories .swiper .swiper-button-next {
  width: 83px !important;
  right: 48px !important;
}
@media all and (max-width: 980px) {
  div.headerCategories .swiper .swiper-button-next {
    display: none;
  }
}
div.headerCategories .swiper .swiper-button-next::after {
  background: linear-gradient(90deg, rgba(16, 20, 31, 0.381477591) 0%, rgb(16, 20, 31) 50%, rgb(16, 20, 31) 100%);
  border-radius: unset;
  width: 90px !important;
}
@media all and (max-width: 1200px) {
  div.headerCategories .swiper .swiper-button-next::after {
    height: 40px;
  }
}
div.headerCategories button {
  color: var(--text-color);
  background-color: var(--button-color);
  padding: 8px 15px;
  border-radius: 10px;
  width: 13rem;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media all and (max-width: 1600px) {
  div.headerCategories button {
    width: 11rem;
  }
}
@media all and (max-width: 1400px) {
  div.headerCategories button {
    width: 10rem;
  }
}
@media all and (max-width: 1200px) {
  div.headerCategories button {
    width: 8rem;
  }
}
@media all and (max-width: 980px) {
  div.headerCategories button {
    width: 9rem;
  }
}
@media all and (max-width: 480px) {
  div.headerCategories button {
    width: 8rem;
  }
}
div.headerCategories button:hover {
  transform: scale(1.0476190476, 1.0357142857);
}

div.openAppContainer {
  padding: 15px;
  position: fixed;
  bottom: 10px;
  width: 100%;
  background-color: var(--button-color);
  z-index: 100000000000000000;
  border-radius: 10px;
  width: 98%;
  margin: 0 auto;
  transform: translateX(-50%);
  left: 50%;
}
div.openAppContainer h1 {
  font-size: 14px;
  color: #fff;
  margin-bottom: 12px;
}
div.openAppContainer p {
  font-size: 12px;
  color: #fff;
  margin-bottom: 10px;
}
div.openAppContainer div.buttons {
  display: flex;
  justify-content: space-between;
}
div.openAppContainer div.buttons button.notNow {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  padding: 6px 12px;
  border-radius: 30px;
  font-weight: 700;
  font-size: 12px;
}
div.openAppContainer div.buttons button.openApp {
  border: 1px solid #fff;
  background: #fff;
  color: #000;
  padding: 6px 12px;
  border-radius: 30px;
  font-weight: 700;
  font-size: 12px;
}

div.affliatePage {
  padding: 30px 0 0;
  background-color: #fff;
}
div.affliatePage div.top {
  padding: 30px 0;
}
div.affliatePage div.top div.logoSection {
  width: 70%;
  margin: 0 auto;
}
@media all and (min-width: 980px) {
  div.affliatePage div.top div.logoSection {
    width: 30%;
  }
}
div.affliatePage div.top div.logoSection h1.image {
  width: 100%;
}
div.affliatePage div.top div.logoSection h1.image img {
  width: 100%;
  display: block;
}
div.affliatePage div.top h1 {
  font-size: 24px;
  text-align: center;
}
div.affliatePage div.bottom {
  background-color: #022230;
  padding: 136px 20px;
  border-top-left-radius: 91%;
  border-top-right-radius: 0;
}
div.affliatePage div.bottom div.buttons {
  margin-left: 40px;
}
div.affliatePage div.bottom div.buttons div.buttonContainer {
  text-align: center;
}
div.affliatePage div.bottom div.buttons div.buttonContainer button {
  padding: 10px 20px;
  font-size: 15px;
  border-radius: 30px;
  width: 10rem;
  color: #fff;
}
div.affliatePage div.bottom div.buttons div.buttonContainer button.useApp {
  background-color: #45c4af;
  margin-bottom: 20px;
  box-shadow: rgba(69, 196, 175, 0.35) 0px 5px 15px;
}
div.affliatePage div.bottom div.buttons div.buttonContainer button.web {
  box-shadow: rgba(0, 0, 0, 0.03) 0px 16px 24px;
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
}

div.playerContainer {
  width: 90%;
  margin: 0 auto;
  background-color: var(--black-color);
  position: relative;
}
div.playerContainer div.videoContainer {
  background-color: #000;
  width: 100%;
}
div.playerContainer div.videoContainer.heightIncrease {
  height: 1000px;
}
@media all and (max-width: 1200px) {
  div.playerContainer div.videoContainer.heightIncrease {
    height: 25em;
  }
}
div.playerContainer div.videoContainer div.gradientTop {
  position: absolute;
  top: 0;
  z-index: 8;
  width: 100%;
  height: 20%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(0deg, rgba(16, 20, 31, 0), rgba(16, 20, 31, 0.2) 19%, rgba(16, 20, 31, 0.4) 39%, rgba(16, 20, 31, 0.8));
}
div.playerContainer div.videoContainer div.player {
  position: relative;
  z-index: 9;
  width: 100%;
  display: block;
}
div.playerContainer div.videoContainer div.player div.closeIcon {
  display: none;
}
div.playerContainer div.videoContainer div.player div.nowPlaying {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 7;
}
div.playerContainer div.videoContainer div.player div.nowPlaying h1 {
  color: var(--text-color);
}
@media all and (max-width: 1200px) {
  div.playerContainer div.videoContainer div.player div.nowPlaying h1 {
    font-size: 23px;
  }
}
@media all and (max-width: 980px) {
  div.playerContainer div.videoContainer div.player div.nowPlaying h1 {
    font-size: 20px;
  }
}
@media all and (max-width: 768px) {
  div.playerContainer div.videoContainer div.player div.nowPlaying h1 {
    font-size: 18px;
  }
}
@media all and (max-width: 640px) {
  div.playerContainer div.videoContainer div.player div.nowPlaying h1 {
    font-size: 16px;
  }
}
@media all and (max-width: 480px) {
  div.playerContainer div.videoContainer div.player div.nowPlaying h1 {
    font-size: 14px;
  }
}
div.playerContainer div.videoContainer div.player.pipMode {
  position: fixed;
  top: 110px;
  width: 22%;
  right: 0;
  z-index: 999999999;
  height: 200px;
}
@media all and (max-width: 768px) {
  div.playerContainer div.videoContainer div.player.pipMode {
    width: 40%;
  }
}
@media all and (max-width: 640px) {
  div.playerContainer div.videoContainer div.player.pipMode {
    width: 50%;
  }
}
@media all and (max-width: 480px) {
  div.playerContainer div.videoContainer div.player.pipMode {
    width: 68%;
  }
}
div.playerContainer div.videoContainer div.player.pipMode video {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
}
div.playerContainer div.videoContainer div.player.pipMode .video-js {
  padding: 0;
  height: 100%;
}
div.playerContainer div.videoContainer div.player.pipMode div.gradientTop {
  display: none;
}
div.playerContainer div.videoContainer div.player.pipMode div.nowPlaying {
  display: none;
}
div.playerContainer div.videoContainer div.player.pipMode:hover div.closeIcon {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 9;
  background-color: var(--bg-color);
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
div.playerContainer div.videoContainer div.player.pipMode:hover div.closeIcon svg {
  color: var(--text-color);
}
div.playerContainer div.videoContainer div.player.pipMode:hover div.gradientTop {
  display: block;
}
div.playerContainer div.videoContainer video {
  display: block;
  width: 100%;
}

div.playerdetailsScreen {
  scroll-behavior: smooth;
  padding: 20px 0 0;
  position: relative;
}
@media all and (max-width: 2600px) {
  div.playerdetailsScreen {
    padding: 246px 0 0;
  }
}
@media all and (max-width: 2500px) {
  div.playerdetailsScreen {
    padding: 180px 0 0;
  }
}
@media all and (max-width: 1768px) {
  div.playerdetailsScreen {
    padding: 140px 0 0;
  }
}
@media all and (max-width: 1600px) {
  div.playerdetailsScreen {
    padding: 145px 0 0;
  }
}
div.playerdetailsScreen .swiper-button-next {
  right: -9px !important;
  top: 45%;
}
@media all and (min-width: 1200px) {
  div.playerdetailsScreen .swiper-button-next {
    right: 0 !important;
  }
}
div.playerdetailsScreen .swiper-button-prev {
  left: -9px !important;
  top: 45%;
}
@media all and (min-width: 1200px) {
  div.playerdetailsScreen .swiper-button-prev {
    left: 0 !important;
  }
}
div.playerdetailsScreen div.wrapper {
  background-color: var(--bg-color);
  position: relative;
  z-index: 8;
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 980px) {
  div.playerdetailsScreen div.wrapper {
    flex-direction: column;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer {
  width: 73%;
}
@media all and (max-width: 980px) {
  div.playerdetailsScreen div.wrapper div.leftContainer {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.videoplayerContainer {
  width: 90%;
  margin: 0 auto;
  background-color: var(--black-color);
  position: relative;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.videoplayerContainer div.videoContainer {
  background-color: #000;
  width: 100%;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.videoplayerContainer div.videoContainer.heightIncrease {
  height: 1000px;
}
@media all and (max-width: 1200px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.videoplayerContainer div.videoContainer.heightIncrease {
    height: 25em;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.videoplayerContainer div.videoContainer div.gradientTop {
  position: absolute;
  top: 0;
  z-index: 8;
  width: 100%;
  height: 20%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(0deg, rgba(16, 20, 31, 0), rgba(16, 20, 31, 0.2) 19%, rgba(16, 20, 31, 0.4) 39%, rgba(16, 20, 31, 0.8));
}
div.playerdetailsScreen div.wrapper div.leftContainer div.videoplayerContainer div.videoContainer div.player {
  position: relative;
  z-index: 9;
  width: 100%;
  display: block;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.videoplayerContainer div.videoContainer div.player div.closeIcon {
  display: none;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.videoplayerContainer div.videoContainer div.player div.nowPlaying {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 7;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.videoplayerContainer div.videoContainer div.player div.nowPlaying h1 {
  color: var(--text-color);
}
@media all and (max-width: 1200px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.videoplayerContainer div.videoContainer div.player div.nowPlaying h1 {
    font-size: 23px;
  }
}
@media all and (max-width: 980px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.videoplayerContainer div.videoContainer div.player div.nowPlaying h1 {
    font-size: 20px;
  }
}
@media all and (max-width: 768px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.videoplayerContainer div.videoContainer div.player div.nowPlaying h1 {
    font-size: 18px;
  }
}
@media all and (max-width: 640px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.videoplayerContainer div.videoContainer div.player div.nowPlaying h1 {
    font-size: 16px;
  }
}
@media all and (max-width: 480px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.videoplayerContainer div.videoContainer div.player div.nowPlaying h1 {
    font-size: 14px;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.videoplayerContainer div.videoContainer div.player.pipMode {
  position: fixed;
  top: 110px;
  width: 22%;
  right: 0;
  z-index: 999999999;
  height: 200px;
}
@media all and (max-width: 768px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.videoplayerContainer div.videoContainer div.player.pipMode {
    width: 40%;
  }
}
@media all and (max-width: 640px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.videoplayerContainer div.videoContainer div.player.pipMode {
    width: 50%;
  }
}
@media all and (max-width: 480px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.videoplayerContainer div.videoContainer div.player.pipMode {
    width: 68%;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.videoplayerContainer div.videoContainer div.player.pipMode video {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.videoplayerContainer div.videoContainer div.player.pipMode .video-js {
  padding: 0;
  height: 100%;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.videoplayerContainer div.videoContainer div.player.pipMode div.gradientTop {
  display: none;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.videoplayerContainer div.videoContainer div.player.pipMode div.nowPlaying {
  display: none;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.videoplayerContainer div.videoContainer div.player.pipMode:hover div.closeIcon {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 9;
  background-color: var(--bg-color);
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.videoplayerContainer div.videoContainer div.player.pipMode:hover div.closeIcon svg {
  color: var(--text-color);
}
div.playerdetailsScreen div.wrapper div.leftContainer div.videoplayerContainer div.videoContainer div.player.pipMode:hover div.gradientTop {
  display: block;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.videoplayerContainer div.videoContainer video {
  display: block;
  width: 100%;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.multiAudio {
  width: 22%;
  margin-top: 2%;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer {
  margin-top: 15px;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata {
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 40px;
  width: 100%;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata h1.name {
  font-size: 30px;
  font-weight: 700;
  color: var(--text-color);
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions {
  display: flex;
  margin: 10px 0;
}
@media all and (max-width: 640px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions {
    flex-direction: column;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions button {
  width: 10rem;
  display: block;
  background: var(--secondary-btn-color);
  padding: 8px 10px;
  font-size: 14px;
  color: var(--text-color);
  font-weight: 600;
  border-radius: 30px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions button:hover {
  background: var(--button-hover);
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.left {
  width: 30%;
}
@media all and (max-width: 640px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.left {
    width: 100%;
    margin-bottom: 20px;
  }
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.left div.playButtonContainer button.playButton {
    width: 90% !important;
    margin: 0 auto;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.left div.playButtonContainer button.playButton {
  width: unset;
  padding: 8px 12px;
  background: var(--button-color);
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.left div.playButtonContainer button.playButton:hover {
  background-color: rgba(203, 203, 208, 0.16);
}
@media all and (max-width: 360px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.left div.playButtonContainer button.playButton {
    width: 100% !important;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right {
  width: 70%;
  display: flex;
  justify-content: flex-end;
}
@media all and (max-width: 640px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right {
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.addRemoveList button {
  width: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media all and (max-width: 640px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.addRemoveList button {
    width: 14rem;
  }
}
@media all and (max-width: 480px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.addRemoveList button {
    width: 17rem;
    margin-bottom: 10px;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.addRemoveList button div.icon {
  margin-left: 6px;
  line-height: 0;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton {
  position: relative;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton button.share {
  width: 6rem;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media all and (max-width: 480px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton button.share {
    width: 14rem;
    margin-left: 0;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton button.share div.icon {
  margin-left: 6px;
  line-height: 0;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer {
  position: absolute;
  bottom: -126px;
  left: -50px;
  z-index: 9;
  background-color: #1c1f29;
  border: 1px solid var(--border-color);
  width: 232px;
  border-radius: 8px;
  display: none;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer button {
  border-radius: 0%;
}
@media all and (max-width: 1200px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer {
    width: 200px;
  }
}
@media all and (max-width: 980px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer {
    width: 232px;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media all and (max-width: 480px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer {
    width: 100%;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer.active {
  display: block;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer::after {
  content: "";
  top: -9px;
  width: 0;
  height: 0;
  border-color: transparent transparent rgba(240, 241, 245, 0.16);
  border-style: solid;
  border-width: 0 8px 8px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
@media all and (max-width: 1200px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer::after {
    left: 40%;
  }
}
@media all and (max-width: 980px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer::after {
    left: 50%;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer div.facebookContainer button.facebook {
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px !important;
  color: var(--text-color) !important;
  cursor: pointer !important;
  border-bottom: 1px solid var(--border-color) !important;
  width: 100% !important;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer div.facebookContainer button.facebook div.icon {
  width: 10%;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer div.facebookContainer button.facebook div.icon svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer div.facebookContainer button.facebook div.icon svg:hover {
  color: var(--text-color);
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer div.facebookContainer div.left {
  width: 10%;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer div.facebookContainer div.right svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer div.facebookContainer div.right svg:hover {
  color: var(--text-color);
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer div.twitterContainer button.twitter {
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px !important;
  color: var(--text-color) !important;
  cursor: pointer !important;
  width: 100% !important;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer div.twitterContainer button.twitter div.icon {
  width: 10%;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer div.twitterContainer button.twitter div.icon svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer div.twitterContainer button.twitter div.icon svg:hover {
  color: var(--text-color);
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer div.twitterContainer div.left {
  width: 10%;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer div.twitterContainer div.right svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.shareButton div.shareContainer div.twitterContainer div.right svg:hover {
  color: var(--text-color);
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.more {
  margin-left: 10px;
  position: relative;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.more button.more {
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.more button.more svg {
  width: 20px !important;
  height: 20px !important;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.more button.more:hover {
  background-color: rgba(203, 203, 208, 0.16);
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.more div.reportProblemContainer {
  position: absolute;
  bottom: -66px;
  right: -17px;
  z-index: 9;
  background-color: #1c1f29;
  border: 1px solid var(--border-color);
  width: 200px;
  border-radius: 8px;
  padding: 15px;
  display: none;
  cursor: pointer;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.more div.reportProblemContainer.active {
  display: block;
}
@media all and (max-width: 1200px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.more div.reportProblemContainer {
    width: 164px;
  }
}
@media all and (max-width: 980px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.more div.reportProblemContainer {
    width: 200px;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.more div.reportProblemContainer span {
  color: var(--text-color);
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.more div.reportProblemContainer::after {
  content: "";
  top: -9px;
  width: 0;
  height: 0;
  border-color: transparent transparent rgba(240, 241, 245, 0.16);
  border-style: solid;
  border-width: 0 8px 8px;
  position: absolute;
  right: 8%;
  transform: translateX(-50%);
}
@media all and (max-width: 1200px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.more div.reportProblemContainer::after {
    right: 5%;
  }
}
@media all and (max-width: 980px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.actions div.right div.more div.reportProblemContainer::after {
    right: 8%;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.data {
  display: flex;
  margin-bottom: 6px;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.data span {
  font-size: 14px;
  color: var(--inner-text-color);
}
@media all and (max-width: 640px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.data span {
    font-size: 14px;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.data span.year {
  margin-right: 6px;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.data span.duration {
  margin-right: 8px;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.data span.duration.showDot::before {
  content: "·";
  margin-right: 6px;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.data span.certificate {
  display: block;
  padding: 4px 6px;
  background-color: rgba(150, 153, 163, 0.32);
  font-size: 11px;
  font-weight: 700;
  color: var(--text-color);
  border-radius: 4px;
}
@media all and (max-width: 640px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.data span.certificate {
    font-size: 10px;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.genres {
  margin-bottom: 10px;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.genres span {
  font-size: 14px;
  color: var(--inner-text-color);
  margin-right: 6px;
}
@media all and (max-width: 640px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.genres span {
    font-size: 14px;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.genres span::before {
  content: "·";
  margin-right: 6px;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.genres span:first-child::before {
  display: none;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.genres span:last-child {
  margin-right: 0;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.watchNow {
  display: none;
}
@media all and (max-width: 980px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.watchNow {
    display: block;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.addtoList {
  display: none;
}
@media all and (max-width: 980px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.addtoList {
    display: block;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.buttons {
  display: none;
  margin-bottom: 20px;
}
@media all and (max-width: 980px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.buttons {
    display: flex;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata p.description {
  font-size: 14px;
  color: var(--text-color);
  line-height: 1.4em;
  letter-spacing: 0.3px;
  max-width: 100%;
}
@media all and (max-width: 1200px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata p.description {
    max-width: 100%;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.extraInfoContainer {
  margin-top: 10px;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.extraInfoContainer div.metaItem {
  margin-bottom: 20px;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.extraInfoContainer div.metaItem h6.subHeading {
  font-size: 14px;
  font-weight: 400;
  color: var(--inner-text-color);
  margin-bottom: 10px;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.extraInfoContainer div.metaItem div.items {
  width: 30%;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.extraInfoContainer div.metaItem div.items.oneRow {
  display: flex;
  width: 60%;
  flex-wrap: wrap;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.extraInfoContainer div.metaItem div.items.oneRow span.item {
  margin-right: 10px;
  margin-bottom: 0;
  display: block;
  color: var(--text-color);
  cursor: pointer;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.extraInfoContainer div.metaItem div.items.oneRow span.item:last-child span.comma {
  display: none;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.extraInfoContainer div.metaItem div.items span.itemInfo {
  cursor: pointer;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.extraInfoContainer div.metaItem div.items div.item {
  display: flex;
  justify-content: space-between;
  color: var(--inner-text-color);
  margin-bottom: 6px;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.extraInfoContainer div.metaItem div.items div.item:last-child {
  margin-bottom: 0;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info {
  margin-top: 20px;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring div.left {
  width: 15%;
}
@media all and (max-width: 768px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring div.left {
    width: 20%;
  }
}
@media all and (max-width: 640px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring div.left {
    width: 25%;
  }
}
@media all and (max-width: 480px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring div.left {
    width: 30%;
  }
}
@media all and (max-width: 360px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring div.left {
    width: 33%;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring div.left h6 {
  font-size: 14px;
  font-weight: 400;
  color: var(--inner-text-color);
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring div.right {
  width: 85%;
}
@media all and (max-width: 980px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring div.right {
    width: 75%;
  }
}
@media all and (max-width: 640px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring div.right {
    width: 70%;
  }
}
@media all and (max-width: 480px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring div.right {
    width: 65%;
  }
}
@media all and (max-width: 360px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring div.right {
    width: 55%;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring div.right ul.names {
  display: flex;
  flex-wrap: wrap;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring div.right ul.names li {
  font-size: 14px;
  font-weight: 600;
  margin-right: 8px;
  color: var(--text-color);
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring div.right ul.names li:last-child {
  margin-right: 0;
}
@media all and (max-width: 480px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.starring div.right ul.names li {
    font-size: 14px;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.director {
  display: flex;
  justify-content: space-between;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.director div.left {
  width: 15%;
}
@media all and (max-width: 768px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.director div.left {
    width: 20%;
  }
}
@media all and (max-width: 640px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.director div.left {
    width: 25%;
  }
}
@media all and (max-width: 480px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.director div.left {
    width: 30%;
  }
}
@media all and (max-width: 360px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.director div.left {
    width: 33%;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.director div.left h6 {
  font-size: 14px;
  font-weight: 400;
  color: var(--inner-text-color);
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.director div.right {
  width: 85%;
}
@media all and (max-width: 980px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.director div.right {
    width: 75%;
  }
}
@media all and (max-width: 640px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.director div.right {
    width: 70%;
  }
}
@media all and (max-width: 480px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.director div.right {
    width: 65%;
  }
}
@media all and (max-width: 360px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.director div.right {
    width: 55%;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.director div.right h6 {
  font-size: 14px;
  font-weight: 600;
  margin-right: 8px;
  color: var(--text-color);
}
@media all and (max-width: 480px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.info div.director div.right h6 {
    font-size: 14px;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.subscriptions {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.subscriptions div.items {
  cursor: pointer;
  margin-right: 10px;
  background-color: var(--yellow-color);
  padding: 6px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  color: var(--text-color);
  margin-bottom: 20px;
  border-radius: 30px;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.infoContainer div.showMetadata div.subscriptions div.items.free {
  background-color: var(--success-color);
}
div.playerdetailsScreen div.wrapper div.leftContainer div.showTrailerContainer {
  margin-top: 20px;
  display: flex;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.showTrailerContainer h6.heading {
  font-size: 14px;
  font-weight: 400;
  color: var(--inner-text-color);
  margin-bottom: 15px;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.showTrailerContainer div.imageContainer {
  width: 200px;
  position: relative;
  margin-left: 100px;
  cursor: pointer;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.showTrailerContainer div.imageContainer:hover div.playIconContainer {
  visibility: visible;
  animation: fadeIn 0.2s ease-in-out forwards;
}
@media all and (max-width: 2500px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.showTrailerContainer div.imageContainer {
    margin-left: 12%;
  }
}
@media all and (max-width: 1200px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.showTrailerContainer div.imageContainer {
    margin-left: 10%;
  }
}
@media all and (max-width: 980px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.showTrailerContainer div.imageContainer {
    margin-left: 20%;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.showTrailerContainer div.imageContainer img {
  display: block;
  width: 100%;
  border-radius: 3px;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.showTrailerContainer div.imageContainer div.playIconContainer {
  align-items: center;
  border: 1px solid var(--white-color);
  border-radius: 50%;
  background: rgba(30, 30, 20, 0.5);
  cursor: pointer;
  display: flex;
  height: 40px;
  width: 40px;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s ease-in-out;
  z-index: 9;
  visibility: hidden;
  animation: fadeOut 0.2s ease-in-out forwards;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.showTrailerContainer div.imageContainer div.playIconContainer svg {
  width: 25px !important;
  height: 25px !important;
  color: white;
  fill: white;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer {
  width: 100%;
  margin: 0 auto;
  padding: 60px 0;
}
@media all and (max-width: 1200px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer {
    width: 80%;
  }
}
@media all and (max-width: 768px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer {
    width: 90%;
  }
}
@media all and (max-width: 640px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer {
    width: 100%;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.top div.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 10px;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.top div.head span.commentsCount {
  color: var(--text-color);
  font-size: 22px;
}
@media all and (max-width: 640px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.top div.head span.commentsCount {
    font-size: 16px;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.top div.head div.sortContainer select {
  background-color: transparent;
  border: 1px solid var(--border-color);
  padding: 10px 15px;
  color: var(--text-color);
  font-size: 16px;
  border-radius: 6px;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.top div.head div.sortContainer select option {
  color: var(--black-color);
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.top div.textContainer {
  display: flex;
  padding: 40px 0 20px;
  border-bottom: 1px solid var(--border-color);
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.top div.textContainer div.imageContainer {
  margin-right: 20px;
  width: 45px;
  height: 45px;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.top div.textContainer div.imageContainer img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.top div.textContainer div.imageContainer span.nameAvatar {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.top div.textContainer div.inputContainer {
  width: 90%;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.top div.textContainer div.inputContainer textarea {
  display: block;
  width: 100%;
  height: 45px;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.top div.textContainer div.inputContainer textarea:focus-visible {
  border: 3px solid var(--button-color) !important;
  outline: none;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.top div.textContainer div.inputContainer div.actions {
  display: flex;
  justify-content: space-between;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.top div.textContainer div.inputContainer div.actions p {
  font-size: 14px;
  color: var(--text-color);
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.top div.textContainer div.inputContainer div.actions p span {
  color: var(--grey-color);
  cursor: pointer;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.top div.textContainer div.inputContainer div.actions p span:hover {
  text-decoration: underline;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.top div.textContainer div.inputContainer div.actions div.buttonContainer button.submit {
  background-color: var(--button-color);
  color: var(--text-color);
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid var(--button-color);
  transition: 0.4s ease-in-out;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.top div.textContainer div.inputContainer div.actions div.buttonContainer button.submit:hover {
  background-color: transparent;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.bottom div.comments div.eachComment {
  border-bottom: 1px solid var(--border-color);
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.bottom div.comments div.eachComment:last-child {
  border: none;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.bottom div.comments div.eachComment div.containers {
  padding: 40px 0 25px;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.bottom div.comments div.eachComment div.containers div.replyMessagesContainer {
  padding: 40px 0 0;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.bottom div.comments div.eachComment div.containers div.replyMessagesContainer div.containers {
  padding: 0 0 0;
  display: flex;
  border: none;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem {
  display: flex;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem:last-child {
  border: none;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left {
  width: 45px;
  margin-right: 20px;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer {
  width: 100%;
  height: 45px;
}
@media all and (max-width: 640px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer {
    height: 41px;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer img {
  width: 100%;
  display: block;
  height: 100%;
  border-radius: 50%;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left span.nameAvatar {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right {
  width: 90%;
}
@media all and (max-width: 640px) {
  div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right {
    width: 96%;
  }
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right h1.name {
  font-size: 16px;
  color: var(--white-color);
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right span.time {
  font-size: 13px;
  color: var(--inner-text-color);
  font-weight: 600;
  display: inline-block;
  margin-bottom: 20px;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right p.comment {
  font-size: 16px;
  color: var(--text-color);
  margin-bottom: 20px;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions {
  display: flex;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 11px;
  color: var(--inner-text-color);
  transition: 0.4s ease-in-out;
  cursor: pointer;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item:hover {
  opacity: 0.6;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item:last-child {
  margin-right: 0;
}
div.playerdetailsScreen div.wrapper div.leftContainer div.commentsSection div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item span svg {
  width: 15px !important;
  height: 15px !important;
  color: rgb(190, 190, 187);
  fill: rgb(190, 190, 187);
  margin-right: 5px;
}
div.playerdetailsScreen div.wrapper div.rightContainer {
  width: 25%;
  height: 600px;
  overflow-y: scroll;
}
@media all and (max-width: 980px) {
  div.playerdetailsScreen div.wrapper div.rightContainer {
    width: 100%;
  }
}
div.playerdetailsScreen div.wrapper div.rightContainer div.relatedVideos div.releated_Video__Container h1.relatedHeading {
  font-size: 18px;
  color: var(--text-color);
}
@media all and (max-width: 980px) {
  div.playerdetailsScreen div.wrapper div.rightContainer div.relatedVideos div.releated_Video__Container div.recommendedVideos {
    display: flex;
    flex-wrap: wrap;
  }
}
@media all and (max-width: 980px) {
  div.playerdetailsScreen div.wrapper div.rightContainer div.relatedVideos div.releated_Video__Container div.recommendedVideos div.items {
    width: 48%;
    margin-right: 10px;
  }
  div.playerdetailsScreen div.wrapper div.rightContainer div.relatedVideos div.releated_Video__Container div.recommendedVideos div.items:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.playerdetailsScreen div.wrapper div.rightContainer div.relatedVideos div.releated_Video__Container div.recommendedVideos div.items {
    width: 100%;
    margin-right: 0;
  }
  div.playerdetailsScreen div.wrapper div.rightContainer div.relatedVideos div.releated_Video__Container div.recommendedVideos div.items:nth-child(2n) {
    margin-right: 0;
  }
}
div.playerdetailsScreen div.details {
  position: relative;
  z-index: 8;
  display: flex;
  padding: 30px 0 130px;
}
@media all and (max-width: 980px) {
  div.playerdetailsScreen div.details {
    flex-direction: column;
  }
}
div.playerdetailsScreen div.details div.watchNow {
  width: 100%;
  margin-top: 15px;
}
div.playerdetailsScreen div.details div.watchNow button {
  width: 100%;
  display: block;
  background: var(--button-color);
  padding: 15px 10px;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.playerdetailsScreen div.details div.watchNow button:hover {
  background: var(--button-hover);
}
div.playerdetailsScreen div.details div.addtoList {
  width: 100%;
  margin-top: 15px;
}
div.playerdetailsScreen div.details div.addtoList button {
  width: 100%;
  display: block;
  background-color: rgba(150, 153, 163, 0.16);
  padding: 15px 10px;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.playerdetailsScreen div.details div.addtoList button:hover {
  background-color: rgba(203, 203, 208, 0.16);
}
div.playerdetailsScreen div.details div.buttons {
  margin-top: 15px;
  display: flex;
  width: 100%;
}
div.playerdetailsScreen div.details div.buttons div.buttonContainerOne {
  width: 70%;
  position: relative;
  margin-right: 10px;
}
@media all and (max-width: 980px) {
  div.playerdetailsScreen div.details div.buttons div.buttonContainerOne {
    width: 90%;
  }
}
@media all and (max-width: 640px) {
  div.playerdetailsScreen div.details div.buttons div.buttonContainerOne {
    width: 80%;
  }
}
@media all and (max-width: 480px) {
  div.playerdetailsScreen div.details div.buttons div.buttonContainerOne {
    width: 75%;
  }
}
div.playerdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer {
  position: absolute;
  bottom: -126px;
  left: -20px;
  z-index: 9;
  background-color: #1c1f29;
  border: 1px solid var(--border-color);
  width: 232px;
  border-radius: 8px;
  display: none;
}
@media all and (max-width: 1200px) {
  div.playerdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer {
    width: 200px;
  }
}
@media all and (max-width: 980px) {
  div.playerdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer {
    width: 232px;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media all and (max-width: 480px) {
  div.playerdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer {
    width: 100%;
  }
}
div.playerdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer.active {
  display: block;
}
div.playerdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer::after {
  content: "";
  top: -9px;
  width: 0;
  height: 0;
  border-color: transparent transparent rgba(240, 241, 245, 0.16);
  border-style: solid;
  border-width: 0 8px 8px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
@media all and (max-width: 1200px) {
  div.playerdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer::after {
    left: 40%;
  }
}
@media all and (max-width: 980px) {
  div.playerdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer::after {
    left: 50%;
  }
}
div.playerdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer button.facebook {
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px !important;
  color: var(--text-color) !important;
  cursor: pointer !important;
  border-bottom: 1px solid var(--border-color) !important;
  width: 100% !important;
}
div.playerdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer button.facebook div.icon {
  width: 10%;
}
div.playerdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer button.facebook div.icon svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.playerdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer button.facebook div.icon svg:hover {
  color: var(--text-color);
}
div.playerdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer div.left {
  width: 10%;
}
div.playerdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer div.right svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.playerdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer div.right svg:hover {
  color: var(--text-color);
}
div.playerdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer button.twitter {
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px !important;
  color: var(--text-color) !important;
  cursor: pointer !important;
  width: 100% !important;
}
div.playerdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer button.twitter div.icon {
  width: 10%;
}
div.playerdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer button.twitter div.icon svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.playerdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer button.twitter div.icon svg:hover {
  color: var(--text-color);
}
div.playerdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer div.left {
  width: 10%;
}
div.playerdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer div.right svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.playerdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer div.right svg:hover {
  color: var(--text-color);
}
div.playerdetailsScreen div.details div.buttons div.buttonContainerOne button.share {
  display: block;
  background-color: rgba(150, 153, 163, 0.16);
  padding: 15px 10px;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.playerdetailsScreen div.details div.buttons div.buttonContainerOne button.share:hover {
  background-color: rgba(203, 203, 208, 0.16);
}
div.playerdetailsScreen div.details div.buttons div.buttonContainerTwo {
  width: 30%;
  position: relative;
}
@media all and (max-width: 980px) {
  div.playerdetailsScreen div.details div.buttons div.buttonContainerTwo {
    width: 10%;
  }
}
@media all and (max-width: 640px) {
  div.playerdetailsScreen div.details div.buttons div.buttonContainerTwo {
    width: 18%;
  }
}
@media all and (max-width: 480px) {
  div.playerdetailsScreen div.details div.buttons div.buttonContainerTwo {
    width: 25%;
  }
}
div.playerdetailsScreen div.details div.leftContainer {
  width: 27%;
  margin-right: 40px;
}
@media all and (max-width: 980px) {
  div.playerdetailsScreen div.details div.leftContainer {
    display: none;
  }
}
div.playerdetailsScreen div.details div.leftContainer div.showCardDetails {
  width: 100%;
  position: sticky;
  top: 0;
}
div.playerdetailsScreen div.details div.leftContainer div.showCardDetails div.imageContainer {
  width: 100%;
}
div.playerdetailsScreen div.details div.leftContainer div.showCardDetails div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 5px;
}
div.playerdetailsScreen div.details div.rightContainer {
  width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
div.playerdetailsScreen div.details div.rightContainer::-webkit-scrollbar {
  display: none;
}
div.commentsContainer {
  width: 70%;
  margin: 0 auto;
  padding: 60px 0;
}
@media all and (max-width: 1200px) {
  div.commentsContainer {
    width: 80%;
  }
}
@media all and (max-width: 768px) {
  div.commentsContainer {
    width: 90%;
  }
}
@media all and (max-width: 640px) {
  div.commentsContainer {
    width: 100%;
  }
}
div.commentsContainer div.top div.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 10px;
}
div.commentsContainer div.top div.head span.commentsCount {
  color: var(--white-color);
  font-size: 22px;
}
@media all and (max-width: 640px) {
  div.commentsContainer div.top div.head span.commentsCount {
    font-size: 16px;
  }
}
div.commentsContainer div.top div.head div.sortContainer select {
  background-color: transparent;
  border: 1px solid var(--border-color);
  padding: 10px 15px;
  color: var(--white-color);
  font-size: 16px;
  border-radius: 6px;
}
div.commentsContainer div.top div.head div.sortContainer select option {
  color: var(--black-color);
}
div.commentsContainer div.top div.textContainer {
  display: flex;
  padding: 40px 0 20px;
  border-bottom: 1px solid var(--border-color);
}
div.commentsContainer div.top div.textContainer div.imageContainer {
  margin-right: 20px;
  width: 45px;
  height: 45px;
}
div.commentsContainer div.top div.textContainer div.imageContainer img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
div.commentsContainer div.top div.textContainer div.imageContainer span.nameAvatar {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.commentsContainer div.top div.textContainer div.inputContainer {
  width: 90%;
}
div.commentsContainer div.top div.textContainer div.inputContainer textarea {
  display: block;
  width: 100%;
  height: 45px;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}
div.commentsContainer div.top div.textContainer div.inputContainer textarea:focus-visible {
  border: 3px solid var(--button-color) !important;
  outline: none;
}
div.commentsContainer div.top div.textContainer div.inputContainer div.actions {
  display: flex;
  justify-content: space-between;
}
div.commentsContainer div.top div.textContainer div.inputContainer div.actions p {
  font-size: 14px;
  color: var(--text-color);
}
div.commentsContainer div.top div.textContainer div.inputContainer div.actions p span {
  color: var(--grey-color);
  cursor: pointer;
}
div.commentsContainer div.top div.textContainer div.inputContainer div.actions p span:hover {
  text-decoration: underline;
}
div.commentsContainer div.top div.textContainer div.inputContainer div.actions div.buttonContainer button.submit {
  background-color: var(--button-color);
  color: var(--white-color);
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid var(--button-color);
  transition: 0.4s ease-in-out;
}
div.commentsContainer div.top div.textContainer div.inputContainer div.actions div.buttonContainer button.submit:hover {
  background-color: transparent;
}
div.commentsContainer div.bottom div.comments div.eachComment {
  border-bottom: 1px solid var(--border-color);
}
div.commentsContainer div.bottom div.comments div.eachComment:last-child {
  border: none;
}
div.commentsContainer div.bottom div.comments div.eachComment div.containers {
  padding: 40px 0 25px;
}
div.commentsContainer div.bottom div.comments div.eachComment div.containers div.replyMessagesContainer {
  padding: 40px 0 0;
}
div.commentsContainer div.bottom div.comments div.eachComment div.containers div.replyMessagesContainer div.containers {
  padding: 0 0 0;
  display: flex;
  border: none;
}
div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem {
  display: flex;
}
div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem:last-child {
  border: none;
}
div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left {
  width: 45px;
  margin-right: 20px;
}
div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer {
  width: 100%;
  height: 45px;
}
@media all and (max-width: 640px) {
  div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer {
    height: 41px;
  }
}
div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer img {
  width: 100%;
  display: block;
  height: 100%;
  border-radius: 50%;
}
div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left span.nameAvatar {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right {
  width: 90%;
}
@media all and (max-width: 640px) {
  div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right {
    width: 96%;
  }
}
div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right h1.name {
  font-size: 16px;
  color: var(--white-color);
}
div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right span.time {
  font-size: 13px;
  color: var(--inner-text-color);
  font-weight: 600;
  display: inline-block;
  margin-bottom: 20px;
}
div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right p.comment {
  font-size: 16px;
  color: var(--white-color);
  margin-bottom: 20px;
}
div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions {
  display: flex;
}
div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 11px;
  color: var(--inner-text-color);
  transition: 0.4s ease-in-out;
  cursor: pointer;
}
div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item:hover {
  opacity: 0.6;
}
div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item:last-child {
  margin-right: 0;
}
div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item span svg {
  width: 15px !important;
  height: 15px !important;
  color: rgb(190, 190, 187);
  fill: rgb(190, 190, 187);
  margin-right: 5px;
}

div.replyField {
  display: flex;
  width: 100%;
  padding-top: 30px;
  animation: fadeIn 0.4s ease-in-out;
}
div.replyField.child {
  width: 92%;
  margin: 0 0 0 auto;
}
div.replyField div.left {
  margin-right: 20px;
}
div.replyField div.left div.imageContainer {
  width: 45px;
  height: 45px;
}
div.replyField div.left div.imageContainer img {
  width: 100%;
  display: block;
  height: 100%;
  border-radius: 50%;
}
div.replyField div.left div.imageContainer span.nameAvatar {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.replyField div.right {
  width: 100%;
}
div.replyField div.right div.inputContainer textarea {
  display: block;
  width: 100%;
  height: 45px;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}
div.replyField div.right div.inputContainer textarea:focus-visible {
  border: 3px solid var(--button-color) !important;
  outline: none;
}
div.replyField div.right div.inputContainer div.actions {
  display: flex;
  justify-content: flex-end;
}
div.replyField div.right div.inputContainer div.actions p {
  font-size: 14px;
  color: var(--text-color);
}
div.replyField div.right div.inputContainer div.actions p span {
  color: var(--grey-color);
  cursor: pointer;
}
div.replyField div.right div.inputContainer div.actions p span:hover {
  text-decoration: underline;
}
div.replyField div.right div.inputContainer div.actions div.buttonContainer button {
  background-color: var(--button-color);
  color: var(--white-color);
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid var(--button-color);
  transition: 0.4s ease-in-out;
}
div.replyField div.right div.inputContainer div.actions div.buttonContainer button:hover {
  background-color: transparent;
}
div.replyField div.right div.inputContainer div.actions div.buttonContainer button.cancel {
  background-color: var(--grey-color);
  border: 1px solid var(--grey-color);
  margin-right: 20px;
}
div.replyField div.right div.inputContainer div.actions div.buttonContainer button.cancel:hover {
  background-color: transparent;
}

div.eventDetailsScreen {
  margin-top: 100px;
  background-color: var(--secondary-bg-color);
}
div.eventDetailsScreen div.topBackgroundContainer {
  position: relative;
  background-repeat: no-repeat;
  width: 100%;
  background-size: 100%;
  background-size: cover;
  padding-bottom: 20px;
}
div.eventDetailsScreen div.topBackgroundContainer div.contents {
  display: flex;
  justify-content: space-between;
  padding: 0 50px 20px 50px;
}
div.eventDetailsScreen div.topBackgroundContainer div.bottomGradient {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 35%, rgba(0, 0, 0, 0.6) 70%, rgba(0, 0, 0, 0.8) 100%);
}
div.eventDetailsScreen div.topBackgroundContainer div.left {
  padding-top: 220px;
  width: 23%;
  z-index: 9;
  padding-left: 5px;
}
@media all and (max-width: 2500px) {
  div.eventDetailsScreen div.topBackgroundContainer div.left {
    padding-left: 80px;
  }
}
@media all and (max-width: 1600px) {
  div.eventDetailsScreen div.topBackgroundContainer div.left {
    padding-left: 5px;
  }
}
@media all and (max-width: 1400px) {
  div.eventDetailsScreen div.topBackgroundContainer div.left {
    padding-left: 30px;
  }
}
@media all and (max-width: 1200px) {
  div.eventDetailsScreen div.topBackgroundContainer div.left {
    width: 24%;
  }
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.topBackgroundContainer div.left {
    width: 100%;
    padding-top: 280px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
div.eventDetailsScreen div.topBackgroundContainer div.left h1.title {
  color: var(--text-color);
  font-size: 28px;
  text-transform: uppercase;
  margin-bottom: 16px;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.showMoreInfo {
  color: var(--text-color);
  align-items: center;
  border-bottom: 1px solid var(--text-color);
  padding-bottom: 10px;
  margin-bottom: 20px;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.icon {
  margin-right: 5px;
  line-height: 0;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.icon svg {
  width: 16px !important;
  height: 16px !important;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.icon svg path {
  fill: var(--text-color) !important;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.duration {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.duration span {
  font-size: 13px;
  font-weight: 500;
  color: var(--text-color);
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.duration span {
    font-size: 14px;
  }
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.categoryname {
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-top: 10px;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.categoryname h2 {
  color: var(--text-color);
  font-size: 14px;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.categoryname h2 span {
  font-size: 15px;
  font-weight: 500;
  color: var(--text-color);
  margin-left: 10px;
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.categoryname h2 span {
    font-size: 14px;
  }
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--text-color);
  padding-bottom: 10px;
  margin-bottom: 20px;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.addRating {
  margin-right: 10px;
  cursor: pointer;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.addRating:hover svg {
  fill: var(--grey-color);
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.addRating svg {
  width: 27px !important;
  height: 27px !important;
  fill: var(--text-color);
  transition: 0.3s ease-in-out;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.socialLinks {
  display: flex;
  align-items: center;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.socialLinks div.facebookLink, div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.socialLinks div.instagramLink {
  margin-right: 10px;
  cursor: pointer;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.socialLinks div.facebookLink:hover svg, div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.socialLinks div.instagramLink:hover svg {
  color: var(--grey-color);
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.socialLinks div.facebookLink svg, div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.socialLinks div.instagramLink svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--text-color);
  transition: 0.3s ease-in-out;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.userRatings {
  display: flex;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.userRatings div.star.active svg {
  fill: #fbe725;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.userRatings div.star svg {
  width: 23px !important;
  height: 23px !important;
  fill: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.favourite {
  margin-right: 10px;
  cursor: pointer;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.favourite:hover svg {
  fill: var(--grey-color);
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.favourite svg {
  width: 23px !important;
  height: 23px !important;
  fill: var(--text-color);
  transition: 0.3s ease-in-out;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.watchListButton {
  cursor: pointer;
  margin-right: 10px;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.watchListButton:hover svg {
  fill: var(--grey-color);
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.watchListButton svg {
  width: 22px !important;
  height: 22px !important;
  transition: 0.3s ease-in-out;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.externalLinks {
  display: flex;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.externalLinks div.imageContainer {
  margin-right: 10px;
  width: 35px;
  cursor: pointer;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.externalLinks div.imageContainer:last-child {
  margin-right: 0;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.externalLinks div.imageContainer img {
  width: 100%;
  display: block;
}
div.eventDetailsScreen div.topBackgroundContainer div.categoryName {
  background-color: #68abff;
  padding: 10px 6px;
  width: 8rem;
  text-transform: uppercase;
  color: var(--text-color);
  font-weight: 500;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  position: absolute;
  right: 0;
  top: 20px;
  z-index: 9;
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.topBackgroundContainer div.categoryName {
    display: none;
  }
}
div.eventDetailsScreen div.topBackgroundContainer div.top {
  position: absolute;
  right: 0;
  text-align: right;
  padding-top: 60px;
  z-index: 9;
}
@media all and (max-width: 2500px) {
  div.eventDetailsScreen div.topBackgroundContainer div.top {
    right: 100px;
  }
}
@media all and (max-width: 1600px) {
  div.eventDetailsScreen div.topBackgroundContainer div.top {
    right: 50px;
  }
}
@media all and (max-width: 1400px) {
  div.eventDetailsScreen div.topBackgroundContainer div.top {
    right: 60px;
  }
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.topBackgroundContainer div.top {
    padding-top: 75px;
    right: 40px;
  }
}
div.eventDetailsScreen div.topBackgroundContainer div.top div.ratings {
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 20px;
  margin-top: 10px;
}
div.eventDetailsScreen div.topBackgroundContainer div.top div.ratings div.icon {
  margin-right: 5px;
}
div.eventDetailsScreen div.topBackgroundContainer div.top div.ratings div.icon svg {
  width: 18px !important;
  height: 18px !important;
}
div.eventDetailsScreen div.topBackgroundContainer div.top div.ratings h1.rating {
  font-size: 22px;
  color: var(--text-color);
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.topBackgroundContainer div.top div.ratings h1.rating {
    font-size: 23px;
  }
}
div.eventDetailsScreen div.topBackgroundContainer div.top div.ratings h1.rating span.outOf {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 700;
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.topBackgroundContainer div.top div.ratings h1.rating span.outOf {
    font-size: 15px;
  }
}
div.eventDetailsScreen div.topBackgroundContainer div.top div.trailer {
  padding-right: 5px;
  padding-top: 90px;
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.topBackgroundContainer div.top div.trailer {
    display: none;
  }
}
div.eventDetailsScreen div.topBackgroundContainer div.top div.trailer button.trailerBtn {
  background-color: transparent;
  border: 1px solid var(--text-color);
  width: 5rem;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  padding: 6px;
  color: var(--text-color);
  margin-left: auto;
  cursor: pointer;
}
div.eventDetailsScreen div.topBackgroundContainer div.top div.trailer button.trailerBtn:hover {
  border-color: var(--button-hover);
  background-color: var(--button-hover);
}
div.eventDetailsScreen div.topBackgroundContainer div.top div.trailer button.trailerBtn div.icon {
  line-height: 0;
  margin-left: 8px;
}
div.eventDetailsScreen div.topBackgroundContainer div.top span.totalRatings {
  color: var(--text-color);
  font-size: 13px;
  font-weight: 700;
  padding-right: 3px;
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.topBackgroundContainer div.top span.totalRatings {
    font-size: 14px;
  }
}
div.eventDetailsScreen div.topBackgroundContainer div.topLeft {
  position: absolute;
  text-align: right;
  margin-top: 100px;
  z-index: 999;
  cursor: pointer;
}
@media all and (max-width: 2500px) {
  div.eventDetailsScreen div.topBackgroundContainer div.topLeft {
    margin-left: 80px;
  }
}
@media all and (max-width: 1600px) {
  div.eventDetailsScreen div.topBackgroundContainer div.topLeft {
    margin-left: 15px;
  }
}
div.eventDetailsScreen div.topBackgroundContainer div.topLeft svg {
  width: 30px;
  fill: #fff !important;
  stroke: #fff;
  cursor: pointer;
}
div.eventDetailsScreen div.topBackgroundContainer div.right {
  width: 73%;
  padding-top: 270px;
  z-index: 8;
  display: flex;
}
@media all and (max-width: 2500px) {
  div.eventDetailsScreen div.topBackgroundContainer div.right {
    padding-right: 80px;
  }
}
@media all and (max-width: 1600px) {
  div.eventDetailsScreen div.topBackgroundContainer div.right {
    padding-right: 5px;
  }
}
@media all and (max-width: 1400px) {
  div.eventDetailsScreen div.topBackgroundContainer div.right {
    padding-right: 30px;
  }
}
@media all and (max-width: 1200px) {
  div.eventDetailsScreen div.topBackgroundContainer div.right {
    width: 70%;
  }
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.topBackgroundContainer div.right {
    display: none;
  }
}
div.eventDetailsScreen div.topBackgroundContainer div.right div.description {
  color: var(--text-color);
  width: 50%;
}
div.eventDetailsScreen div.topBackgroundContainer div.right div.description p.data {
  font-size: 13px;
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.topBackgroundContainer div.right div.description p.data {
    font-size: 14px;
  }
}
div.eventDetailsScreen div.topBackgroundContainer div.playIconContainer {
  position: absolute;
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid var(--text-color);
  transition: 0.3s ease-in-out;
  z-index: 9;
  left: 50%;
  top: 23%;
  transform: translateX(-50%);
  cursor: pointer;
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.topBackgroundContainer div.playIconContainer {
    top: 30%;
  }
}
div.eventDetailsScreen div.topBackgroundContainer div.playIconContainer svg {
  width: 30px !important;
  height: 30px !important;
  color: var(--text-color);
}
div.eventDetailsScreen div.topBackgroundContainer div.playIconContainer:hover {
  background-color: var(--button-hover);
  border-color: var(--button-hover);
}
div.eventDetailsScreen div.topBackgroundContainer div.subscriptions {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  z-index: 9;
  padding-left: 5px;
  padding-bottom: 30px;
}
@media all and (max-width: 2500px) {
  div.eventDetailsScreen div.topBackgroundContainer div.subscriptions {
    padding-left: 80px;
  }
}
@media all and (max-width: 1600px) {
  div.eventDetailsScreen div.topBackgroundContainer div.subscriptions {
    padding-left: 50px;
  }
}
div.eventDetailsScreen div.topBackgroundContainer div.subscriptions div.items {
  z-index: 9;
  cursor: pointer;
  margin-right: 10px;
  background-color: var(--yellow-color);
  padding: 6px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: 600;
  color: var(--text-color);
  margin-bottom: 20px;
  border-radius: 30px;
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.topBackgroundContainer div.subscriptions div.items {
    font-size: 12px;
  }
}
div.eventDetailsScreen div.topBackgroundContainer div.subscriptions div.items.free {
  background-color: var(--success-color);
}
div.eventDetailsScreen div.topBackgroundContainer div.shareContainer {
  position: absolute;
  right: 5px;
  z-index: 9;
  bottom: 10px;
  display: flex;
  align-items: center;
  padding-right: 50px;
  cursor: pointer;
}
div.eventDetailsScreen div.topBackgroundContainer div.shareContainer span {
  color: var(--text-color);
  font-size: 14px;
  margin-right: 15px;
  transition: 0.2s ease-in-out;
}
div.eventDetailsScreen div.topBackgroundContainer div.shareContainer span.active {
  transform: translateX(-10px);
}
div.eventDetailsScreen div.topBackgroundContainer div.shareContainer div.icon {
  line-height: 0;
}
div.eventDetailsScreen div.topBackgroundContainer div.shareContainer div.icon svg {
  width: 20px !important;
  height: 20px !important;
}
div.eventDetailsScreen div.topBackgroundContainer div.shareContainer div.icon .closeIcon {
  stroke: var(--text-color) !important;
  display: block;
  width: 11px !important;
  height: 11px !important;
}
div.eventDetailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons {
  line-height: 0;
  animation: fadeIn 0.9s;
}
div.eventDetailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons .facebook {
  margin-right: 15px;
}
div.eventDetailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons .facebook div.icon svg {
  color: var(--text-color);
}
div.eventDetailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons .twitter {
  margin-right: 15px;
}
div.eventDetailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons .twitter div.icon svg {
  color: var(--text-color);
}
div.eventDetailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons .clipBoard {
  margin-right: 15px;
  display: inline;
}
div.eventDetailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons .clipBoard svg {
  fill: var(--white-color);
  width: 20px !important;
  height: 20px !important;
}
div.eventDetailsScreen div.responsiveContent {
  padding-bottom: 25px;
  display: none;
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.responsiveContent {
    display: block;
  }
}
div.eventDetailsScreen div.responsiveContent div.description {
  padding: 25px 15px;
  color: var(--text-color);
}
div.eventDetailsScreen div.responsiveContent div.description p.data {
  font-size: 16px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
div.livePlayerContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}
div.livePlayerContainer div.videoPlayer {
  margin-top: 170px;
  width: 70%;
  position: relative;
  padding: 10px 20px;
}
div.livePlayerContainer div.videoPlayer video {
  display: block;
  width: 100%;
}
@media all and (max-width: 768px) {
  div.livePlayerContainer div.videoPlayer {
    width: 100%;
  }
}
@media all and (min-width: 768px) {
  div.livePlayerContainer div.videoPlayer:hover div.bottom div.details {
    transform: translateY(0);
  }
}
div.livePlayerContainer div.videoPlayer div.bottom {
  width: 90%;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  height: 200px;
  overflow: hidden;
}
@media all and (max-width: 768px) {
  div.livePlayerContainer div.videoPlayer div.bottom {
    position: relative;
    bottom: unset;
    left: unset;
    transform: unset;
    width: 100%;
    height: auto;
  }
}
div.livePlayerContainer div.videoPlayer div.bottom div.details {
  display: flex;
  justify-content: space-between;
  height: 100%;
  transform: translateY(200px);
  transition: 0.3s ease-in-out;
  background-color: rgba(41, 36, 35, 0.6);
  padding: 20px;
  border-radius: 8px;
}
@media all and (max-width: 768px) {
  div.livePlayerContainer div.videoPlayer div.bottom div.details {
    flex-direction: column;
    transform: unset;
  }
}
div.livePlayerContainer div.videoPlayer div.bottom div.details h1.heading {
  font-size: 28px;
  color: var(--text-color);
  margin-bottom: 10px;
}
@media all and (max-width: 768px) {
  div.livePlayerContainer div.videoPlayer div.bottom div.details h1.heading {
    font-size: 22px;
  }
}
div.livePlayerContainer div.videoPlayer div.bottom div.details h1.title {
  font-size: 20px;
  color: var(--text-hover);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.livePlayerContainer div.videoPlayer div.bottom div.details span.time {
  font-size: 14px;
  color: var(--inner-text-color);
  display: inline-block;
  margin-bottom: 10px;
}
div.livePlayerContainer div.videoPlayer div.bottom div.details p.description {
  color: var(--text-color);
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
div.livePlayerContainer div.videoPlayer div.bottom div.details div.left {
  width: 48%;
  height: 100%;
}
@media all and (max-width: 768px) {
  div.livePlayerContainer div.videoPlayer div.bottom div.details div.left {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.livePlayerContainer div.videoPlayer div.bottom div.details div.right {
  width: 48%;
  height: 100%;
}
@media all and (max-width: 768px) {
  div.livePlayerContainer div.videoPlayer div.bottom div.details div.right {
    width: 100%;
  }
}

div.liveChannelsContainer {
  background-color: var(--secondary-bg-color);
  position: relative;
  padding-top: 10%;
}
div.liveChannelsContainer div.topContainer {
  display: flex;
  justify-content: space-between;
  position: relative;
  min-height: 580px;
}
@media all and (max-width: 1200px) {
  div.liveChannelsContainer div.topContainer {
    min-height: 508px;
  }
}
div.liveChannelsContainer div.topContainer div.wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 100px;
}
@media all and (max-width: 980px) {
  div.liveChannelsContainer div.topContainer div.wrapper {
    flex-direction: column-reverse;
  }
}
div.liveChannelsContainer div.topContainer div.bgImage {
  padding: 200px 0;
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  height: 100%;
  max-height: 800px;
  position: absolute;
  z-index: 3;
  bottom: 0;
}
@media all and (max-width: 1200px) {
  div.liveChannelsContainer div.topContainer div.bgImage {
    padding: 200px 0 150px;
  }
}
@media all and (max-width: 980px) {
  div.liveChannelsContainer div.topContainer div.bgImage {
    display: none;
  }
}
div.liveChannelsContainer div.topContainer div.gradient {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
  z-index: 4;
  height: 500px;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.01), #000 94.15%);
}
div.liveChannelsContainer div.topContainer div.bannerContainer {
  position: relative;
  margin-bottom: 60px;
  width: 55%;
  margin-right: 30px;
}
@media all and (max-width: 980px) {
  div.liveChannelsContainer div.topContainer div.bannerContainer {
    width: 100%;
  }
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info {
  color: var(--text-color);
  position: relative;
  z-index: 9;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.liveLabel {
  background-color: #f52d2d;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 3px;
  width: 3.5rem;
  border-radius: 3px;
  margin-bottom: 10px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.liveLabel span {
  font-weight: 600;
  font-size: 11px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.liveLabel svg {
  width: 16px !important;
  height: 16px !important;
  margin-right: 6px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.channelLogo {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.channelLogo img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info h1.title {
  font-size: 30px;
  margin-bottom: 15px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.additionalInfo {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.additionalInfo h6.timeLeft {
  color: var(--text-color);
  font-size: 15px;
  margin-right: 15px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.additionalInfo span.caption {
  background-color: var(--text-color);
  color: var(--black-color);
  font-size: 10px;
  padding: 2px 4px;
  font-weight: 700;
  border-radius: 3px;
  outline: none;
  border: none;
  margin-right: 15px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.additionalInfo span.rating {
  background-color: hsla(226, 7%, 61%, 0.32);
  border-radius: 4px;
  color: var(--text-color);
  font-size: 12px;
  font-weight: 700;
  padding: 1px 8px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info p.description {
  max-width: 100%;
  font-size: 15px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info p.description span.readMore, div.liveChannelsContainer div.topContainer div.bannerContainer div.info p.description span.readLess {
  color: var(--button-color);
  margin-left: 6px;
  cursor: pointer;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info p.description span.readMore:hover, div.liveChannelsContainer div.topContainer div.bannerContainer div.info p.description span.readLess:hover {
  text-decoration: underline;
}
div.liveChannelsContainer div.topContainer div.videoContainer {
  right: 40px;
  z-index: 9;
  width: 70%;
  top: 90px;
  border-radius: 15px;
}
@media all and (max-width: 1600px) {
  div.liveChannelsContainer div.topContainer div.videoContainer {
    width: 70%;
  }
}
@media all and (max-width: 1400px) {
  div.liveChannelsContainer div.topContainer div.videoContainer {
    width: 60%;
  }
}
@media all and (max-width: 1200px) {
  div.liveChannelsContainer div.topContainer div.videoContainer {
    width: 60%;
  }
}
@media all and (max-width: 980px) {
  div.liveChannelsContainer div.topContainer div.videoContainer {
    margin-bottom: 20px;
    width: 100%;
  }
}
div.liveChannelsContainer div.topContainer div.videoContainer div.video-js {
  padding-top: 0 !important;
  height: 408px;
  border-radius: 15px;
}
@media all and (max-width: 1200px) {
  div.liveChannelsContainer div.topContainer div.videoContainer div.video-js {
    height: 242px;
  }
}
@media all and (max-width: 980px) {
  div.liveChannelsContainer div.topContainer div.videoContainer div.video-js {
    height: 300px;
  }
}
@media all and (max-width: 480px) {
  div.liveChannelsContainer div.topContainer div.videoContainer div.video-js {
    height: 200px;
  }
}
div.liveChannelsContainer div.topContainer div.videoContainer video {
  border-radius: 15px;
}
div.liveChannelsContainer div.wrapper {
  overflow: hidden;
}
div.liveChannelsContainer div.wrapper div.categories {
  margin-bottom: 30px;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
div.liveChannelsContainer div.wrapper div.categories::-webkit-scrollbar {
  display: none;
}
div.liveChannelsContainer div.wrapper div.categories div.buttons {
  width: 1200px;
}
div.liveChannelsContainer div.wrapper div.categories div.buttons button {
  background-color: var(--black-color);
  padding: 5px 10px;
  color: var(--text-color);
  margin-right: 10px;
  font-weight: 600;
  font-size: 13px;
  border-radius: 30px;
  cursor: pointer;
}
div.liveChannelsContainer div.wrapper div.categories div.buttons button.active {
  background-color: var(--text-color);
  color: var(--black-color);
}
div.liveChannelsContainer div.wrapper div.categories div.buttons button:last-child {
  margin-right: 0;
}
div.liveChannelsContainer div.wrapper div.scheduleTime {
  width: 90%;
  margin: 0 0 6px auto;
  display: flex;
  align-items: center;
  position: relative;
}
div.liveChannelsContainer div.wrapper div.scheduleTime div.time {
  border-left: 2px solid var(--border-color);
  padding-left: 10px;
  width: 100%;
}
div.liveChannelsContainer div.wrapper div.scheduleTime div.time span {
  color: var(--inner-text-color);
  font-size: 10px;
  font-weight: 700;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer {
  position: relative;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.rightGradient {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 10rem;
  z-index: 9;
  background: linear-gradient(95deg, rgba(0, 0, 0, 0), #000 101.15%);
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.bottomGradient {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10rem;
  z-index: 9;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.01), #000 62.15%);
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel {
  display: flex;
  align-items: center;
  width: 3000px;
  height: 75px;
  margin-bottom: 4px;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel.showTimeline div.right div.scheduledItem.active div.progress div.currentTime {
  position: absolute;
  top: -26px;
  right: 0;
  background-color: #f52d2d;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 3px 3px;
  width: 5rem;
  border-radius: 3px;
  color: var(--text-color);
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel.showTimeline div.right div.scheduledItem.active div.progress div.currentTime span {
  font-weight: 600;
  font-size: 11px;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel.showTimeline div.right div.scheduledItem.active div.progress div.currentTime div.icon {
  line-height: 0;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel.showTimeline div.right div.scheduledItem.active div.progress div.currentTime div.icon svg {
  width: 16px !important;
  height: 16px !important;
  margin-right: 6px;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.left {
  width: 5%;
  margin-right: 4px;
  height: 100%;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.left div.currentPlayingCard {
  width: 100%;
  height: 100%;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.left div.currentPlayingCard div.imageContainer {
  width: 100%;
  height: 100%;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.left div.currentPlayingCard div.imageContainer img {
  display: block;
  width: 100%;
  border-radius: 4px;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right {
  display: flex;
  align-items: center;
  width: 95%;
  height: 100%;
  position: relative;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem {
  background-color: rgb(25, 25, 25);
  padding: 25px 20px;
  border-radius: 4px;
  margin-right: 4px;
  height: 100%;
  align-items: center;
  position: relative;
  cursor: pointer;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem.active {
  border: 2px solid var(--text-hover);
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem.active div.progress {
  display: block;
  position: absolute;
  background: linear-gradient(95deg, rgba(25, 25, 25, 0), #363434 101.15%);
  width: 10%;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem.active div.progress div.currentTime {
  display: none;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem:hover div.metaData {
  display: flex;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem div.progress {
  display: none;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem h1 {
  font-size: 16px;
  color: var(--text-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  z-index: 9;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem div.metaData {
  display: none;
  justify-content: space-between;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem div.metaData p.description {
  position: absolute;
  z-index: 99;
  color: var(--inner-text-color);
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem div.metaData div.infoIcon {
  text-align: right;
  position: absolute;
  z-index: 9999;
  right: 10px;
  top: 10px;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem div.metaData div.infoIcon svg {
  color: var(--inner-text-color);
  width: 20px !important;
  height: 20px !important;
}

div.magazineCardContainer {
  width: 100%;
  cursor: pointer;
  position: relative;
}
div.magazineCardContainer.noPointer {
  cursor: default;
}
div.magazineCardContainer div.showMoreOverlay {
  display: none;
}
div.magazineCardContainer.cardHover:hover .square {
  position: absolute;
  z-index: 9;
  transform: translateX(0) translateY(0) scale(1.1);
}
div.magazineCardContainer.cardHover:hover .square div.metaData {
  display: block;
  visibility: visible !important;
  animation: fadeIn 0.3s ease-in-out;
}
div.magazineCardContainer.cardHover:not(:hover) .square {
  transform: none;
}
div.magazineCardContainer.cardHover:not(:hover) div.metaData {
  display: none;
  visibility: hidden;
}
div.magazineCardContainer .square {
  width: 100%;
  transition: transform 500ms ease-out, border-radius 200ms ease-out;
}
div.magazineCardContainer .square.hide div.metaData {
  display: none;
}
@media all and (max-width: 980px) {
  div.magazineCardContainer .square.hide div.metaData {
    display: block;
  }
}
div.magazineCardContainer .square div.metaData {
  display: none;
}
div.magazineCardContainer .square div.metaData.hovered {
  display: block;
  visibility: visible !important;
  animation: fadeIn 0.3s ease-in-out;
}
@media all and (max-width: 980px) {
  div.magazineCardContainer .square div.metaData {
    display: block;
  }
}
div.magazineCardContainer .square div.imageContainer {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
div.magazineCardContainer .square div.imageContainer img {
  width: 100%;
  display: block;
  transition: 0.2s ease-in-out;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
div.magazineCardContainer .square div.imageContainer div.extraActions {
  visibility: hidden;
  animation: fadeOut 0.2s ease-in-out forwards;
}
div.magazineCardContainer .square div.imageContainer div.extraActions div.showCardOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--overlay);
  z-index: 8;
}
div.magazineCardContainer .square div.metaData {
  background: #181818;
  color: #fff;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 8px 10px;
  box-sizing: border-box;
  visibility: hidden;
  transition: 0.9s ease-in-out;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
@media all and (max-width: 980px) {
  div.magazineCardContainer .square div.metaData {
    visibility: visible;
  }
}
div.magazineCardContainer .square div.metaData h1.title {
  font-size: 11px;
  color: var(--text-color);
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.magazineCardContainer .square div.metaData h1.synopsis {
  font-size: 11px;
  color: var(--text-color);
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.magazineCardContainer .square div.metaData div.buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 10px;
}
div.magazineCardContainer .square div.metaData div.buttons span.material-symbols-outlined {
  font-weight: 300 !important;
  font-size: 20px !important;
}
div.magazineCardContainer .square div.metaData div.buttons svg {
  width: 100% !important;
  height: 15px !important;
}
div.magazineCardContainer .square div.metaData div.buttons button {
  color: var(--white-color);
  background-color: var(--button-color);
  width: 50%;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  font-size: 10px;
}
div.magazineCardContainer .square div.metaData div.buttons button.play {
  background-color: var(--white-color);
  margin-right: 4px;
  transition: 0.2s ease-in-out;
}
div.magazineCardContainer .square div.metaData div.buttons button.play:hover {
  background-color: var(--primary-btn-hover);
}
div.magazineCardContainer .square div.metaData div.buttons button.play svg {
  color: var(--black-color);
}
div.magazineCardContainer .square div.metaData div.buttons button:hover {
  background-color: var(--secondary-hover-color);
}
div.magazineCardContainer .square div.metaData div.buttons div.left {
  display: flex;
  align-items: center;
}

div.trailerModal div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.trailerModal div.head {
  position: fixed;
  z-index: 9999;
  color: var(--white-color);
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  top: 20px;
  left: 0;
}
div.trailerModal div.head div.left h4.heading {
  font-size: 13px;
  color: var(--white-color);
}
div.trailerModal div.head div.left h1.title {
  font-size: 22px;
  text-transform: uppercase;
  color: var(--white-color);
}
div.trailerModal div.head div.left span.director {
  font-weight: 700;
  font-size: 14px;
  margin-right: 5px;
  color: var(--white-color);
}
div.trailerModal div.head div.left span.year {
  font-weight: normal;
  font-size: 13px;
  color: var(--white-color);
}
div.trailerModal div.head div.right div.closeIcon {
  cursor: pointer;
}
div.trailerModal div.head div.right div.closeIcon svg {
  stroke: var(--white-color) !important;
}
div.trailerModal div.trailerPlayer {
  z-index: 9999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  width: 50%;
  margin: 0 auto;
}
@media all and (max-width: 1400px) {
  div.trailerModal div.trailerPlayer {
    top: 50%;
    width: 60%;
  }
}
@media all and (max-width: 1200px) {
  div.trailerModal div.trailerPlayer {
    top: 50%;
    width: 60%;
  }
}
@media all and (max-width: 980px) {
  div.trailerModal div.trailerPlayer {
    top: 45%;
    width: 70%;
  }
}
@media all and (max-width: 480px) {
  div.trailerModal div.trailerPlayer {
    width: 95%;
  }
}
div.trailerModal div.trailerPlayer video {
  width: 100%;
  display: block;
}

div.autoplayComponent {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #000;
  height: 100%;
  z-index: 9;
}
div.autoplayComponent div.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
div.autoplayComponent div.content div.progressBar {
  width: 100px;
  height: 100px;
}
div.autoplayComponent div.content span.timer {
  display: inline-block;
  margin-top: 10px;
  font-weight: 600;
  color: var(--white-color);
}

div.socialLoginModal div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.socialLoginModal div.closeIconContainer {
  position: fixed;
  top: 20px;
  right: 30px;
  z-index: 9999999;
}
div.socialLoginModal div.closeIconContainer svg {
  width: 20px !important;
  height: 20px !important;
}
div.socialLoginModal div.contents {
  z-index: 9999999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.socialLoginModal div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.socialLoginModal div.contents {
    width: 80%;
  }
}
@media all and (max-width: 480px) {
  div.socialLoginModal div.contents {
    width: 90%;
  }
}
div.socialLoginModal div.contents h1.error {
  font-size: 20px;
  color: #E72A31;
  margin-bottom: 10px;
}
div.socialLoginModal div.contents p {
  color: var(--white-color);
  font-size: 16px;
}
div.socialLoginModal div.contents h1 {
  color: var(--white-color);
  font-size: 18px;
}
@media all and (max-width: 768px) {
  div.socialLoginModal div.contents h1 {
    font-size: 16px;
  }
}
div.socialLoginModal div.contents div.buttonContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
div.socialLoginModal div.contents div.buttonContainer button {
  background-color: var(--button-color);
  padding: 10px 12px;
  color: var(--text-color);
  font-size: 13px;
  cursor: pointer;
  width: 10rem;
  border-radius: 5px;
}
div.socialLoginModal div.contents div.buttonContainer button:first-child {
  margin-right: 6px;
}

:root {
  --grey-color:#9699a3;
  --text-hover:#188fcb;
  --bg-color:#10141f;
  --secondary-bg-color:#1c1f29;
  --overlay:rgba(16,20,31,.64);
  --text-color:#fff;
  --border-color:rgba(150,153,163,.16);
  --inner-text-color:#9699a3;
  --success-color:#4BB543;
  --error-color:#E72A31;
  --yellow-color:#f8d406;
  --black-color:#000;
  --button-hover:#188fcb;
  --button-color:#f00;
  --secondary-btn-color:#272727;
  --grey-color: #f5f5f5;
  --white-color:#fff;
}

div.mainContainer {
  display: flex;
}
div.mainContainer div.menuContainer {
  display: flex;
  width: 5%;
  background-color: var(--bg-color);
}
@media all and (max-width: 1400px) {
  div.mainContainer div.menuContainer {
    width: 6%;
  }
}
@media all and (max-width: 1200px) {
  div.mainContainer div.menuContainer {
    width: 7%;
  }
}
@media all and (max-width: 980px) {
  div.mainContainer div.menuContainer {
    width: 9%;
  }
}
@media all and (max-width: 768px) {
  div.mainContainer div.menuContainer {
    width: 10%;
  }
}
@media all and (max-width: 640px) {
  div.mainContainer div.menuContainer {
    width: 0;
  }
}
div.mainContainer div.bodyContainer {
  width: 95%;
}
@media all and (max-width: 1400px) {
  div.mainContainer div.bodyContainer {
    width: 94%;
  }
}
@media all and (max-width: 1200px) {
  div.mainContainer div.bodyContainer {
    width: 93%;
  }
}
@media all and (max-width: 980px) {
  div.mainContainer div.bodyContainer {
    width: 91%;
  }
}
@media all and (max-width: 768px) {
  div.mainContainer div.bodyContainer {
    width: 90%;
  }
}
@media all and (max-width: 640px) {
  div.mainContainer div.bodyContainer {
    width: 100%;
  }
}

div.menuHide div.menuContainer {
  flex: 0;
  width: 0%;
  background-color: var(--bg-color);
}
div.menuHide div.menuContainer div.menubarMinContainer {
  display: none;
}
div.menuHide div.bodyContainer {
  flex: 0;
  width: 100%;
  margin-top: -2%;
}
@media all and (max-width: 640px) {
  div.menuHide div.bodyContainer {
    width: 100%;
  }
}

.wrapper {
  width: 92%;
  margin: 0 auto;
}
@media all and (max-width: 2500px) {
  .wrapper {
    margin: 30px auto;
  }
}
@media all and (max-width: 980px) {
  .wrapper {
    width: 95%;
  }
}
@media all and (max-width: 768px) {
  .wrapper {
    width: 90%;
  }
}
@media all and (max-width: 640px) {
  .wrapper {
    width: 85%;
  }
}

.swiper-button-disabled {
  display: none !important;
}

.swiper-button-next {
  width: 45px !important;
}
.swiper-button-next::after {
  background: rgb(16, 20, 31);
  border-radius: 50%;
  font-size: 16px;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--inner-text-color);
}
@media all and (min-width: 320px) {
  .swiper-button-next::after {
    width: 25px;
    height: 25px;
    font-size: 12px !important;
  }
}
@media all and (min-width: 1200px) {
  .swiper-button-next::after {
    font-size: 16px !important;
    width: 45px;
    height: 45px;
  }
}

.swiper-button-prev {
  width: 45px !important;
}
.swiper-button-prev::after {
  background: rgb(16, 20, 31);
  border-radius: 50%;
  font-size: 16px;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--inner-text-color);
}
@media all and (min-width: 320px) {
  .swiper-button-prev::after {
    width: 25px;
    height: 25px;
    font-size: 12px !important;
  }
}
@media all and (min-width: 1200px) {
  .swiper-button-prev::after {
    font-size: 16px !important;
    width: 45px;
    height: 45px;
  }
}

span.error {
  font-size: 14px;
  color: #E72A31;
  display: block;
  margin-top: 10px;
  font-weight: normal !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}/*# sourceMappingURL=app.css.map */