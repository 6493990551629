div.termsAndConditions{
    padding: 130px 0;
    @media all and (max-width:2500px){
        padding: 185px 0;
      }
    @media all and (max-width:1980px){
        padding: 165px 0;
      }
    @media all and (max-width:1600px){
        padding: 140px 0;
      }
    @media all and (max-width:1400px){
        padding: 135px 0;
      }
}