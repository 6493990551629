div.aboutUs{
   padding: 130px 0;
   background-color: var(--bg-color);
   @media all and (max-width: 2600px) {
    padding: 250px 0;
  }
  @media all and (max-width: 2500px) {
    padding: 220px 0;
  }
  h1{
    margin-left: 10px;
  }
    h1.noData{
        text-align: center;
        color: var(--heading-color);
        margin-left: 10px;
    }
    @media all and (max-width: 2600px) {
        padding: 250px 0;
      }
      @media all and (max-width: 2500px) {
        padding: 220px 0;
      }
    @media all and (max-width: 1600px) {
      padding: 165px 0;
        
      }
}
