div.showCardContainer {
  width: 100%;
  position: relative;
  div.showsSubscriptions {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    @media all and (max-width:1200px){
        display: none;
      }
    div.items {
      width: 5rem;
      margin-right: 10px;
      background-color: var(--yellow-color);
      padding: 4px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      font-weight: 600;
      color: var(--text-color);
      margin-bottom: 10px;
      border-radius: 30px;
      &.free {
        background-color: var(--success-color);
      }
      @media all and (max-width:1200px){
        font-size: 8px;
        width: 4rem;
      }
    }
  }
  &.showContents{
    cursor: pointer;
  }
  div.premiumStatus {
    div.premium {
      position: absolute;
      top: 6px;
      left: 6px;
      z-index: 8;
      background-color: rgba(8, 25, 43, 0.65);
      // padding: 6px 6px 3px;
      width: 35px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      @media all and (max-width: 1200px) {
        width: 26px;
      }
      svg {
        width: 18px !important;
        height: 18px !important;
        @media all and (max-width: 1200px) {
          width: 12px !important;
          heigth: 12px !important;
        }
      }
    }
    div.free {
      position: absolute;
      top: 6px;
      left: 6px;
      z-index: 8;
      width: 50px;
      @media all and (max-width: 1200px) {
        width: 30px;
      }
      img {
        display: block;
        width: 100%;
      }
    }
    div.lock {
      position: absolute;
      top: 6px;
      left: 6px;
      z-index: 8;
      width: 20px;
      @media all and (max-width: 1200px) {
        width:15px;
      }
      img {
        display: block;
        width: 100%;
      }
    }
  }
  div.videoCard{
    justify-content: space-between;
    align-items: center;
    width: 100%;  
    div.imageContainer{
        width: 100%;
        img{
        width: 100%;
        display: block;
        border-radius: 10px;
        @media all and (max-width:640px){
            border-radius: 0px;
        }
        @media all and (max-width:480px){
            border-radius: 0px;     
        }
        @media all and (max-width:360px){
            border-radius: 0px;     
        }
        
        }      
    }
    div.videoCard__info{
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-left: 5px;
        @media all and (max-width:480px){
            margin-left: 0px;
        }
        @media all and (max-width:360px){
            margin-left: 0px;
            margin-top: 5px;
        }
        .videoCard__avatar{
            height: 40px ;
            width: 40px ;
            @media all and (max-width:980px){
                height: 30px ;
                width: 30px ;
            }
            @media all and (max-width:480px){
                height: 28px ;
                width: 28px ;
            }
            @media all and (max-width:360px){
                height: 25px ;
                width: 25px ;
            }
        }  
        div.videoCard__text{
            margin-left: 15px;
            @media all and (max-width:980px){
                margin-left: 5px;
            }
            @media all and (max-width:360px){
               margin-left: 2px;
            }
            h4{
                font-size: 15px;
                color: var(--text-color);
                @media all and (max-width:980px){
                    font-size: 11px;
                }
                 
                @media all and (max-width:640px){
                    font-size: 10px;
                }
                @media all and (max-width:480px){
                    font-size: 9px;
                }
                @media all and (max-width:360px){
                    font-size: 8px;
                }    
            }
            p{
                font-size: 13px;
                color:var(--grey-color);
                @media all and (max-width:980px){
                    font-size: 11px;
                }
                @media all and (max-width:480px){
                    font-size: 9px;
                }
                @media all and (max-width:360px){
                    font-size: 8px;
                } 
                
            }
        }       
    }    
}
 



 
  // div.imageContainer {
  //   width: 100%;
  //   display: block;
  //   position: relative;
  //   overflow: hidden;
  //   border-radius: 3px;
  //   img {
  //     width: 100%;
  //     display: block;
  //     border-radius: 3px;
  //     transition: 0.2s ease-in-out;
  //   }
  //   div.continueWatchingBar{
  //     position: absolute;
  //     bottom: 0;
  //     height: 6px;
  //     background-color: var(--text-color);
  //     width: 100%;
  //     div.line{
  //       height: 100%;
  //       background-color: var(--button-hover);
  //     }
  //   }
  //   div.extraActions {
  //       visibility: hidden;
  //       animation: fadeOut 0.2s ease-in-out forwards;

  //     div.showCardOverlay {
  //       position: absolute;
  //       top: 0;
  //       bottom: 0;
  //       right: 0;
  //       left: 0;
  //       background-color: var(--overlay);
  //       z-index: 8;
  //     }
  //     div.playIconContainer {
  //       position: absolute;
  //       z-index: 9;
  //       top: 50%;
  //       left: 50%;
  //       transform: translate(-50%, -50%);
  //       border: 3px solid var(--text-color);
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //       width: 60px;
  //       height: 60px;
  //       border-radius: 50%;
  //       transition: 0.3s ease-in-out;
  //       cursor: pointer;
  //       @media all and (min-width: 320px) {
  //         width: 30px;
  //         height: 30px;
  //         svg {
  //           width: 13px !important;
  //           height: 25px !important;
  //         }
  //       }
  //       @media all and (min-width: 320px) {
  //         width: 50px;
  //         height: 50px;
  //       }
  //       &:hover {
  //         border-color: var(--text-hover);
  //         svg {
  //           color: var(--text-hover);
  //         }
  //       }
  //       svg {
  //         width: 13px !important;
  //         height: 13px !important;
  //         color: var(--text-color);
  //         transition: 0.3s ease-in-out;
  //         @media all and (min-width: 320px) {
  //           width: 13px !important;
  //           height: 13px !important;
  //         }
  //         @media all and (min-width: 480px) {
  //           width: 25px !important;
  //           height: 18px !important;
  //         }
  //       }
  //     }
  //     div.addToList {
  //       position: absolute;
  //       bottom: 3px;
  //       width: 96%;
  //       z-index: 9;
  //       left: 50%;
  //       border-radius: 8px;
  //       transform: translateX(-50%);
  //       padding: 6px;
  //       text-align: center;
  //       box-shadow: rgb(62 1 1 / 3%) 0px 16px 24px;
  //       background-color: rgb(49 46 46 / 30%);
  //       backdrop-filter: blur(8px);
  //       @media all and (min-width: 320px) {
  //         padding: 0;
  //         border-radius: 2px;
  //         width: 97%;
  //         span {
  //           font-size: 8px;
  //         }
  //       }
  //       @media all and (min-width: 480px) {
  //         padding: 3px;
  //         span {
  //           font-size: 10px;
  //         }
  //       }
  //       &:hover {
  //         span {
  //           color: var(--text-hover);
  //         }
  //       }
  //       span {
  //         color: var(--text-color);
  //         font-size: 10px;
  //         transition: 0.3s ease-in-out;
  //         @media all and (min-width: 480px) {
  //           font-size: 11px;
  //         }
  //       }
  //     }
  //   }
  // }
  
}
