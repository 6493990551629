div.headerCategories{
    padding:0 20px 30px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: fixed;
    top: 15%;
    z-index: 10;

    // transform: translateX(-50%);
    // left: 53%;
    background-color: var(--bg-color);
    .swiper{
        @media all and (max-width:980px){
            overflow: visible !important;
        }
        .swiper-slide {
            @media all and (max-width:980px){
                width: unset !important;
            }
        }
        .swiper-button-prev{
            width: 83px !important;
            left:-7px;
            &::after{
                background: linear-gradient(90deg, rgba(16,20,31,1) 0%, rgba(16,20,31,1) 50%, rgba(16,20,31,0.16299019607843135) 100%);
                border-radius: unset;
                width: 90px !important;
                @media all and (max-width:1200px){
                    height: 40px;
                }
            }
            @media all and (max-width:980px){
                display: none;
            }
        }
        .swiper-button-next{
            // right: -5px !important;
            width: 83px !important;
            right: 48px !important;
            @media all and (max-width:980px){
                display: none;
            }
            &::after{
                background: linear-gradient(90deg, rgba(16,20,31,0.38147759103641454) 0%, rgba(16,20,31,1) 50%, rgba(16,20,31,1) 100%);
                border-radius: unset;
                // background-color: transparent !important;
                width: 90px !important;
                @media all and (max-width:1200px){
                    height: 40px;
                }
            }
        }
    }
    button{
        color: var(--text-color);
        background-color: var(--button-color);
        padding: 8px 15px;
        border-radius: 10px;
        width: 13rem;
        cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media all and (max-width:1600px){
        width: 11rem;
    }
    @media all and (max-width:1400px){
        width: 10rem;
    }
    @media all and (max-width:1200px){
        width: 8rem;
    }
        @media all and (max-width:980px){
            width: 9rem;
        }
        @media all and (max-width:480px){
            width: 8rem;
        }
        &:hover{
            transform: scale(1.0476190476,1.0357142857);
        }
    }
}