div.commentsContainer {
    width: 70%;
    margin: 0 auto;
    padding: 60px 0;
    @media all and (max-width:1200px){
      width: 80%;
    }
    @media all and (max-width:768px){
      width: 90%;
    }
    @media all and (max-width:640px){
      width: 100%;
    }
    div.top {
      div.head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--border-color);
        padding-bottom: 10px;
        span.commentsCount {
          color: var(--white-color);
          font-size: 22px;
          @media all and (max-width:640px){
            font-size: 16px;
          }
        }

        div.sortContainer {
          select {
            background-color: transparent;
            border: 1px solid var(--border-color);
            padding: 10px 15px;
            color: var(--white-color);
            font-size: 16px;
            border-radius: 6px;
            option {
              color: var(--black-color);
            }
          }
        }
      }
      
      div.textContainer {
        display: flex;
        padding: 40px 0 20px;
        border-bottom: 1px solid var(--border-color);

        div.imageContainer {
          margin-right: 20px;
          width: 45px;
          height: 45px;
            img{
              height: 100%;
              width: 100%;
              border-radius: 50%;
            }
          span.nameAvatar {
            width: 45px;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--white-color);
            background-color: green;
            border-radius: 50%;
          }
        }
        div.inputContainer {
          width: 90%;
          textarea {
            display: block;
            width: 100%;
            height: 45px;
            padding: 10px 15px;
            border-radius: 5px;
            margin-bottom: 20px;
            &:focus-visible {
              border: 3px solid var(--button-color) !important;
              outline: none;
            }
          }
          div.actions {
            display: flex;
            justify-content: space-between;

            p {
              font-size: 14px;
              color: var(--text-color);
              span {
                color: var(--grey-color);
                cursor: pointer;
                &:hover {
                  text-decoration: underline;
                }
              }
            }

            div.buttonContainer {
              button.submit {
                background-color: var(--button-color);
                color: var(--white-color);
                font-weight: 600;
                padding: 10px 20px;
                border-radius: 6px;
                font-size: 14px;
                cursor: pointer;
                border: 1px solid var(--button-color);
                transition: 0.4s ease-in-out;
                &:hover {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }

    div.bottom {
      div.comments {
        div.eachComment{
          border-bottom: 1px solid var(--border-color);
          &:last-child{
            border: none;
          }
          div.containers {
            padding: 40px 0 25px;
            
            div.replyMessagesContainer {
              padding: 40px 0 0;
             
              div.containers{
                padding: 0 0 0;
                display: flex;
                border: none;
              }
             
            }
            div.commentItem {
              display: flex;
              &:last-child{
                border: none;
              }
    
              div.left {
                width: 45px;
                margin-right: 20px;
                div.profileImageContainer{
                  width: 100%;
                  height: 45px;
                  @media all and (max-width:640px){
                    height: 41px;
                  }
                  img{
                    width: 100%;
                    display: block;
                    height: 100%;
                  border-radius: 50%;

                  }
                }
                span.nameAvatar {
                  width: 100%;
                  height: 45px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: var(--white-color);
                  background-color: green;
                  border-radius: 50%;
                }
              }
    
              div.right {
                width: 90%;
                @media all and (max-width:640px){
                  width: 96%;
                }
                h1.name {
                  font-size: 16px;
                  color: var(--white-color);
                }
    
                span.time {
                  font-size: 13px;
                  color: var(--inner-text-color);
                  font-weight: 600;
                  display: inline-block;
                  margin-bottom: 20px;
                }
    
                p.comment {
                  font-size: 16px;
                  color: var(--white-color);
                  margin-bottom: 20px;
                }
    
                div.actions {
                  display: flex;
                  span.item {
                    display: flex;
                    align-items: center;
                    margin-right: 20px;
                    font-size: 11px;
                    color: var(--inner-text-color);
                    transition: 0.4s ease-in-out;
                    cursor: pointer;
                    &:hover {
                      opacity: 0.6;
                    }
                    &:last-child {
                      margin-right: 0;
                    }
                    span {
                      svg {
                        width: 15px !important;
                        height: 15px !important;
                        color: rgb(190, 190, 187);
                        fill: rgb(190, 190, 187);
                        margin-right: 5px;
                      }
                    }
                  }
                }
              }
            }
           
          }

        }
      }
    }
  }