div.relet_video_container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  cursor: pointer;
  @media all and (max-width: 2500px) {
    width: 100%;
  }
  @media all and (max-width: 640px) {
    width: 100%;
  }
  div.video_image_container {
    width: 100%;
    @media all and (max-width: 768px) {
      width: 60%;
    }
    img {
      width: 100%;
      border-radius: 10px;
    }
  }
  div.videoCard__info {
    width: 100%;
    margin-left: 6px;
    div.videoCard__text {
      margin-left: 10px;
      overflow: hidden;
      @media all and (max-width: 1600px) {
        margin-left: 5px;
      }
      @media all and (max-width: 640px) {
        font-size: 15px;
      }
      h4 {
        font-size: 12px;
        font-family: sans-serif;
        color: var(--text-color);
        font-weight: 600;
        margin-bottom: 50px;
        @media all and (max-width: 2500px) {
          font-size: 19px;
        }
        @media all and (max-width: 2200px) {
          font-size: 17px;
          margin-bottom: 40px;
        }
        @media all and (max-width: 1980px) {
          font-size: 15px;
        }
        @media all and (max-width: 1768px) {
          font-size: 13px;
          margin-bottom: 30px;
        }
        @media all and (max-width: 1600px) {
          font-size: 12px;
          margin-bottom: 25px;
        }
        @media all and (max-width: 1400px) {
          font-size: 12px;
          margin-bottom: 10px;
        }
        @media all and (max-width: 1200px) {
          font-size: 12px;
          margin-bottom: 5px;
        }
        @media all and (max-width: 980px) {
          font-size: 14px;
          margin-bottom: 10px;
        }
        @media all and (max-width: 768px) {
          font-size: 15px;
          margin-bottom: 5px;
        }
        @media all and (max-width: 640px) {
          font-size: 15px;
          margin-bottom: 30px;
        }
        @media all and (max-width: 480px) {
          font-size: 11px;
          margin-bottom: 20px;
        }
        @media all and (max-width: 360px) {
          font-size: 10px;
          margin-bottom: 15px;
        }
      }
      p {
        font-size: 13px;
        color: gray;
        font-weight: 400;
        @media all and (max-width: 2500px) {
          font-size: 19px;
        }
        @media all and (max-width: 1980px) {
          font-size: 14px;
        }
        @media all and (max-width: 1400px) {
          font-size: 12px;
        }
        @media all and (max-width: 1200px) {
          font-size: 10px;
        }
        @media all and (max-width: 980px) {
          font-size: 13px;
        }
        @media all and (max-width: 768px) {
          font-size: 13px;
        }
        @media all and (max-width: 640px) {
          font-size: 13px;
        }
      }
      div.add_remove_icon {
        display: flex;
        margin-left: 0px;
        color: gray;
        font-size: 11px;
        div.addToList{
            display: flex;
        align-items: center;
        @media all and (max-width: 768px) {
          font-size: 13px;
        }
        }
        
      }
    }
  }
}
