div.privacyPolicy{
    padding: 130px 0;
    @media all and (max-width:2500px){
        padding: 200px 0 0 0;
      }
    @media all and (max-width:2200px){
        padding: 220px 0 0 0;
      }
    @media all and (max-width:1980px){
        padding: 170px 0 0 0;
      }
    @media all and (max-width:1600px){
        padding: 145px 0 0 0;
      }
}