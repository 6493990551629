div.paymentScreen {
    padding: 100px 0;
    background-color: var(--sub-bg-color);

    .paypal-button-container {
        min-width: 326px !important;
        max-width: 284px !important;
    }

    span.success {
        color: var(--success-color);
    }

    div.wrapper {
        @media all and (max-width:640px) {
            width: 90%;
        }

        h1.heading {
            text-align: center;
            font-size: 30px;
            color: var(--text-color);
            margin-bottom: 30px;
            text-transform: uppercase;

            @media all and (max-width:480px) {
                font-size: 25px;
            }
        }

        div.mainContainer {
            width: 90%;
            margin: 0 auto;
            display: flex;
            justify-content: center;

            //  justify-content: center;
            &.centerDiv {
                justify-content: center;
            }

            &.modifyDiv {
                justify-content: space-evenly;
            }

            @media all and (max-width:980px) {
                flex-direction: column;
                width: 80%;

                &.modifyDiv {
                    align-items: center;
                }
            }

            @media all and (max-width:640px) {
                width: 100%;
            }

            div.left {
                width: 50%;
                border-right: 2px solid var(--border-color);

                &.small {
                    width: 33.3%;

                }

                &.no-border {
                    border-right: none;
                }

                @media all and (max-width:1200px) {
                    &.small {
                        width: 40%;
                    }
                }

                @media all and (max-width:980px) {
                    width: 100%;

                    &.small {
                        width: 90%;
                        border-right: none;
                        border-bottom: 2px solid var(--border-color);
                    }
                }

                div.couponCodeContainer {

                    label {
                        font-size: 20px;
                        display: block;
                        color: var(--text-color);
                    }

                    div.inputContainer {
                        margin-top: 20px;
                        display: flex;
                        align-items: center;
                        width: 25rem;

                        &.small {
                            width: 25rem;
                        }

                        @media all and (max-width:1600px) {
                            &.small {
                                width: 23rem;
                            }
                        }

                        @media all and (max-width:1400px) {
                            width: 25rem;

                            &.small {
                                width: 21rem;
                            }
                        }

                        @media all and (max-width:1200px) {
                            width: 24rem;

                            &.small {
                                width: 18rem;
                            }
                        }

                        @media all and (max-width:980px) {
                            width: 22rem;

                            &.small {
                                width: 90%;
                            }
                        }

                        @media all and (max-width:640px) {
                            width: 90%;

                            &.small {
                                width: 90%;
                            }
                        }

                        @media all and (max-width:480px) {
                            flex-direction: column;

                            &.small {
                                flex-direction: column;
                            }
                        }

                        input {
                            outline: none;
                            border-radius: 5px;
                            font-size: 14px;
                            background: #000;
                            text-align: left;
                            padding: 20px 15px;
                            border: 1px solid transparent;
                            caret-color: var(--text-color);
                            display: block;
                            width: 100%;
                            color: var(--text-color);
                            margin-right: 20px;

                            &:focus {
                                border: 1px solid var(--text-color);
                            }

                            @media all and (max-width:640px) {
                                padding: 16px 15px;
                            }

                            @media all and (max-width:480px) {
                                margin-bottom: 20px;
                            }
                        }

                        div.buttonContainer {
                            display: flex;
                            padding: 18px 20px;
                            border-radius: 4px;
                            align-items: center;
                            cursor: pointer;
                            transition: 0.2s ease-in-out;
                            justify-content: center;
                            position: relative;
                            width: 15rem;
                            margin: 0 auto;

                            @media all and (max-width:640px) {
                                width: 15rem;
                                padding: 16px 20px;

                            }

                            @media all and (max-width:480px) {
                                width: 10rem;
                            }

                            &:hover {
                                div.background {

                                    transform: scale(1.0476190476, 1.0357142857);
                                }
                            }

                            div.background {
                                background: var(--button-color);
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                border-radius: 4px;
                                transition: 0.1s ease-in-out;
                                z-index: 1;

                            }

                            button {
                                background: transparent;
                                font-size: 15px;
                                color: var(--text-color);
                                font-weight: 600;
                                position: relative;
                                z-index: 2;
                                cursor: pointer;


                            }
                        }
                    }
                }

                div.paymentGatewayContainer {
                    margin-top: 30px;
                    margin-bottom: 20px;

                    h2.heading {
                        font-size: 20px;
                        color: var(--text-color);
                        font-weight: 400;
                    }

                    ul.gateways {
                        display: flex;
                        flex-wrap: wrap;

                        @media all and (max-width:480px) {}

                        li {
                            margin: 5px;
                            width: 90px;

                            &:last-child {
                                margin-right: 0;
                            }

                            @media all and (max-width:640px) {
                                width: 70px;

                            }

                            @media all and (max-width:480px) {
                                width: 20%;
                                // &:nth-child(2n){
                                //     margin-right: 0;
                                // }
                            }

                            div.imageContainer {
                                width: 100%;
                                transition: 0.3s ease-in-out;
                                cursor: pointer;

                                &:hover {
                                    transform: scale(1.1);
                                }

                                img {
                                    width: 100%;
                                    display: block;
                                }

                                img.ideabiz-icon {
                                    border-radius: 5px;
                                }
                            }
                        }
                    }
                }
            }

            div.center {
                width: 33.3%;
                padding: 0 40px 0;
                border-right: 2px solid var(--border-color);

                &.small {
                    width: 33.3%;
                }

                &.no-border {
                    border-right: none;
                    display: flex;
                    width: 50%;
                    justify-content: center;
                }

                @media all and (max-width:980px) {
                    width: 90%;
                    padding: 40px 0 0;
                    border-left: none;
                    border-right: none;
                    border-bottom: 2px solid var(--border-color);

                    &.small {
                        width: 90%;
                        border-right: none;
                        border-bottom: 2px solid var(--border-color);
                    }

                    &.no-border {
                        border-bottom: none;
                        width: 90%;
                        justify-content: left;
                    }

                }

                @media all and (max-width:768px) {
                    width: 100%;

                    &.small {
                        width: 90%;
                        border-right: none;
                        border-bottom: 2px solid var(--border-color);
                    }

                    &.no-border {
                        border-bottom: none;
                        width: 100%;
                        justify-content: left;
                    }
                }

                @media all and (max-width:640px) {
                    &.small {
                        width: 90%;
                        border-right: none;
                        border-bottom: 2px solid var(--border-color);
                    }

                    &.no-border {
                        border-bottom: none;
                        width: 90%;
                        justify-content: left;
                    }
                }

                iframe {
                    z-index: 2 !important;
                }

                div.paymentGatewayContainer {
                    margin-bottom: 20px;

                    h2.heading {
                        font-size: 20px;
                        color: var(--text-color);
                        font-weight: 400;
                    }

                    ul.gatewaysCinet {
                        display: flex;
                        flex-wrap: wrap;
                        margin-top: 10px;

                        @media all and (max-width:480px) {}

                        li {
                            margin-right: 20px;
                            width: 250px;

                            &:last-child {
                                margin-right: 0;
                            }

                            @media all and (max-width:640px) {
                                width: 70px;

                            }

                            @media all and (max-width:480px) {
                                width: 20%;
                                // &:nth-child(2n){
                                //     margin-right: 0;
                                // }
                            }

                            div.cinetImageContainer {
                                width: 100%;
                                transition: 0.3s ease-in-out;
                                cursor: pointer;

                                &:hover {
                                    transform: scale(1.1);
                                }

                                img {
                                    width: 100%;
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }

            div.right {
                width: 50%;
                padding: 0 40px 0;

                &.small {
                    width: 33.3%;
                }

                @media all and (max-width:980px) {
                    width: 90%;
                    padding: 40px 0 0;
                    border-left: none;

                    &.small {
                        width: 90%;
                    }
                }

                @media all and (max-width:768px) {
                    width: 100%;

                    &.small {
                        width: 90%;
                    }
                }

                iframe {
                    z-index: 2 !important;
                }
            }


        }
    }
}
