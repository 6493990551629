div.categoriesContainer {
  padding: 20px 0 8px;
  &:hover {
    .swiper-button-next {
      &::after {
        opacity: 1;
      }
    }
    .swiper-button-prev {
      &::after {
        opacity: 1;
      }
    }
  }
  .swiper {
    &.landscape {
        .swiper-button-next {
            right: -82px;
            height: 100%;
            width: 6%;
            top: 5%;
            background: linear-gradient(
              -97deg,
              rgb(28, 31, 41) 0.98%,
              rgba(28, 31, 41, 0.4) 100.23%,
              rgba(28, 31, 41, 0.2) 100.16%,
              rgba(28, 31, 41, 0.0001) 52.12%
            );
            // 
            @media all and (min-width:320px){
                display: none;
                
            }
            @media all and (min-width:980px){
                display: flex;
                width: 4%;
                right: -47px;
                top: 8%;
            }
            @media all and (min-width:1200px){
              right: -57px;
            width: 4% !important;
              top: 7%;

          }
          @media all and (min-width:1400px){
            top: 6%;

            right: -67px;
        }
        @media all and (min-width:1600px){
          right: -70px;
        }
        @media all and (min-width:1768px){
          right: -79px;
          top: 5%;
        }
        @media all and (min-width:1980px){
            right: -91px;

          }
          @media all and (min-width:2200px){
            right: -99px;
        }
            // @media all and (min-width:768px){
            //    width: 7%;
            // }
          }
          .swiper-button-prev {
            left: -77px;
            height: 100%;
            width: 5%;
            top: 5%;
            background: linear-gradient(
              -97deg,
              rgb(28, 31, 41) 0.98%,
              rgba(28, 31, 41, 0.4) 100.23%,
              rgba(28, 31, 41, 0.2) 100.16%,
              rgba(28, 31, 41, 0.0001) 52.12%
            );
            
            @media all and (min-width:320px){
                display: none;
        
            }
            @media all and (min-width:980px){
                display: flex;
                width: 4%;
                left: -47px;
                top: 8%;
            }
            @media all and (min-width:1200px){
              left: -57px;
              width: 4% !important;
              top: 7%;
          }
          @media all and (min-width:1400px){
            left: -67px;
            top: 6%;

        }
        @media all and (min-width:1600px){
          left: -70px;
        }
        @media all and (min-width:1768px){
          left: -79px;
          top: 5%;
        }
        @media all and (min-width:1980px){
            left: -91px;
        }
        @media all and (min-width:2200px){
            left: -99px;
        }
            // @media all and (min-width:768px){
            //     width: 7%;
            //  }
          }
    }
    &.portrait{

        // .swiper-button-next {
        //   &::after {
        //     background: rgb(16, 20, 31);
        //     padding: 6px;
        //     border-radius: 50%;
        //     font-size: 18px;
        //     width: 20px;
        //     height: 20px;
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     color: var(--inner-text-color);
        //     opacity: 0;
        //     transition: 0.3s ease-in-out;
            
        //   }
        // }
        // .swiper-button-prev {
        //   &::after {
        //     background: rgb(16, 20, 31);
        //     padding: 6px;
        //     border-radius: 50%;
        //     font-size: 18px;
        //     width: 20px;
        //     height: 20px;
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     color: var(--inner-text-color);
        //     opacity: 0;
        //     transition: 0.3s ease-in-out;
           
        //   }
        // }

        .swiper-button-next {
            right: -82px;
            height: 100%;
            width: 6%;
            top: 5%;
            background: linear-gradient(
              -97deg,
              rgb(28, 31, 41) 0.98%,
              rgba(28, 31, 41, 0.4) 100.23%,
              rgba(28, 31, 41, 0.2) 100.16%,
              rgba(28, 31, 41, 0.0001) 52.12%
            );
            // 
            @media all and (min-width:320px){
                display: none;
                
            }
            @media all and (min-width:980px){
                display: flex;
                width: 4%;
                right: -47px;
                top: 6%;
            }
            @media all and (min-width:1200px){
              right: -57px;
            width: 4% !important;
              top: 5%;

          }
          @media all and (min-width:1400px){

            right: -67px;
        }
        @media all and (min-width:1600px){
          right: -70px;
        }
        @media all and (min-width:1768px){
          right: -79px;
          top: 4%;
        }
        @media all and (min-width:1980px){
            right: -91px;

          }
          @media all and (min-width:2200px){
            right: -99px;
        }
            // @media all and (min-width:768px){
            //    width: 7%;
            // }
          }
          .swiper-button-prev {
            left: -77px;
            height: 100%;
            width: 5%;
            top: 5%;
            background: linear-gradient(
              -97deg,
              rgb(28, 31, 41) 0.98%,
              rgba(28, 31, 41, 0.4) 100.23%,
              rgba(28, 31, 41, 0.2) 100.16%,
              rgba(28, 31, 41, 0.0001) 52.12%
            );
            
            @media all and (min-width:320px){
                display: none;
        
            }
            @media all and (min-width:980px){
                display: flex;
                width: 4%;
                left: -47px;
                top: 5%;
            }
            @media all and (min-width:1200px){
              left: -57px;
              width: 4% !important;
              top: 5%;
          }
          @media all and (min-width:1400px){
            left: -67px;

        }
        @media all and (min-width:1600px){
          left: -70px;
        }
        @media all and (min-width:1768px){
          left: -79px;
          top: 4%;
        }
        @media all and (min-width:1980px){
            left: -91px;
        }
        @media all and (min-width:2200px){
            left: -99px;
        }
            // @media all and (min-width:768px){
            //     width: 7%;
            //  }
          }
    }
  }
  div.categoryNameContainer {
    display: inline;
    // align-items: center;
    cursor: pointer;
    width: 100%;
    &:hover {
      h1 {
        color: var(--text-hover);
      }
      svg {
        color: var(--text-hover);
      }
    }
    h1 {
      font-size: 16px;
      margin-right: 20px;
      color: var(--text-color);
      transition: 0.3s ease-in-out;
      display: inline-block;
    }
    svg {
      color: var(--text-color);
      height: 20px !important;
      width: 20px !important;
      transition: 0.3s ease-in-out;
      vertical-align: sub;
    }
  }
  div.itemsContainer {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 3px solid var(--border-color);

    div.items{
      &.rowCount3{
          width: 32.5%;
          margin-right: 13px;
          margin-bottom: 30px;
          &:nth-child(3n){
              margin-right: 0;
          }
          @media all and (max-width:1200px){
              width: 32%;
          }
      }
      &.rowCount4{
          width: 24%;
          margin-right: 13px;
          margin-bottom: 30px;
          &:nth-child(4n){
              margin-right: 0;
          }
          @media all and (max-width:1200px){
              width: 23.8%;
          }
      }
      &.rowCount5{
          width: 19%;
          margin-right: 13px;
          margin-bottom: 30px;
          &:nth-child(5n){
              margin-right: 0;
          }
          @media all and (max-width:1200px){
              width: 18.5%;
          }
      }
      &.rowCount6{
          width: 15.8%;
          margin-right: 13px;
          margin-bottom: 30px;
          &:nth-child(6n){
              margin-right: 0;
          }
          @media all and (max-width:1400px){
              width: 15.5%;
          }
          @media all and (max-width:1200px){
              width: 15%;
          }
      }
      &.portrait{
          @media all and (max-width:980px){
              width:23.5%;
              margin-right: 13px;
              margin-bottom: 40px;
              &:nth-child(4n){
                  margin-right: 0;
              }
             &:nth-child(5n){
              margin-right: 13px;
             }
          }
          @media all and (max-width:768px){
              width:23%;
              &:nth-child(4n){
                  margin-right: 0;
              }
             &:nth-child(5n){
              margin-right: 13px;
             }
          }
          @media all and (max-width:640px){
              width:31%;
              
             &:nth-child(4n){
              margin-right: 13px;
             }
             &:nth-child(3n){
              margin-right: 0;
          }
          }
          @media all and (max-width:480px){
              width:47%;
              
             &:nth-child(3n){
              margin-right: 13px;
             }
             &:nth-child(2n){
              margin-right: 0;
          }
          }
      }
      &.landscape{
      @media all and (max-width:980px){
          width:32%;
          margin-right: 13px;
      margin-bottom: 40px;
          &:nth-child(3n){
              margin-right: 0;
          }
          
      }
      @media all and (max-width:768px){
          width:47.6%;
          &:nth-child(2n){
              margin-right: 0;
          }
          &:nth-child(3n){
              margin-right: 13px;
          }
      }
      @media all and (max-width:640px){
          width:48%;
          
         &:nth-child(3n){
          margin-right: 13px;
         }
         &:nth-child(2n){
          margin-right: 0;
      }
      }
      @media all and (max-width:480px){
          width:47%;
          
         &:nth-child(3n){
          margin-right: 13px;
         }
         &:nth-child(2n){
          margin-right: 0;
      }
      }
      }

  }
  div.magazine{
    &.rowCount3{
      width: 32.5%;
      margin-right: 13px;
      margin-bottom: 30px;
      &:nth-child(3n){
          margin-right: 0;
      }
      @media all and (max-width:1200px){
          width: 32%;
      }
  }
  &.rowCount4{
    width: 20%;
    margin-right: 60px;
      margin-bottom: 30px;
      &:nth-child(4n){
          margin-right: 0;
      }
      @media all and (max-width:1200px){
        width: 20%;
      }
  }
  &.rowCount5{
      width: 19%;
      margin-right: 13px;
      margin-bottom: 30px;
      &:nth-child(5n){
          margin-right: 0;
      }
      @media all and (max-width:1200px){
          width: 18.5%;
      }
  }
  &.rowCount6{
      width: 15.8%;
      margin-right: 13px;
      margin-bottom: 30px;
      &:nth-child(6n){
          margin-right: 0;
      }
      @media all and (max-width:1400px){
          width: 15.5%;
      }
      @media all and (max-width:1200px){
          width: 15%;
      }
  }
  &.portrait{
      @media all and (max-width:980px){
          width:23.5%;
          margin-right: 13px;
          margin-bottom: 40px;
          &:nth-child(4n){
              margin-right: 0;
          }
         &:nth-child(5n){
          margin-right: 13px;
         }
      }
      @media all and (max-width:768px){
          width:23%;
          &:nth-child(4n){
              margin-right: 0;
          }
         &:nth-child(5n){
          margin-right: 13px;
         }
      }
      @media all and (max-width:640px){
          width:31%;
          
         &:nth-child(4n){
          margin-right: 13px;
         }
         &:nth-child(3n){
          margin-right: 0;
      }
      }
      @media all and (max-width:480px){
          width:47%;
          
         &:nth-child(3n){
          margin-right: 13px;
         }
         &:nth-child(2n){
          margin-right: 0;
      }
      }
  }
  &.landscape{
  @media all and (max-width:980px){
      width:32%;
      margin-right: 13px;
  margin-bottom: 40px;
      &:nth-child(3n){
          margin-right: 0;
      }
      
  }
  @media all and (max-width:768px){
      width:47.6%;
      &:nth-child(2n){
          margin-right: 0;
      }
      &:nth-child(3n){
          margin-right: 13px;
      }
  }
  @media all and (max-width:640px){
      width:48%;
      
     &:nth-child(3n){
      margin-right: 13px;
     }
     &:nth-child(2n){
      margin-right: 0;
  }
  }
  @media all and (max-width:480px){
      width:47%;
      
     &:nth-child(3n){
      margin-right: 13px;
     }
     &:nth-child(2n){
      margin-right: 0;
  }
  }
  }
  }
  div.showMoreContainer,div.showLessContainer{
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    transition: 0.4s ease-in-out;
    &:hover{
      background-color: var(--button-hover);
    }
  }
    // div.show {
    //   width: 14rem;
    //   @media all and (min-width: 320px) {
    //     width: 100%;
    //   }
    //   // @media all and (min-width:360px){
    //   //     width: 11rem;
    //   // }
    //   // @media all and (min-width:640px){
    //   //     width: 13rem;
    //   // }
    //   // @media all and (min-width:768px){
    //   //     width: 13rem;
    //   // }
    //   // @media all and (min-width:980px){
    //   //     width: 13rem;
    //   // }
    // }
  }
}
