div.otpModal{
    div.overlayOtp{
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 8;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    div.otpContainer{
        z-index: 9;
        text-align: center;
        position: fixed;
        left: 50%;
        // right: 0;
        top: 50%;
        // bottom: 0;
        transform: translate(-50%,-50%);
        background-color: var(--bg-color);
        width: 40%;
        margin: 0 auto;
        padding: 60px 60px 60px;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        @media all and (max-width:1200px){
            padding: 30px;
        }
        @media all and (max-width:768px){
            width: 60%;
        }
        @media all and (max-width:480px){
            width: 90%;
        }
        label{
            font-size: 16px;
        margin-bottom: 10px;
        color: var(--text-color);

        }
        button.bg-button{
            margin: 20px auto 0;
            width: 30%;
        }
        input{
            outline: none;
            border-radius: 5px;
            font-size: 14px;
            background: transparent;
            text-align: left;
          padding: 20px 15px;
          border: 1px solid var(--inner-text-color);
          caret-color: var(--text-color);
          margin-bottom: 10px;
          color: var(--text-color);
          &:focus{
              border: 1px solid var(--text-color);
          }
          @media all and (max-width:640px){
              padding: 16px 15px;
          }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }

/* Firefox */
        input[type=number] {
        -moz-appearance: textfield;
        }
        div.buttonContainer { 
            display: flex;
        padding: 18px 20px;
        border-radius: 4px;
        align-items: center;
        margin-bottom: 15px;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        justify-content: center;
        position: relative;
        width: 20rem;
        margin: 30px auto 0;
        @media all and (max-width:640px){
            width: 15rem;
            padding: 10px 20px;
        }
        @media all and (max-width:480px){
            width: 10rem;
        }
        &:hover{
            div.background{

                transform: scale(1.0476190476,1.0357142857);
            }
        }
            div.background{
                background: var(--text-hover);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 4px;
                transition: 0.1s ease-in-out;
                z-index: 1;

            }
            button { 
                background: transparent;
                font-size: 15px;
                color: var( --text-color);
                font-weight: 600;
                position: relative;
                z-index: 2;
                cursor: pointer;


            }
        }

        div.buttonsContainer {
            display: flex;
            flex-direction: row;
            justify-content: center;

            button {
                background: var(--button-color);
                color: var(--white-color);
                position: relative;
                z-index: 999;
                cursor: pointer;
                width: 100px;
                height: 40px;
                border: transparent;
                border-radius: 3px;
                font-size: medium;
                font-weight: 400;
                margin: 5px;

                &:hover {
                    background: var(--white-color);
                    color: var(--button-color);
                }

            }

            .cancel {
                background: transparent;
                border: 1px solid;

                &:hover {
                    color: rgba(0, 0, 0, 0.7);
                }
            }
        }
    }
}
