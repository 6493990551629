div.commonPage {
    padding: 130px 0 40px;
    @media all and (max-width: 2500px) {
        padding: 220px 0 40px;
      }
    @media all and (max-width: 1768px) {
        padding: 180px 0 40px;
      }
    div.top {
      padding-bottom: 60px;
      position: absolute;
      top: 60px;
      z-index: 8;
      left: 60px;
      @media all and (max-width: 2500px) {
        top: 180px;
        left: 205px;
      }
      @media all and (max-width: 1980px) {
        left: 165px;
      }
      @media all and (max-width: 1768px) {
        top: 150px;
        left: 141px;
      }
      @media all and (max-width: 1200px) {
        top: 135px;
        left: 111px;
      }
      @media all and (max-width: 640px) {
        top: 108px;
        z-index: 8;
        left: 49px;
        }
    @media all and (max-width: 480px) {
        left: 33px;
            }
      h1.heading {
        font-size: 26px;
        margin-bottom: 10px;
        margin-top: 20px;
        color: var(--text-color);
        @media all and (max-width: 640px) {
          font-size: 22px;
          margin-top: 50px;
        }
      }
      p.desc {
        font-size: 18px;
        color: var(--inner-text-color);
        @media all and (max-width: 640px) {
          font-size: 14px;
        }
      }
    }
    div.bgImageContainer {
      width: 100%;
      display: block;
      height: 508px;
      position: relative;
      @media all and (max-width: 768px) {
        height: 556px;
      }
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media all and (max-width: 768px) {
          object-fit: cover;
        }
      }
    }
    div.bottomGradient {
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 1;
      height: 100%;
      background-position: top;
      background-size: cover;
      background-repeat: no-repeat;
      background: linear-gradient(
          78.83deg,
          rgb(16, 20, 31) 0%,
          rgba(16, 20, 31, 0) 61.46%
        ),
        linear-gradient(
          360deg,
          rgb(16, 20, 31) 0%,
          rgba(16, 20, 31, 0) 48.44%,
          rgba(16, 20, 31, 0.4) 100%
        );
  
  
    }
    div.bottom {
      margin-top: 40px;
      div.itemsContainer {
        display: flex;
        flex-wrap: wrap;
        div.items{
          &.rowCount3{
              width: 32.5%;
              margin-right: 13px;
              margin-bottom: 30px;
              &:nth-child(3n){
                  margin-right: 0;
              }
              @media all and (max-width:1200px){
                  width: 32%;
              }
          }
          &.rowCount4{
              width: 24%;
              margin-right: 13px;
              margin-bottom: 30px;
              &:nth-child(4n){
                  margin-right: 0;
              }
              @media all and (max-width:1200px){
                  width: 23.8%;
              }
          }
          &.rowCount5{
              width: 19%;
              margin-right: 13px;
              margin-bottom: 30px;
              &:nth-child(5n){
                  margin-right: 0;
              }
              @media all and (max-width:1200px){
                  width: 18.5%;
              }
          }
          &.rowCount6{
              width: 15.8%;
              margin-right: 13px;
              margin-bottom: 30px;
              &:nth-child(6n){
                  margin-right: 0;
              }
              @media all and (max-width:1400px){
                  width: 15.5%;
              }
              @media all and (max-width:1200px){
                  width: 15%;
              }
          }
          &.portrait{
            
              @media all and (max-width:980px){
                  width:23.5%;
                  margin-right: 13px;
                  margin-bottom: 40px;
                  &:nth-child(4n){
                      margin-right: 0;
                  }
                 &:nth-child(5n){
                  margin-right: 13px;
                 }
              }
              @media all and (max-width:768px){
                  width:23%;
                  &:nth-child(4n){
                      margin-right: 0;
                  }
                 &:nth-child(5n){
                  margin-right: 13px;
                 }
              }
              @media all and (max-width:640px){
                  width:31%;
                  
                 &:nth-child(4n){
                  margin-right: 13px;
                 }
                 &:nth-child(3n){
                  margin-right: 0;
              }
              }
              @media all and (max-width:480px){
                  width:47%;
                  
                 &:nth-child(3n){
                  margin-right: 13px;
                 }
                 &:nth-child(2n){
                  margin-right: 0;
              }
              }
          }
          &.landscape{
        
          @media all and (max-width:980px){
              width:32%;
              margin-right: 13px;
          margin-bottom: 40px;
              &:nth-child(3n){
                  margin-right: 0;
              }
              
          }
          @media all and (max-width:768px){
              width:47.6%;
              &:nth-child(2n){
                  margin-right: 0;
              }
              &:nth-child(3n){
                  margin-right: 13px;
              }
          }
          @media all and (max-width:640px){
              width:48%;
              
             &:nth-child(3n){
              margin-right: 13px;
             }
             &:nth-child(2n){
              margin-right: 0;
          }
          }
          @media all and (max-width:480px){
              width:47%;
              
             &:nth-child(3n){
              margin-right: 13px;
             }
             &:nth-child(2n){
              margin-right: 0;
          }
          }
          }

      }
      div.magazine{
        &.rowCount3{
          width: 32.5%;
          margin-right: 13px;
          margin-bottom: 30px;
          &:nth-child(3n){
              margin-right: 0;
          }
          @media all and (max-width:1200px){
              width: 32%;
          }
      }
      &.rowCount4{   
          width: 19%;
          margin-right: 13px;
          margin-bottom: 30px;
          &:nth-child(5n){
              margin-right: 0;
          }
    
      }
      &.rowCount5{
          width: 19%;
          margin-right: 13px;
          margin-bottom: 30px;
          &:nth-child(5n){
              margin-right: 0;
          }
          @media all and (max-width:1200px){
              width: 18.5%;
          }
      }
      &.rowCount6{
          width: 15.8%;
          margin-right: 13px;
          margin-bottom: 30px;
          &:nth-child(6n){
              margin-right: 0;
          }
          @media all and (max-width:1400px){
              width: 15.5%;
          }
          @media all and (max-width:1200px){
              width: 15%;
          }
      }
      &.portrait{
        
          @media all and (max-width:980px){
              width:23.5%;
              margin-right: 13px;
              margin-bottom: 40px;
              &:nth-child(4n){
                  margin-right: 0;
              }
             &:nth-child(5n){
              margin-right: 13px;
             }
          }
          @media all and (max-width:768px){
              width:23%;
              &:nth-child(4n){
                  margin-right: 0;
              }
             &:nth-child(5n){
              margin-right: 13px;
             }
          }
          @media all and (max-width:640px){
              width:31%;
              
             &:nth-child(4n){
              margin-right: 13px;
             }
             &:nth-child(3n){
              margin-right: 0;
          }
          }
          @media all and (max-width:480px){
              width:47%;
              
             &:nth-child(3n){
              margin-right: 13px;
             }
             &:nth-child(2n){
              margin-right: 0;
          }
          }
      }
      &.landscape{
    
      @media all and (max-width:980px){
          width:32%;
          margin-right: 13px;
      margin-bottom: 40px;
          &:nth-child(3n){
              margin-right: 0;
          }
          
      }
      @media all and (max-width:768px){
          width:47.6%;
          &:nth-child(2n){
              margin-right: 0;
          }
          &:nth-child(3n){
              margin-right: 13px;
          }
      }
      @media all and (max-width:640px){
          width:48%;
          
         &:nth-child(3n){
          margin-right: 13px;
         }
         &:nth-child(2n){
          margin-right: 0;
      }
      }
      @media all and (max-width:480px){
          width:47%;
          
         &:nth-child(3n){
          margin-right: 13px;
         }
         &:nth-child(2n){
          margin-right: 0;
      }
      }
      }
      }
      }
    }
  }
  
