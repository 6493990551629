div.loading{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: var(--bg-color);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    // flex-direction: column;
    div.loadingOverlay{
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: var(--bg-color);
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      div.loadingText{
        display: flex;
        align-items: flex-end;
          z-index: 999;
          h1{
            color: var(--inner-text-color);
            margin-right: 10px;
            font-size: 28px;
          }
          span{
            margin-right: 10px;
            color: var(--inner-text-color);
            animation: blinking 0.6s ease-in-out infinite;

            &:nth-child(odd){
                color: var(--text-hover);
            }
            &:nth-child(4){
            animation: blinking 0.7s ease-in-out infinite;
                
            }

            &:last-child{
                margin-right: 0;
            }
          }
      }
      .loader {
        width: 8px;
        height: 40px;
        border-radius: 4px;
        display: block;
        margin: 20px auto;
        position: relative;
        background: currentColor;
        color: #fff;
        box-sizing: border-box;
        animation: animloader 0.3s 0.3s linear infinite alternate;
        z-index: 999;
      }
      
      .loader::after, .loader::before {
        content: '';
        width: 8px;
        height: 40px;
        border-radius: 4px;
        background: currentColor;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 20px;
        box-sizing: border-box;
        animation: animloader 0.3s  0.45s  linear infinite alternate;
      }
      .loader::before {
        left: -20px;
        animation-delay: 0s;
      }
      
      @keyframes animloader {
        0%   { height: 48px} 
        100% { height: 4px}
      }
          
      
}