@import "./Components/Header/scss/header";
@import "./Screens/homepage/scss/features";
@import "./Screens/homepage/scss/homepage";
@import "./Screens/homepage/scss/categories";
@import "./Components/ShowCard/scss/showCard";
@import "./Components/Footer/scss/footer";
@import "./Screens/ShowDetailsScreen/scss/detailsScreen";
@import "./Screens/Accounts/Login/scss/login";
@import "./Screens/Accounts/Register/scss/register";
@import "./Components/CommonPage/scss/commonPage";
@import "./Screens/PaymentStatus/PaymentFailed/scss/paymentFailed";
@import "./Screens/PaymentStatus/PaymentSuccess/scss/paymentSuccess";
@import "./Screens/Accounts/ForgotPassword/scss/forgotPassword";
@import "./Screens/Accounts/ForgotPassword/scss/ResetPassword";
@import "./Components/Header/scss/headerAccounts";
@import "./Components/Modals/scss/OtpModal";
@import "./Components/Modals/scss/countinueWatching";
@import "./Components/Modals/scss/currentPasswordModal";
@import "./Components/Modals/scss/watchWithoutAds";
@import "./Components/Modals/scss/logoutAllModal";
@import "./Components/Modals/scss/cancelSubscriptionModal";
@import "./Components/Modals/scss/playNextEpisode";
@import "./Screens/Accounts/AccountSettings/scss/accountSetting";
@import "./Screens/Accounts/AccountSettings/scss/BillingDetails";
@import "./Screens/Accounts/AccountSettings/scss/streamingActivity";
@import "./Screens/homepage/scss/live";
@import "./Components/ShowsRow/scss/showsRow";
@import "./Screens/Subscription/scss/subscription";
@import "./Screens/Subscription/scss/SubscriptionTwo";
@import "./Screens/Subscription/scss/payment";
@import "./Screens/Subscription/scss/ideabiz";
@import "./Screens/Subscription/scss/paymentProcessing";
@import "./Screens/Accounts/ChangePassword/scss/changepwd";
@import "./Screens/Search/scss/search";
@import "./Screens/MyList/scss/myList";
@import "./Screens/PrivacyPolicy/scss/privacyPolicy";
@import "./Screens/TermsAndConditions/scss/termsAndConditions";
@import "./Screens/ContactPages/scss/contactUs";
@import "./Screens/ContactPages/scss/contactSupport";
@import "./Screens/About/scss/about";
@import "./Components/Loading/scss/loading";
@import "./Components/NewsCard/NewsCard";
@import "./Screens/NewsSection/scss/LatestNews";
@import "./Screens/NewsSection/scss/newsDetails";
@import "./Screens/Blogs/scss/blogs";
@import "./Screens/LandingScreen/scss/landingScreen";
@import "./Screens/NotFound/scss/nowFound";
@import "./Screens/LinkTvApp/linkTvApp";
@import "./Screens/LivePlayer/scss/livePlayer";
@import "./Components/Modals/scss/podcastModal";
@import "./Components/Modals/scss/loginModal";
@import "./Components/ShowCard/scss/card2";
@import "./Components/Menubar/scss/menubar";
@import "./Components/Menubar/scss/menubarmin";
@import "./Components/Header/scss/headerCategories";
@import "./Components/OpenApp/scss/openApp";
@import "./Screens/AffliatePage/scss/affliatePage";
@import "./Screens/ShowDetailsScreen/scss/videoplayer";
@import "./Screens/ShowDetailsScreen/scss/playerDetailsScreen";
@import "./Screens/ShowDetailsScreen/scss/comment";
@import "./Screens/ShowDetailsScreen/scss/commentReply";
@import "./Screens/EventDetailsScreen/scss/eventDetailsScreen";
@import "./Screens/VideoPlayer/scss/liveVideoPlayer";
@import "./Screens/LiveChannels/scss/liveChannels";
@import "./Components/MagazineCard/scss/magazineCard";
@import "./Components/Modals/scss/trailerModal";
@import "./Components/Autoplay/autoplay";
@import './Components/Modals/scss/socialLoginModal';
:root{
  --grey-color:#9699a3;
  // --text-hover:#f00; //bounty hunter
  --text-hover:#188fcb; //fish
  --bg-color:#10141f;
  --secondary-bg-color:#1c1f29;
  --overlay:rgba(16,20,31,.64);
  --text-color:#fff;
  --border-color:rgba(150,153,163,.16);
  --inner-text-color:#9699a3;
  --success-color:#4BB543;
  --error-color:#E72A31;
  --yellow-color:#f8d406;
  --black-color:#000;
  // --button-hover:#f00; //bounty hunter
  // --button-color:#f00;
  --button-hover:#188fcb; //fish
  --button-color:#f00; //fish
  --secondary-btn-color:#272727;
  --grey-color: #f5f5f5;
  --white-color :#fff;
}
div.mainContainer{
  display: flex;
  div.menuContainer{
    display: flex;
    width: 5%;
    background-color: var(--bg-color);
    // z-index: -999999999;
    @media all and (max-width: 1400px) {
      width: 6%;
    } 
    @media all and (max-width: 1200px) {
      width: 7%;
     } 
    @media all and (max-width: 980px) {
      width:9%;
    } 
    @media all and (max-width: 768px) {
      width:10%;
    }
    @media all and (max-width: 640px) {
      width: 0;
    } 
  }
 
  div.bodyContainer{
    
    width: 95%;
    @media all and (max-width: 1400px) {
      width:94%;
    } 
    @media all and (max-width: 1200px) {
      width: 93%;
    } 
    @media all and (max-width: 980px) {
      width:91%;
    } 
    @media all and (max-width: 768px) {
      width:90%;
    } 
    @media all and (max-width: 640px) {
      width: 100%;  
    } 
  }
}
div.menuHide{
  div.menuContainer{
    flex: 0;
    width: 0%;
    background-color: var(--bg-color);
   div.menubarMinContainer{
    display: none;
   }
  }
  div.bodyContainer{
    flex:0;
    width: 100%;
    margin-top: -2%;
    @media all and (max-width: 640px) {
      width: 100%;
           
    } 
  }
}
.wrapper{
  width: 92%;
  margin: 0 auto;
  @media all and (max-width: 2500px){
    margin: 30px auto;
  }
  @media all and (max-width:980px){
    width: 95%;
  }
  @media all and (max-width:768px){
    width: 90%;
  }
  @media all and (max-width:640px){
    width: 85%;
  }
    }
.swiper-button-disabled {
  display: none !important;
}
.swiper-button-next{
  width: 45px !important;
  &::after{
      background: rgb(16, 20, 31);
      border-radius: 50%;
      font-size: 16px;
      width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--inner-text-color);
      @media all and (min-width:320px){    
        width: 25px;
        height: 25px;
        font-size: 12px !important;
          
      }
      @media all and (min-width:1200px){
          font-size: 16px !important;
          width: 45px;
          height: 45px;
      
       }
  }
}
.swiper-button-prev{
  width: 45px !important;
  &::after{
      background: rgb(16, 20, 31);
      border-radius: 50%;
      font-size: 16px;
      width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--inner-text-color);
      @media all and (min-width:320px){
         
        width: 25px;
        height: 25px;
        font-size: 12px !important;
      
      }
      @media all and (min-width:1200px){
         
          font-size: 16px !important;
          width: 45px;
          height: 45px;
      
       }
  }
}

span.error{
	font-size: 14px;
    color: #E72A31;
    display: block;
    margin-top: 10px;
	font-weight: normal !important;
}

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
