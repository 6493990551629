div.streamingActivity{
    padding: 130px 0 ;
    width: 80%;
    margin: 0 auto;
    @media all and (max-width:2500px){
        padding: 200px 0;
    }
    @media all and (max-width:1768px){
        padding: 160px 0;
    }
    @media all and (max-width:1600px){
        padding: 145px 0;
    }
    @media all and (max-width:1200px){
        width: 100%;
    }
    h1.heading{
        font-size: 24px;
        color: var(--text-color);
        margin-bottom: 30px;
    }
    p{
        font-size: 18px;
        color: var(--inner-text-color);
        margin-bottom: 30px;
    }
    ul.recentDeviceContainer{
        li{
            border-bottom: 1px dashed #f5deb3;
            padding-bottom: 30px;
            margin-bottom: 30px;
            span{
                color: #f5deb3;
                display: block;
                font-size: 14px;
                &:first-child{
                    margin-bottom: 10px;
                }
            }
        }
    }
    div.buttonContainer { 
        display: flex;
    padding: 18px 20px;
    border-radius: 4px;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    justify-content: center;
    position: relative;
    width: 20rem;
    margin: 30px auto 0;
    @media all and (max-width:640px){
        width: 15rem;
    }
    @media all and (max-width:480px){
        width: 10rem;
    }
    &:hover{
        div.background{

            transform: scale(1.0476190476,1.0357142857);
        }
    }
        div.background{
            background: var(--text-hover);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 4px;
            transition: 0.1s ease-in-out;
            z-index: 1;

        }
        button { 
            background: transparent;
            font-size: 15px;
            color: var( --text-color);
            font-weight: 600;
            position: relative;
            z-index: 2;
            cursor: pointer;


        }
    }
}